import { RequestUtil } from '../../../utils';
import { ISignUpDto } from './sign-up.dto';
import { UserDto } from '../../../context/dto/user.dto';

class SignUpActions {
  static async signup(signUpDto: ISignUpDto) {
    const data = {
      ...signUpDto,
    } as json;
    delete data.url;
    return RequestUtil.post(signUpDto.url, data, {}, () => UserDto).
      then(response => response).
      catch(error => {
        throw new Error('There was an error!!!');
      });
  }
}

export { SignUpActions };
