import { RequestUtil } from '../../../utils';
import { IForgotPasswordDto } from './forgot-password.dto';
import { UserDto } from '../../../context/dto/user.dto';

class ForgotPasswordActions {
  static async forgotPassword(forgotPasswordDto: IForgotPasswordDto) {
    const data = {
      ...forgotPasswordDto,
    } as json;
    delete data.url;
    return RequestUtil.post(forgotPasswordDto.url, data, {}, () => UserDto).
      then(response => response).
      catch(error => {
        throw new Error('There was an error!!!');
      });
  }
}

export { ForgotPasswordActions };
