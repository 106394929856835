import { Expose, Type } from 'class-transformer';
import { SnapshotOrderDto } from './snapshot-order.dto';
import {
  OrderConstants
} from '../../../components/store/orders/order.constants';

export class OrderDto {
  @Expose()
  uuid!: string;

  @Expose()
  @Type(() => SnapshotOrderDto)
  cartSnapshot!: SnapshotOrderDto;

  @Expose()
  @Type(() => Date)
  createdAt!: Date;
  
  @Expose()
  status!: OrderConstants;
}
