import React from 'react';
import { MainLayout } from '../../layouts/main.layout';
import { ShopMainHeader } from '../../components/store/shop-main-header';
import { MainHeader } from '../../components/main/main-header';
import MainBody from '../../components/main/main-body';
import { Dashboard } from '../../components/dashboard/dashboard';

const DashboardPage = () => {
  return (
    <MainLayout>
      <MainHeader className={'shadow-sm bg-dark'}>
        <ShopMainHeader/>
      </MainHeader>
      <MainBody>
        <Dashboard></Dashboard>
      </MainBody>
    </MainLayout>
  );
};

export { DashboardPage as default };
