import { useCallback, useContext, useState } from 'react';
import AppContext from '../../context/app.context';
import { UserDto } from '../../context/dto/user.dto';
import { StoreDto } from '../../context/dto/store.dto';

function useStoreState() {
  const {currentUser, setCurrentUser} = useContext(AppContext);
  const [stores, setStores] = useState([] as StoreDto[]);
  return useCallback((newStore: StoreDto) => {
    const newStores = (() => {
      const index = currentUser?.stores?.findIndex(
        store => store.uuid === newStore.uuid);
      if (index && index === -1) {
        return [...(currentUser?.stores || []), newStore];
      }
      if (index) {
        (currentUser?.stores || [])[index] = newStore;
      }
      return [...(currentUser?.stores || [])];
    })();
    const newCurrentUser = {...currentUser} as UserDto;
    if (newCurrentUser && newCurrentUser.stores) {
      newCurrentUser.stores = newStores;
    }
    setCurrentUser && setCurrentUser({
      ...newCurrentUser,
    });
    setStores(newStores);
    return stores;
  }, [currentUser]);
}

export { useStoreState };