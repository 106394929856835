import { Expose, Type } from 'class-transformer';
import {
  DashboardSummaryEarningsDto,
} from './dashboard-summary-earnings.dto';
import {
  DashboardSummaryQtyOrdersDto,
} from './dashboard-summary-qty-orders.dto';

export class DashboardSummaryDto {
  @Expose()
  @Type(() => DashboardSummaryEarningsDto)
  earnings!: DashboardSummaryEarningsDto;

  @Expose()
  @Type(() => DashboardSummaryQtyOrdersDto)
  qty_orders!: DashboardSummaryQtyOrdersDto;
}