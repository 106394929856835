import React from 'react';
import LoginLayout from '../../layouts/login.layout';
import Login from '../../components/auth/login/login';

const LoginPage = () => {
  return (
    <LoginLayout>
      <Login></Login>
    </LoginLayout>
  );
};

export { LoginPage as default };
