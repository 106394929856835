import React from 'react';
import { UserDto } from './dto/user.dto';
import { StoreDto } from './dto/store.dto';

export interface IAppContext {
  currentUser?: UserDto;
  setCurrentUser?: Function;
  shopList?: StoreDto[];
  setShopList?: Function;
  currentShop?: StoreDto;
  setCurrentShop?: Function;
}

const AppContext = React.createContext({} as IAppContext);

export {AppContext as default};
