import { Expose } from 'class-transformer';
import {
  IsDate,
  IsNotEmpty,
  IsOptional,
  IsString,
} from 'class-validator';

export class SnapshotOrderShippingDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  reference!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  shippingMethodCode!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  status!: string;

  @Expose()
  @IsOptional()
  @IsNotEmpty()
  additional?: json;

  @Expose()
  @IsOptional()
  @IsNotEmpty()
  comments?: string;

  @Expose()
  @IsDate()
  @IsNotEmpty()
  createdAt!: Date;
}
