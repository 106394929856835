import { useContext, useEffect, useState } from 'react';
import { RequestUtil } from '../../utils';
import AppContext, { IAppContext } from '../../context/app.context';
import { DashboardDto } from './dto/dashboard.dto';

function useDashboard() {
  const {
    currentShop,
  } = useContext(AppContext) as IAppContext;

  const [dashboard, setDashboard] = useState(
    {} as DashboardDto);
  useEffect(() => {
    if (!currentShop?.uuid) {
      setDashboard({} as DashboardDto);
      return;
    }
    RequestUtil.get(`/stores/${currentShop?.uuid}/dashboard/details`, {},
      () => DashboardDto).
      then((response: json) => {
        setDashboard(response as DashboardDto);
      }).
      catch(errors => console.error('Error: useDashboard'));
  }, [currentShop]);
  return dashboard;
}

export { useDashboard };