import { Expose } from 'class-transformer';
import { IsEmail, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class SnapshotOrderBillingAddressDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  firstName!: string;

  @Expose()
  @IsString()
  @IsOptional()
  lastName?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsEmail()
  email!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  addressLine1!: string;

  @Expose()
  @IsString()
  @IsOptional()
  addressLine2?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  country!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  state!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  zipCode!: string;
}
