import React, {
  useCallback,
  useContext, useEffect,
  useRef,
  useState,
} from 'react';
import AppContext from '../../../../../context/app.context';
import { Spinner } from '../../../../spinner/spinner';
import { IProps } from '../../../../../types';
import {
  IShopProductVariantItemFormUIModel, useCreateModel as useCreateModelForm,
} from './shop-product-variant-item-form.uimodel';
import PubSub from 'pubsub-js';
import { PubSubEnum } from '../../../../../enums/pub-sub.enum';
import { IAlertModal } from '../../../../modal/alert.modal';
import {
  IShopProductVariantItemsUIModel,
} from '../shop-product-variant-items.uimodel';

interface UIModel extends IProps {
  uiModel: IShopProductVariantItemsUIModel;
}

const ShopProductVariantItemForm = (uiModel: UIModel) => {
  const {currentShop} = useContext(AppContext);
  const formRef = useRef(null);
  const uiModelItemForm: IShopProductVariantItemFormUIModel = useCreateModelForm();
  const {
    variant,
    setCreatingOrEditing,
  } = uiModel.uiModel;

  const onCreateOrEditForm = useCallback(() => {
    uiModelItemForm.fill({variant});
    uiModelItemForm.setCreatingOrEditing(true);
  }, [currentShop]);

  return <>
    {
      !uiModelItemForm.creatingOrEditing && (
        <div className={'col-12 position-relative'}>
          <button type={'button'}
                  className={'btn btn-primary end-0 position-absolute'}
                  onClick={onCreateOrEditForm}
          >
            <i className={'bi bi-plus-lg fw-bold me-2'}></i>Add
            Item
          </button>
          <table className="table table-striped table-hover table-responsive caption-top">
            <caption>Variant: {variant?.name}</caption>
            <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Value</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            {variant.items?.map(item => {
              return <tr key={item.uuid}
                         className={'align-middle'}>
                <td>{item.name}</td>
                <td>{item.code}</td>
                <td>
                  <p className="pe-4 m-0 w-100 text-truncate text-muted">{
                    item.enabled
                      ? <>
                        <i className={'bi bi-check-circle-fill text-success me-1'}></i>Enabled
                      </>
                      : <>
                        <i className={'bi bi-dash-circle-fill text-danger me-1'}></i>Disabled
                      </>
                  }</p>
                </td>
                <td>
                  <div className="btn-group"
                       role="group"
                       aria-label="Basic example">
                    <button type="button"
                            className="btn btn-outline-primary"
                            onClick={() => {
                              uiModelItemForm.fill(item);
                              uiModelItemForm.setCreatingOrEditing(true);
                            }}
                    >
                      <i className={'bi bi-pencil'}></i>
                    </button>
                    <button type="button"
                            className="btn btn-outline-primary"
                            onClick={() => {
                              uiModelItemForm.fill(item);
                              uiModelItemForm.setCreatingOrEditing(
                                true);
                            }}
                    >
                      <i className={'bi bi-list-check'}></i>
                    </button>
                    <button type="button"
                            className="btn btn-outline-primary"
                            onClick={() => {
                              uiModelItemForm.fill(item);
                              PubSub.publish(
                                PubSubEnum.ALERT_CONFIRM_MODAL_MESSAGE,
                                {
                                  message: <div>
                                    <p className={'fs-4'}>Do You want to
                                      delete <span className={'fw-bold'}>{item.name}</span> variant?
                                    </p>
                                    <p>Click on Yes button to
                                      delete.</p>
                                  </div>,
                                  okHandler: () => {
                                    return uiModelItemForm.remove(item).
                                      then(() => {
                                        // uiModelItemForm.fetch();
                                      });
                                  },
                                } as IAlertModal);
                            }}
                    >
                      <i className={'bi bi-x'}></i>
                    </button>
                  </div>
                </td>
              </tr>;
            })}
            </tbody>
          </table>
          <div className="d-flex justify-content-between mb-3">
            <button className="btn btn-outline-primary btn-lg"
                    onClick={() => setCreatingOrEditing(false)}>
              <i className={'bi bi-arrow-left me-2'}></i>Back
            </button>
          </div>
        </div>
      )
    }
    {
      uiModelItemForm.creatingOrEditing && (
        <>
          <form ref={formRef}
                className="needs-validation col-12 px-3 px-md-0"
                noValidate={true}
                autoComplete="off"
                onSubmit={(evt) => {
                  evt.preventDefault();
                  uiModelItemForm.save();
                }}>
            <fieldset>
              <legend className={''}>Variant: {variant?.name}</legend>
              <div className="row g-2">
                <div className="col-md">
                  <div className="form-floating mb-3">
                    <input id="store-product-variant-code"
                           value={uiModelItemForm.code}
                           onChange={(el) => uiModelItemForm.setCode(
                             el.currentTarget.value)}
                           type="text"
                           className="form-control form-control-sm"
                           placeholder="Code"
                           autoComplete="nope"
                           required={true}/>
                    <label
                      className="form-label"
                      htmlFor="store-product-variant-code">Code</label>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-floating mb-3">
                    <input id="store-product-variant-name"
                           value={uiModelItemForm.name}
                           onChange={(el) => uiModelItemForm.setName(
                             el.currentTarget.value)}
                           type="text"
                           className="form-control form-control-sm"
                           placeholder="Name"
                           autoComplete="nope"
                           required={true}/>
                    <label
                      className="form-label"
                      htmlFor="store-product-variant-name">Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="form-floating mb-3">
                    <input id="store-product-variant-short-name"
                           value={uiModelItemForm.shortName}
                           onChange={(el) => uiModelItemForm.setShortName(
                             el.currentTarget.value)}
                           type="text"
                           className="form-control form-control-sm"
                           placeholder="Code"
                           autoComplete="nope"
                           required={true}/>
                    <label
                      className="form-label"
                      htmlFor="store-product-variant-short-name">Short
                      Name</label>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <div className="form-check form-switch align-self-center">
                  <input id="payment-gateway.enabled"
                         className="form-check-input"
                         type="checkbox"
                         onChange={(el) => uiModelItemForm.setEnabled(
                           el.target.checked)}
                         role="switch"
                         checked={uiModelItemForm.enabled}
                  />
                  <label className="form-check-label"
                         htmlFor="payment-gateway.enabled">Enabled /
                    Disabled</label>
                </div>
              </div>
            </fieldset>
            <div className="d-flex justify-content-between mb-3">
              <button className="btn btn-outline-primary btn-lg"
                      onClick={() => uiModelItemForm.setCreatingOrEditing(
                        false)}>
                <i className={'bi bi-arrow-left me-2'}></i>Back
              </button>
              <button className="btn btn-outline-primary btn-lg">
                <Spinner show={uiModelItemForm.showSubmitLoading}/>
                {!uiModelItemForm.showSubmitLoading
                  ? <><i className={'bi bi-save mx-2'}></i>Save</>
                  : ''}
              </button>
            </div>
          </form>
        </>
      )
    }

  </>;
};

export { ShopProductVariantItemForm };