import React, {
  useCallback,
  useContext, useEffect,
  useRef,
  useState,
} from 'react';
import AppContext from '../../../../../context/app.context';
import { Spinner } from '../../../../spinner/spinner';
import {
  IShopProductVariantFormUIModel,
} from './shop-product-variant-form.uimodel';
import { IProps } from '../../../../../types';

interface UIModel extends IProps {
  uiModel: IShopProductVariantFormUIModel;
}

const ShopProductVariantsForm = (uiModel: UIModel) => {
  const {currentShop} = useContext(AppContext);
  const formRef = useRef(null);
  const {
    uuid,
    setUUID,
    creatingOrEditing,
    setCreatingOrEditing,
    code,
    setCode,
    name,
    setName,
    shortDescription,
    setShortDescription,
    enabled,
    setEnabled,
    showSubmitLoading,
    setShowSubmitLoading,
  } = uiModel.uiModel;

  return <form ref={formRef}
               className="needs-validation col-12 px-3 px-md-0"
               noValidate={true}
               autoComplete="off"
               onSubmit={(evt) => {
                 evt.preventDefault();
                 uiModel.uiModel?.save && uiModel.uiModel.save();
               }}>
    <fieldset>
      <legend className={'my-5'}>Variant</legend>
      <div className="row g-2">
        <div className="col-md">
          <div className="form-floating mb-3">
            <input id="store-product-variant-code"
                   value={code}
                   onChange={(el) => setCode(
                     el.currentTarget.value)}
                   type="text"
                   className="form-control form-control-lg"
                   placeholder="Code"
                   autoComplete="nope"
                   required={true}/>
            <label
              className="form-label"
              htmlFor="store-product-variant-code">Code</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-3">
            <input id="store-product-variant-name"
                   value={name}
                   onChange={(el) => setName(
                     el.currentTarget.value)}
                   type="text"
                   className="form-control form-control-lg"
                   placeholder="Name"
                   autoComplete="nope"
                   required={true}/>
            <label
              className="form-label"
              htmlFor="store-product-variant-name">Name</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <div className="form-floating mb-3">
            <input id="store-product-variant-short-description"
                   value={shortDescription}
                   onChange={(el) => setShortDescription(
                     el.currentTarget.value)}
                   type="text"
                   className="form-control form-control-lg"
                   placeholder="Code"
                   autoComplete="nope"
                   required={true}/>
            <label
              className="form-label"
              htmlFor="store-product-variant-short-description">Short
              Description</label>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="form-check form-switch align-self-center">
          <input id="payment-gateway.enabled"
                 className="form-check-input"
                 type="checkbox"
                 onChange={(el) => setEnabled(
                   el.target.checked)}
                 role="switch"
                 checked={enabled}
          />
          <label className="form-check-label"
                 htmlFor="payment-gateway.enabled">Enabled /
            Disabled</label>
        </div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-outline-primary btn-lg"
                onClick={() => setCreatingOrEditing(false)}>
          <i className={'bi bi-arrow-left me-2'}></i>Back
        </button>
        <button className="btn btn-outline-primary btn-lg">
          <Spinner show={showSubmitLoading}/>
          {!showSubmitLoading
            ? <><i className={'bi bi-save mx-2'}></i>Save</>
            : ''}
        </button>
      </div>
    </fieldset>
  </form>
    ;
};

export {
  ShopProductVariantsForm,
};