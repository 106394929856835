import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext, { IAppContext } from '../../../context/app.context';
import ModalContext, { IModalContext } from '../../modal/context/modal.context';
import { ProfileEditActions } from '../profile-edit.actions';
import { merge } from 'lodash';

const ProfileEditEmailForm = () => {
  const {currentUser, setCurrentUser} = useContext(AppContext) as IAppContext;
  const {action, setShowSpinner} = useContext(
    ModalContext) as IModalContext;
  const $formRef = useRef(null);
  const [email, setEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [reEmail, setReEmail] = useState('');

  useEffect(() => {
    if (!action) {
      return;
    }
    const $form = $formRef.current as unknown as HTMLFormElement;
    submit($form);
  }, [action]);

  const submit = async ($form: HTMLFormElement) => {
    const url = `${$form.getAttribute('action')}/${currentUser?.id}`;
    try {
      setShowSpinner && setShowSpinner(true);
      const user = await ProfileEditActions.email({
        url,
        email,
        newEmail,
        reEmail,
      });
      setCurrentUser && setCurrentUser(merge(currentUser, user));
    } finally {
      setShowSpinner && setShowSpinner(false);
    }
  };

  return (
    <form ref={$formRef}
          action="/users"
          className="needs-validation"
          noValidate={true}
          autoComplete="off">
      <div className="form-floating mb-3">
        <input id="profile.current.email"
               value={email}
               onChange={(el) => setEmail(el.currentTarget.value)}
               type="email"
               className="form-control form-control-lg"
               placeholder="Current Email"
               autoComplete="nope"
               required={true}/>
        <label
          className="form-label" htmlFor="profile.current.email">Current
          Email</label></div>
      <div className="form-floating mb-3">
        <input id="profile.new.email"
               value={newEmail}
               onChange={(el) => setNewEmail(el.currentTarget.value)}
               type="email"
               className="form-control form-control-lg"
               placeholder="New Email"
               autoComplete="nope"
               required={true}/>
        <label
          className="form-label" htmlFor="profile.new.email">New Email</label>
      </div>
      <div className="form-floating mb-3">
        <input id="profile.repeat.email"
               value={reEmail}
               onChange={(el) => setReEmail(el.currentTarget.value)}
               type="email"
               className="form-control form-control-lg"
               placeholder="Repeat new Email"
               autoComplete="nope"
               required={true}/>
        <label
          className="form-label" htmlFor="profile.repeat.email">Repeat new
          Email</label></div>
    </form>
  );
};

export { ProfileEditEmailForm };
