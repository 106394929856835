import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext, { IAppContext } from '../../../context/app.context';
import { ProfileEditActions } from '../profile-edit.actions';
import ModalContext, { IModalContext } from '../../modal/context/modal.context';
import { merge } from 'lodash';

const ProfileEditAvatarForm = () => {
  const {currentUser, setCurrentUser} = useContext(AppContext) as IAppContext;
  const {action, setShowSpinner} = useContext(
    ModalContext) as IModalContext;
  const $formRef = useRef(null);
  const [avatar, setAvatar] = useState('');
  const [avatarFileType, setAvatarFileType] = useState('');
  const [avatarFileName, setAvatarFileName] = useState('');
  const [avatarFileBase64, setAvatarFileBase64] = useState('');

  const onAvatarChange = (evt: any) => {
    const $el = evt.currentTarget;
    const [file] = $el.files;
    if (!file) {
      throw new Error(`'There isn't any image file loaded`);
    }
    setAvatar(URL.createObjectURL(file));
    const reader = new FileReader();
    reader.addEventListener('loadend', () => {
      setAvatarFileType(file.type);
      setAvatarFileName(file.name);
      setAvatarFileBase64(reader.result as string);
    });
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setAvatar(currentUser?.avatarMedia?.base64 ?? '');
  }, [currentUser]);

  useEffect(() => {
    if (!action) {
      return;
    }
    const $form = $formRef.current as unknown as HTMLFormElement;
    submit($form);
  }, [action]);

  const submit = async ($form: HTMLFormElement) => {
    const url = `${$form.getAttribute('action')}/${currentUser?.id}`;
    try {
      setShowSpinner && setShowSpinner(true);
      const user = await ProfileEditActions.avatar({
        url,
        avatar: {
          type: avatarFileType,
          name: avatarFileName,
          base64: avatarFileBase64,
        },
      });
      setCurrentUser && setCurrentUser(merge(currentUser, user));
    } finally {
      setShowSpinner && setShowSpinner(false);
    }
  };

  return (
    <form ref={$formRef}
          action="/users"
          className="needs-validation"
          noValidate={true} autoComplete="off">
      <div className="d-flex justify-content-center w-100">
        <div className="w-200--px h-200--px mb-5 border rounded-3">
          <img className="w-100 h-100" alt="avatar" src={avatar}/>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label"
               htmlFor="profile.avatar">Upload your Avatar
        </label>
        <input id="profile.avatar"
               type="file"
               className="form-control form-control-lg"
               placeholder="Avatar"
               required={true}
               onChange={onAvatarChange}
        /></div>
    </form>
  );
};

export { ProfileEditAvatarForm };
