import { useEffect, useState } from 'react';
import { RequestUtil } from '../../utils';
import { PaymentGatewayDto } from './dto/payment-gateway.dto';

export default function useListPaymentGateways() {
  const [paymentGateways, setPaymentGateways] = useState(
    [] as Array<PaymentGatewayDto>);
  useEffect(() => {
    RequestUtil.get('/payment-gateways', {}, () => PaymentGatewayDto).
      then(response => {
        setPaymentGateways(response as Array<PaymentGatewayDto>);
      }).
      catch(errors => console.error('Error: useListPaymentGateways'));
  }, []);
  return paymentGateways;
};