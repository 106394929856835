import { IProps } from '../../types';
import { useCallback, useEffect, useRef } from 'react';

export interface ISpinner extends IProps {
  show: boolean;
}

const Spinner = ({show}: ISpinner) => {
  const spinner = useRef(null);

  const showHideSpinner = useCallback(() => {
    const $spinner = spinner.current as unknown as HTMLSpanElement;
    if (show) {
      return $spinner.classList.remove('d-none');
    }
    $spinner.classList.add('d-none');
  }, [spinner, show]);
  
  useEffect(() => {
    showHideSpinner();
  }, [spinner, show]);

  return <span ref={spinner}
               className="spinner-grow spinner-grow-sm"
               role="status"
               aria-hidden="true"></span>;
};

export { Spinner };