import React, { useCallback, useState } from 'react';
import { SignUpActions } from './sign-up.actions';
import { NavLink } from 'react-router-dom';
import ThankYouMsg from '../../tankyou/thank-you-msg';
import { Spinner } from '../../spinner/spinner';

const SignUp = () => {
  const [wasFormSubmitted, setWasFormSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const signup = useCallback((e: any) => {
    e.preventDefault();
    const $form = e.target;
    setShowLoading(true);
    SignUpActions.signup({
      url: $form.getAttribute('action'),
      firstName: $form.elements.floatingFirstName.value,
      lastName: $form.elements.floatingLastName.value,
      email: $form.elements.floatingEmail.value,
      password: $form.elements.floatingPassword.value,
      rePassword: $form.elements.floatingRepeatPassword.value,
    }).then(() => {
      setWasFormSubmitted(true);
    }).finally(() => {
      setShowLoading(false);
    });
  }, []);

  return (
    <>
      <ThankYouMsg
        message={'We\'ve sent an email to confirm your email address'}
        className={!wasFormSubmitted ? 'd-none' : ''}/>
      <div className={wasFormSubmitted ? 'd-none' : 'card w-auto text-center'}>
        <div className="card-body ps-4 pe-4"><p
          className="card-title mt-2 mb-4 text-primary fs-5">Sign up for
          free</p>
          <form onSubmit={signup}
                action="/auth/sign-up"
                className="needs-validation"
                noValidate={true}
                autoComplete="off">
            <div className="form-floating mb-3"><input id="floatingFirstName"
                                                       type="text"
                                                       className="form-control form-control-lg"
                                                       placeholder="First Name"
                                                       autoComplete="nope"
                                                       required={true}/><label
              className="form-label"
              htmlFor="floatingFirstName">First
              Name</label></div>
            <div className="form-floating mb-3"><input id="floatingLastName"
                                                       type="text"
                                                       className="form-control form-control-lg"
                                                       placeholder="Last Name"
                                                       autoComplete="nope"/><label
              className="form-label"
              htmlFor="floatingLastName">Last
              Name</label></div>
            <div className="form-floating mb-3"><input id="floatingEmail"
                                                       type="email"
                                                       className="form-control form-control-lg"
                                                       placeholder="Email address"
                                                       autoComplete="nope"
                                                       required={true}/><label
              className="form-label"
              htmlFor="floatingEmail">Email
              address</label></div>
            <div className="form-floating mb-3"><input id="floatingPassword"
                                                       type="password"
                                                       className="form-control form-control-lg"
                                                       placeholder="Password"
                                                       autoComplete="nope"
                                                       required={true}/><label
              className="form-label"
              htmlFor="floatingPassword">Password</label>
            </div>
            <div className="form-floating mb-3"><input
              id="floatingRepeatPassword"
              type="password"
              className="form-control form-control-lg"
              placeholder="Repeat Password"
              autoComplete="nope"
              required={true}/><label
              className="form-label"
              htmlFor="floatingRepeatPassword">Repeat
              Password</label></div>
            <div className="d-grid mb-3">
              <button className="btn btn-primary btn-lg">
                <Spinner show={showLoading}/>
                <span className={'mx-2'}>Sing Up</span>
              </button>
            </div>
          </form>
        </div>
        <div className="card-footer p-3 bg-transparent">
          <NavLink
            className="text-decoration-none text-secondary mb-2 mt-2"
            to={'/login'}>Already have an account? &nbsp; <span
            className="text-decoration-underline text-primary">Log In</span>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export { SignUp as default };
