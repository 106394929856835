import { RequestUtil } from '../../../utils';
import { IResetPasswordDto } from './reset-password.dto';
import { UserDto } from '../../../context/dto/user.dto';

class ResetPasswordActions {
  static async resetPassword(resetPasswordDto: IResetPasswordDto) {
    const data = {
      ...resetPasswordDto,
    } as json;
    delete data.url;
    return RequestUtil.post(resetPasswordDto.url, data, {}, () => UserDto).
      then(response => response).
      catch(error => {
        throw new Error('There was an error!!!');
      });
  }
}

export { ResetPasswordActions };
