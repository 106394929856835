import { Expose, Transform } from 'class-transformer';

export class SnapshotOrderItemProductDto {
  @Expose()
  uuid!: string;

  @Expose()
  name!: string;

  @Expose()
  longDescription?: string;

  @Expose()
  shortDescription?: string;

  @Expose()
  price!: number;

  @Expose()
  amount!: number;
}
