import { CacheUtil, RequestUtil } from '../../../utils';
import {
  ShippingMethodDto,
} from '../../../hooks/shipping-methods/dto/shipping-method.dto';

class ShippingMethodActions {

  static async save(data: ShippingMethodDto) {
    const storeId = CacheUtil.cache('x-store');
    const url = `/stores/${storeId}/shipping-methods`;
    return RequestUtil.post(url, {
        shippingMethods: [
          {
            code: data.code,
            enabled: data.enabled,
            settings: [],
          }],
      },
      {},
      () => ShippingMethodDto,
    );
  }
}

export { ShippingMethodActions };
