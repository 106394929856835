import { Expose } from 'class-transformer';

export class ShopUserDto {
  @Expose()
  id!: string;

  @Expose()
  firstName!: string;

  @Expose()
  lastName?: string;

  @Expose()
  email!: string;

  @Expose()
  phone!: string;

  @Expose()
  enabled!: boolean;
}
