import { RequestUtil } from '../../../utils/request';
import { CacheUtil } from '../../../utils/cache';
import { ILoginDto } from './login.dto';
import { UserDto } from '../../../context/dto/user.dto';
import { LoginDto } from './dto/login.dto';
import { PubSubEnum } from '../../../enums/pub-sub.enum';
import { IUIToast } from '../../toast/toast.ui';

class LoginActions {
  static async login(loginDto: ILoginDto) {
    const data = {
      'email': loginDto.email,
      'password': loginDto.password,
    };
    const response = await RequestUtil.post(loginDto.url, data, {notify: false},
      () => LoginDto).catch(error => {
      PubSub.publish(PubSubEnum.TOAST_ERROR,
        {message: 'Invalid Credentials'} as IUIToast);
      return;
    });
    if (response?.access_token) {
      // @ts-ignore
      CacheUtil.cache('__jsession', response.access_token);
      const currentUser = await RequestUtil.get('/auth/whichUser', {},
        () => UserDto);
      const {stores} = currentUser;
      if (stores) {
        const [store] = stores;
        // @ts-ignore
        CacheUtil.cache('x-store', store?.uuid);
      }
      return currentUser;
    } else {
      throw new Error('Invalid credentials!!!');
    }
  }

  static logout() {
    CacheUtil.removeCache('__jsession');
    CacheUtil.removeCache('x-store');
  }
}

export { LoginActions };
