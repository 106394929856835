import { Expose, Type } from 'class-transformer';
import { IsOptional, ValidateNested } from 'class-validator';
import { SnapshotOrderPaymentDto } from './snapshot-order-payment.dto';
import {
  SnapshotOrderShippingDto,
} from './snapshot-order-shipping.dto';
import {
  SnapshotOrderBillingAddressDto,
} from './snapshot-order-billing-address.dto';
import { SnapshotOrderItemDto } from './snapshot-order-item.dto';
import { SnapshotOrderSummaryDto } from './snapshot-order-summary.dto';

export class SnapshotOrderDto {
  @Expose()
  uuid!: string;

  @Expose()
  @Type(() => SnapshotOrderItemDto)
  items!: SnapshotOrderItemDto[];

  @Expose()
  @Type(() => SnapshotOrderSummaryDto)
  summary!: SnapshotOrderSummaryDto;

  @Expose()
  @IsOptional()
  @Type(() => SnapshotOrderBillingAddressDto)
  billingAddress?: SnapshotOrderBillingAddressDto;

  @Expose()
  @IsOptional()
  @ValidateNested()
  @Type(() => SnapshotOrderPaymentDto)
  payment?: SnapshotOrderPaymentDto;

  @Expose()
  @IsOptional()
  @ValidateNested()
  @Type(() => SnapshotOrderShippingDto)
  shipping?: SnapshotOrderShippingDto;
}
