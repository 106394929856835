import { DependencyList, useCallback } from 'react';
import { BootstrapInstanceEnum } from './bootstrap-instance.enum';
import { findDOMNode } from 'react-dom';

function useParentUntilFrom(deps: DependencyList) {
  return useCallback(
    (
      ref: React.MutableRefObject<any>,
      className: string,
      bs?: BootstrapInstanceEnum) => {
      const $el = findDOMNode(ref.current) as Element;

      const recursive = (el: Element): Element | null => {
        if (!el) {
          return null;
        }
        if (el.classList.contains(className)) {
          return el;
        }
        return recursive(el.parentNode as Element);
      };

      const component = recursive($el);
      switch (bs) {
        case BootstrapInstanceEnum.MODAL:
          return bootstrap.Modal.getOrCreateInstance(component);
        default:
          return $el.parentNode?.parentNode?.parentNode?.parentNode;
      }
    }, deps);
}

export { useParentUntilFrom };