import React from 'react';
import { MainLayout } from '../../layouts/main.layout';
import { MainHeader } from '../../components/main/main-header';
import MainBody from '../../components/main/main-body';
import ProfileForm from '../../components/profile/profile-form';

const ProfilePage = () => {
  return (
    <MainLayout>
      <MainHeader backButtonClassName={'bg-secondary'}/>
      <MainBody>
        <ProfileForm/>
      </MainBody>
    </MainLayout>
  );
};

export { ProfilePage as default };
