import { Expose, Type } from "class-transformer";
import {
  SnapshotOrderItemProductDto
} from "./snapshot-order-item-product.dto";

export class SnapshotOrderItemDto {
  @Expose()
  qty!: number;

  @Expose()
  @Type(() => SnapshotOrderItemProductDto)
  product!: SnapshotOrderItemProductDto;

}
