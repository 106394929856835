import React, { SetStateAction, useState } from 'react';
import { ShopProductVariantDto } from '../../variants/shop-product-variant.dto';
import {
  ShopProductVariantItemDto,
} from '../shop-product-variant-item.dto';
import {
  ShopProductVariantItemService,
} from '../shop-product-variant-item.service';

export interface IShopProductVariantItemFormUIModel {
  variant: ShopProductVariantDto;
  setVariant: React.Dispatch<SetStateAction<ShopProductVariantDto>>;
  uuid: string;
  setUUID: React.Dispatch<SetStateAction<string>>;
  creatingOrEditing: boolean,
  setCreatingOrEditing: React.Dispatch<SetStateAction<boolean>>;
  code: string;
  setCode: React.Dispatch<SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<SetStateAction<string>>;
  shortName: string;
  setShortName: React.Dispatch<SetStateAction<string>>;
  enabled: boolean;
  setEnabled: React.Dispatch<SetStateAction<boolean>>;
  showSubmitLoading: boolean;
  setShowSubmitLoading: React.Dispatch<SetStateAction<boolean>>;
  empty: Function;
  fill: Function;
  save: Function;
  remove: Function;
}

const useCreateModel = () => {
  // Control
  const [creatingOrEditing, setCreatingOrEditing] = useState(false);
  const [showSubmitLoading, setShowSubmitLoading] = useState(false);

  // Model
  const [variant, setVariant] = useState({} as ShopProductVariantDto);
  const [uuid, setUUID] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [shortName, setShortName] = useState('');
  const [enabled, setEnabled] = useState(false);

  const empty = () => {
    setUUID('');
    setCode('');
    setName('');
    setShortName('');
    setEnabled(false);
  };

  const fill = (dto: ShopProductVariantItemDto) => {
    empty();
    setVariant(dto.variant || {} as ShopProductVariantDto);
    setUUID(dto.uuid || '');
    setCode(dto.code || '');
    setName(dto.name || '');
    setShortName(dto.shortName || '');
    setEnabled(dto.enabled || false);
  };

  const save = () => {
    const transitory: ShopProductVariantItemDto = {
      uuid,
      variant,
      code,
      name,
      shortName,
      enabled,
    };
    setShowSubmitLoading(true);
    ShopProductVariantItemService.save(transitory).then(response => {
      setUUID(response.uuid || '');
      setCreatingOrEditing(false);
    }).finally(() => {
      setShowSubmitLoading(false);
    });
  };

  const remove = async (dto: ShopProductVariantDto) => {
    setShowSubmitLoading(true);
    try {
      await ShopProductVariantItemService.delete(dto.uuid || '');
    } finally {
      setShowSubmitLoading(false);
    }
  };

  return {
    variant,
    setVariant,
    uuid,
    setUUID,
    creatingOrEditing,
    setCreatingOrEditing,
    code,
    setCode,
    name,
    setName,
    shortName,
    setShortName,
    enabled,
    setEnabled,
    showSubmitLoading,
    setShowSubmitLoading,
    empty,
    fill,
    save,
    remove,
  } as IShopProductVariantItemFormUIModel;
};

export { useCreateModel };