import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext, { IAppContext } from '../../../context/app.context';
import ModalContext, { IModalContext } from '../../modal/context/modal.context';
import { ProfileEditActions } from '../profile-edit.actions';
import { merge } from 'lodash';

const ProfileEditPasswordForm = () => {
  const {currentUser, setCurrentUser} = useContext(AppContext) as IAppContext;
  const {action, setShowSpinner} = useContext(
    ModalContext) as IModalContext;
  const $formRef = useRef(null);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  useEffect(() => {
    if (!action) {
      return;
    }
    const $form = $formRef.current as unknown as HTMLFormElement;
    submit($form)
  }, [action]);

  const submit = async ($form: HTMLFormElement) => {
    const url = `${$form.getAttribute('action')}/${currentUser?.id}`;
    try {
      setShowSpinner && setShowSpinner(true);
      const user = await ProfileEditActions.password({
        url,
        password,
        newPassword,
        rePassword,
      });
      setCurrentUser && setCurrentUser(merge(currentUser, user));
    } finally {
      setShowSpinner && setShowSpinner(false);
    }
  };

  return (
    <form ref={$formRef}
          action="/users"
          className="needs-validation"
          noValidate={true}
          autoComplete="off">
      <div className="form-floating mb-3">
        <input id="profile.current.password"
               value={password}
               onChange={(el) => setPassword(el.currentTarget.value)}
               type="password"
               className="form-control form-control-lg"
               placeholder="Current Password"
               autoComplete="nope"
               required={true}/>
        <label
          className="form-label" htmlFor="profile.current.password">Current
          Password</label></div>
      <div className="form-floating mb-3">
        <input id="profile.new.password"
               value={newPassword}
               onChange={(el) => setNewPassword(el.currentTarget.value)}
               type="password"
               className="form-control form-control-lg"
               placeholder="New Password"
               autoComplete="nope"
               required={true}/>
        <label
          className="form-label" htmlFor="profile.new.password">New
          Password</label></div>
      <div className="form-floating mb-3">
        <input id="profile.repeat.password"
               value={rePassword}
               onChange={(el) => setRePassword(el.currentTarget.value)}
               type="password"
               className="form-control form-control-lg"
               placeholder="Repeat new Password"
               autoComplete="nope"
               required={true}/>
        <label
          className="form-label" htmlFor="profile.repeat.password">Repeat new
          Password</label></div>
    </form>
  );
};

export { ProfileEditPasswordForm };
