import React from 'react';
export interface IModalContext {
  action?: string;
  setAction?: Function;

  showSpinner?: boolean

  setShowSpinner?: Function;

  hasBackdrop?: boolean;

  setHasBackdrop?: Function;

  showModal?: boolean;

  setShowModal?: Function;
}

const ModalContext = React.createContext({} as IModalContext);

export { ModalContext as default };
