import { Expose, Type } from "class-transformer";
import { IsArray } from "class-validator";
import {
  DashboardChartDatasetDto
} from "./dashboard-chart-dataset.dto";

export class DashboardChartDto {
  @Expose()
  @IsArray()
  labels!: string[];

  @Expose()
  @IsArray()
  @Type(() => DashboardChartDatasetDto)
  datasets!: DashboardChartDatasetDto[];
}