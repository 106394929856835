import React, { useCallback, useContext, useState } from 'react';
import { LoginActions } from './login.actions';
import AppContext, { IAppContext } from '../../../context/app.context';
import { NavLink, useNavigate } from 'react-router-dom';
import { Spinner } from '../../spinner/spinner';

const Login = () => {
  const {setCurrentUser} = useContext(AppContext) as IAppContext;
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  
  const login = useCallback((e: any) => {
    e.preventDefault();
    const $form = e.target;
    setShowLoading(true);
    const userLogged = LoginActions.login({
      url: $form.getAttribute('action'),
      email: $form.elements.floatingEmail.value,
      password: $form.elements.floatingPassword.value,
    }).then(userLogged => {
      setCurrentUser && setCurrentUser(userLogged);
      navigate('/'); 
    }).finally(() => {
      setShowLoading(false);
    });
  }, []);

  return (
    <>
      <div className="card w-auto text-center">
        <div className="card-body ps-4 pe-4">
          <p
            className="card-title mt-2 mb-4 text-primary fs-5">Log in to your
            account
          </p>
          <form onSubmit={login} action="/auth/login"
                className="needs-validation"
                noValidate={true} autoComplete="off">
            <div className="form-floating mb-3"><input id="floatingEmail"
                                                       type="email"
                                                       className="form-control form-control-lg"
                                                       placeholder="Email address"
                                                       autoComplete="nope"
                                                       required={true}/><label
              className="form-label" htmlFor="floatingEmail">Email
              address</label></div>
            <div className="form-floating mb-3"><input id="floatingPassword"
                                                       type="password"
                                                       className="form-control form-control-lg"
                                                       placeholder="Password"
                                                       autoComplete="nope"
                                                       required={true}/><label
              className="form-label"
              htmlFor="floatingPassword">Password</label>
            </div>
            <div className="d-grid mb-3">
              <button className="btn btn-primary btn-lg">
                <Spinner show={showLoading} />
                {!showLoading ? <span className={'mx-2'}>Log In</span> : ''}
              </button>
            </div>
          </form>
        </div>
        <div className="card-footer p-3 bg-transparent">
          <NavLink
            className="text-decoration-none text-secondary mb-2 mt-2"
            to={'/sign-up'}
          >New to Snoween? &nbsp;
            <span
              className="text-decoration-underline text-primary">Sign Up</span>
          </NavLink>
        </div>
      </div>
      <div className="mt-3">
        <NavLink className="text-white mb-2 mt-2"
                 to={'/forgot-password'}
        >Forgot
          your password?
        </NavLink>
      </div>
    </>
  );
};

export default Login;
