import { StatusCodes } from 'http-status-codes';
import { Pipes, RequestUtil, Try } from '../../../utils';
import { StoreStoreDto, StoreStoreCreateDto, StoreStoreUpdateDto } from './dto';

class StoreStoreService {

  static async save(data: StoreStoreDto) {
    const url = '/stores';
    if (data.uuid) {
      return RequestUtil.patch(
        `${url}/${data.uuid}`,
        Pipes.transform(StoreStoreUpdateDto, data),
        {},
        () => StoreStoreDto,
      );
    }
    return RequestUtil.post(url, Pipes.transform(StoreStoreCreateDto, data), {},
      () => StoreStoreDto);
  }
}

export { StoreStoreService };
