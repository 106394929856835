import React, { SetStateAction, useState } from 'react';
import { ShopProductVariantDto } from '../shop-product-variant.dto';
import { ShopProductVariantService } from '../shop-product-variant.service';

export interface IShopProductVariantFormUIModel {
  uuid: string;
  setUUID: React.Dispatch<SetStateAction<string>>;
  creatingOrEditing: boolean,
  setCreatingOrEditing: React.Dispatch<SetStateAction<boolean>>;
  code: string;
  setCode: React.Dispatch<SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<SetStateAction<string>>;
  shortDescription: string;
  setShortDescription: React.Dispatch<SetStateAction<string>>;
  enabled: boolean;
  setEnabled: React.Dispatch<SetStateAction<boolean>>;
  showSubmitLoading: boolean;
  setShowSubmitLoading: React.Dispatch<SetStateAction<boolean>>;
  empty: Function;
  fill: Function;
  save: Function;
  remove: Function;
}

const useCreateModel = () => {
  // Control
  const [creatingOrEditing, setCreatingOrEditing] = useState(false);
  const [showSubmitLoading, setShowSubmitLoading] = useState(false);

  // Model
  const [uuid, setUUID] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [enabled, setEnabled] = useState(false);

  const empty = () => {
    setUUID('');
    setCode('');
    setName('');
    setShortDescription('');
    setEnabled(false);
  };

  const fill = (dto: ShopProductVariantDto) => {
    setUUID(dto.uuid || '');
    setCode(dto.code || '');
    setName(dto.name || '');
    setShortDescription(dto.shortDescription || '');
    setEnabled(dto.enabled || false);
  };

  const save = () => {
    const transitory: ShopProductVariantDto = {
      uuid,
      code,
      name,
      shortDescription,
      enabled,
    };
    setShowSubmitLoading(true);
    ShopProductVariantService.save(transitory).then(response => {
      setUUID(response.uuid || '');
      setCreatingOrEditing(false);
    }).finally(() => {
      setShowSubmitLoading(false);
    });
  };

  const remove = async (dto: ShopProductVariantDto) => {
    setShowSubmitLoading(true);
    try {
      await ShopProductVariantService.delete(dto.uuid || '');
    } finally {
      setShowSubmitLoading(false);
    }
  };

  return {
    uuid,
    setUUID,
    creatingOrEditing,
    setCreatingOrEditing,
    code,
    setCode,
    name,
    setName,
    shortDescription,
    setShortDescription,
    enabled,
    setEnabled,
    showSubmitLoading,
    setShowSubmitLoading,
    empty,
    fill,
    save,
    remove,
  } as IShopProductVariantFormUIModel;
};

export { useCreateModel };