import { CacheUtil, RequestUtil } from '../../../utils';
import {
  OrderDto,
} from '../../../hooks/orders/dto/order.dto';
import { UpdateOrderDto } from '../../../hooks/orders/dto/update-order.dto';

class OrderActions {

  static async verify(uuid: string) {
    const storeUUID = CacheUtil.cache('x-store');
    const url = `/stores/${storeUUID}/orders/verify/${uuid}`;
    return RequestUtil.post(url, {},
      {},
      () => OrderDto,
    );
  }
  
  static async save(data: UpdateOrderDto) {
    const transformedData = {...data};
    const {uuid} = transformedData;
    delete transformedData.uuid;
    const storeUUID = CacheUtil.cache('x-store');
    const url = `/stores/${storeUUID}/orders/${uuid}`;
    return RequestUtil.patch(url, {...transformedData},
      {},
      () => OrderDto,
    );
  }
}

export { OrderActions };
