import React from 'react';

export interface IListingContainerContext<T> {
  action?: string;
  setAction?: Function;
  itemToEdit?: T;
  setItemToEdit?: Function;
  itemEdited?: T;
  setItemEdited?: Function;
}

const CreateListingContainerContext = <T>() =>
  React.createContext<IListingContainerContext<T>>({});

export {CreateListingContainerContext as default};
