import React, { useCallback, useState } from 'react';
import { ResetPasswordActions } from './reset-password.actions';
import ThankYouMsg from '../../tankyou/thank-you-msg';
import { NavLink, useParams } from 'react-router-dom';
import { Spinner } from '../../spinner/spinner';

const ResetPassword = () => {
  const [wasFormSubmitted, setWasFormSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const {token} = useParams();

  const resetPassword = useCallback((e: any) => {
    e.preventDefault();
    const $form = e.target;
    setShowLoading(true);
    ResetPasswordActions.resetPassword({
      url: $form.getAttribute('action') + `?r=${token}`,
      password: $form.elements.floatingPassword.value,
      rePassword: $form.elements.floatingRepeatPassword.value,
    }).then(() => {
      setWasFormSubmitted(true);
    }).finally(() => {
      setShowLoading(false);
    });

  }, []);

  return (
    <>
      <ThankYouMsg
        message={'Your password has been successfully changed!'}
        className={!wasFormSubmitted ? 'd-none' : ''}>
        <NavLink to={'/login'}
                 className={'text-white mt-4'}>Go to Log
          In</NavLink>
      </ThankYouMsg>
      <div className={wasFormSubmitted ? 'd-none' : 'card w-auto text-center'}>
        <div className="card-body ps-4 pe-4"><p
          className="card-title mt-2 mb-4 text-primary fs-5">Reset your
          password</p>
          <form onSubmit={resetPassword}
                action="/auth/reset-password"
                className="needs-validation"
                noValidate={true}
                autoComplete="off">
            <div className="form-floating mb-3"><input id="floatingPassword"
                                                       type="password"
                                                       className="form-control form-control-lg"
                                                       placeholder="New password"
                                                       autoComplete="nope"
                                                       required={true}/><label
              className="form-label"
              htmlFor="floatingPassword">New
              password</label></div>
            <div className="form-floating mb-3"><input
              id="floatingRepeatPassword"
              type="password"
              className="form-control form-control-lg"
              placeholder="Repeat Password"
              autoComplete="nope"
              required={true}/><label
              className="form-label"
              htmlFor="floatingRepeatPassword">Repeat
              Password</label></div>
            <div className="d-grid mb-3">
              <button className="btn btn-primary btn-lg">
                <Spinner show={showLoading}/>
                <span className={'mx-2'}>Reset password</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { ResetPassword as default };
