import React, { useContext } from 'react';
import AppContext, { IAppContext } from '../../context/app.context';
import { Navigate } from 'react-router-dom';

// @ts-ignore
const Authorized = ({children}) => {
  const {currentUser} = useContext(AppContext) as IAppContext;
  if (!currentUser || Object.keys(currentUser).length === 0) {
    return (<Navigate to={'/login'}/>);
  } else if (!currentUser.isVerified) {
    return (<Navigate to={'/verify'}/>);
  }
  return (
    <>
      {children}
    </>

  );
};

export default Authorized;
