import React, { useCallback, useContext, useState } from 'react';
import { MainLayout } from '../../../layouts/main.layout';
import { MainHeader } from '../../../components/main/main-header';
import { ShopMainHeader } from '../../../components/store/shop-main-header';
import MainBody from '../../../components/main/main-body';
import AppContext, { IAppContext } from '../../../context/app.context';
import { ListingContainer }
  from '../../../components/listing-container/listing-container';
import {
  PaymentGatewayDto,
} from '../../../hooks/payment-gateways/dto/payment-gateway.dto';
import CreateListingContainerContext
  , {
  IListingContainerContext,
} from '../../../components/listing-container/listing-container.context';
import
{
  PaymentGatewayItem,
} from '../../../components/store/payment-gateway/payment-gateway-item';
import useListStorePaymentGateways
  from '../../../hooks/payment-gateways/use-list-store-payment-gateways.hook';
import useListPaymentGateways
  from '../../../hooks/payment-gateways/use-list-payment-gateways.hook';
import EmptyComponentForm
  from '../../../components/listing-container/empty-component-form';
import { NavLink } from 'react-router-dom';

export const ShopPaymentGatewayListingContainerContext = CreateListingContainerContext<PaymentGatewayDto>();

const StorePaymentGatewayPage = () => {
  const {
    currentShop,
  } = useContext(AppContext) as IAppContext;
  // Listing Container context
  const [action, setAction] = useState('');
  const [itemToEdit, setItemToEdit] = useState({} as PaymentGatewayDto);
  const [itemEdited, setItemEdited] = useState({} as PaymentGatewayDto);
  const contextModalForm: IListingContainerContext<PaymentGatewayDto> = {
    action,
    setAction,
    itemToEdit,
    setItemToEdit,
    itemEdited,
    setItemEdited,
  };

  // Data
  const [fetching, setFetching] = useState(true);
  const listPaymentGateways = useListPaymentGateways();
  const listStorePaymentGateways = useListStorePaymentGateways();
  const data = useCallback(() => {
    if (!listPaymentGateways
      || !listStorePaymentGateways
      || listStorePaymentGateways.length === 0) {
      return [] as PaymentGatewayDto[];
    }
    setFetching(false);
    return listPaymentGateways.map(paymentGateway => {
      const paymentGatewayFound = listStorePaymentGateways.find(
        storePaymentGateway => storePaymentGateway.code ===
          paymentGateway.code);
      const enabled = paymentGatewayFound?.enabled;
      return {
        ...paymentGateway,
        enabled,
        settings: paymentGatewayFound?.settings || [],
      };
    });
  }, [listPaymentGateways, listStorePaymentGateways]);

  return (
    <MainLayout>
      <MainHeader className={'shadow-sm bg-dark'}>
        <ShopMainHeader/>
      </MainHeader>
      <MainBody>
        <ShopPaymentGatewayListingContainerContext.Provider value={contextModalForm}>
          <ListingContainer resource={{
            data: {
              fn: data,
              deps: [listPaymentGateways, listStorePaymentGateways],
              useStateFetching: [fetching, setFetching],
            },
          }}
                            breadcrumb={[
                              <NavLink className={'text-decoration-none'}
                                       to={'/store'}>Stores</NavLink>,
                              'Payment Gateway',
                              'All']}
                            itemComponentClass={PaymentGatewayItem}
                            formComponentClass={EmptyComponentForm}
                            ContextComponent={ShopPaymentGatewayListingContainerContext}
                            showSearchBar={false}
          />
        </ShopPaymentGatewayListingContainerContext.Provider>
      </MainBody>
    </MainLayout>
  );
};

export { StorePaymentGatewayPage };
