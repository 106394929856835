import { Expose, Transform } from 'class-transformer';

export class SnapshotOrderSummaryDto {
  @Expose()
  @Transform(({value}) => {
    return Number(value);
  })
  subtotal!: number;

  @Expose()
  @Transform(({value}) => {
    return Number(value);
  })
  total!: number;
}
