import React, { useCallback, useState } from 'react';
import { ForgotPasswordActions } from './forgot-password.actions';
import { NavLink } from 'react-router-dom';
import ThankYouMsg from '../../tankyou/thank-you-msg';
import { Spinner } from '../../spinner/spinner';

const ForgotPassword = () => {
  const [wasFormSubmitted, setWasFormSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const forgotPassword = useCallback((e: any) => {
    e.preventDefault();
    const $form = e.target;
    setShowLoading(true);
    ForgotPasswordActions.forgotPassword({
      url: $form.getAttribute('action'),
      email: $form.elements.floatingEmail.value,
    }).then(() => {
      setWasFormSubmitted(true);
    }).finally(() => {
      setShowLoading(false);
    });
  }, []);

  return (
    <>
      <ThankYouMsg
        message={'We\'ve sent an email with instructions to reset your password address'}
        className={!wasFormSubmitted ? 'd-none' : ''}/>
      <div className={wasFormSubmitted ? 'd-none' : 'card w-auto text-center'}>
        <div className="card-body ps-4 pe-4"><p
          className="card-title mt-2 mb-4 text-primary fs-5">Reset Password</p>
          <p className="card-title mt-2 mb-0 text-primary">Enter your email
            address below and</p><p
            className="card-title mt-0 mb-4 text-primary">we'll send you a link
            to reset your password.</p>
          <form onSubmit={forgotPassword}
                action="/auth/forgot-password"
                className="needs-validation"
                noValidate={true}
                autoComplete="off">
            <div className="form-floating mb-3"><input id="floatingEmail"
                                                       type="email"
                                                       className="form-control form-control-lg"
                                                       placeholder="Email address"
                                                       autoComplete="nope"
                                                       required={true}/><label
              className="form-label"
              htmlFor="floatingEmail">Email
              address</label></div>
            <div className="d-grid mb-3">
              <button className="btn btn-primary btn-lg">
                <Spinner show={showLoading}/>
                <span className={'mx-2'}>Reset Password</span>
              </button>
            </div>
          </form>
        </div>
        <div className="card-footer p-3 bg-transparent">
          <NavLink
            className="text-primary mb-2 mt-2"
            to={'/login'}
          >Log In</NavLink>
          <span
            className="mb-2 mt-2"> or </span>
          <NavLink className="text-primary mb-2 mt-2"
                   to={'/sign-up'}
          >Sign
            Up</NavLink></div>
      </div>
    </>
  );
};

export { ForgotPassword as default };
