import React, {
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';
import AppContext from '../../../context/app.context';
import { StoreStoreDto } from './dto';
import { UIModel } from '../../ui.model';

export interface IStoreStoreItemUIModel extends UIModel<StoreStoreDto> {
  name: string;
  setName: React.Dispatch<SetStateAction<string>>;
  slug: string;
  setSlug: React.Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<SetStateAction<string>>;
  storefrontURL: string;
  setStorefrontURL: React.Dispatch<SetStateAction<string>>;
  enabled: boolean;
  setEnabled: React.Dispatch<SetStateAction<boolean>>;
  isLive: boolean;
  setIsLive: React.Dispatch<SetStateAction<boolean>>;
}

const useCreateUIModel = (): IStoreStoreItemUIModel => {
  const appContext = useContext(AppContext);
  const thisRef = useRef(null);
  const [uuid, setUUID] = useState('');
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [email, setEmail] = useState('');
  const [storefrontURL, setStorefrontURL] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [data, setData] = useState([] as StoreStoreDto[]);

  const empty = () => {
    setUUID('');
    setName('');
    setSlug('');
    setEmail('');
    setEnabled(false);
    setIsLive(false);
    setData([]);
    setStorefrontURL('');
  };

  const fill = (model: StoreStoreDto) => {
    setUUID(model.uuid || '');
    setName(model.name || '');
    setSlug(model.slug || '');
    setEmail(model.email || '');
    setStorefrontURL(model.storefrontURL ?? '');
    setEnabled(model.enabled);
    setIsLive(model.isLive);
  };

  return {
    thisRef,
    uuid,
    setUUID,
    name,
    setName,
    slug,
    setSlug,
    email,
    setEmail,
    storefrontURL,
    setStorefrontURL,
    enabled,
    setEnabled,
    isLive,
    setIsLive,
    empty,
    fill,
    data,
    setData,
  };
};

export { useCreateUIModel };