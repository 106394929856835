import React, {
  useCallback,
  useContext, 
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { StoreStoreDto } from './dto';
import { Pipes } from '../../../utils';
import { IProps } from '../../../types';
import AppContext from '../../../context/app.context';
import { PubSubEnum } from '../../../enums/pub-sub.enum';
import {
  useCreateUIModel,
} from './store-store-item.uimodel';

interface IShopStoreItem extends IProps {
  model?: StoreStoreDto;
}

const StoreStoreItem = ({model}: IShopStoreItem) => {
  const {setCurrentShop} = useContext(AppContext);
  const thisRef = useRef(null);
  const [storeToEdit, setStoreToEdit] = useState(
    Pipes.transform(StoreStoreDto, model) as StoreStoreDto);
  const navigate = useNavigate();
  const uiModel = useCreateUIModel();
  
  useEffect(() => {
    uiModel?.fill && uiModel?.fill(model);
  }, [model, uiModel]);
  
  const onShowLiveEditor = useCallback(() => {
    PubSub.publish(PubSubEnum.STORE_LIVE_EDITOR_SHOW, model)
  }, [storeToEdit]);

  const changeCurrentStore = useCallback((callback: Function) => {
    setCurrentShop && setCurrentShop(storeToEdit);
    if (callback) {
      callback();
    }
  }, [storeToEdit]);

  return (
    (!model || !storeToEdit || !uiModel) ? <></> :
      <div
        ref={thisRef}
        key={uiModel.uuid}
        className="d-flex p-1 g-0 border rounded overflow-hidden mb-1 shadow-sm position-relative">
        <div className="flex-grow-1 overflow-auto">
          <div className="container-fluid row pe-0">
            <div
              className="col-md-6 col-lg-4 col-xl-3 ps-2 pe-2">
              <div className="h-24--px position-relative d-none">
                <Link
                  data-key={'code'}
                  className="text-decoration-none text-primary"
                  onClick={onShowLiveEditor}
                  to={''}>
                  <p className="pe-4 m-0 w-100 text-truncate">{uiModel.uuid}</p>
                </Link>
              </div>
              <div className="position-relative">
                <Link
                  data-key={'name'}
                  className="text-decoration-none text-reset fs-5"
                  onClick={onShowLiveEditor}
                  to={''}>
                  <p className="pe-4 m-0 w-100 text-truncate">{uiModel.name}</p>
                  <i
                    className="bi bi-pencil-square text-primary fs-6 position-absolute top-50 end-0 translate-middle-y d-none d-sm-inline"></i>
                </Link>
              </div>
              <div className="h-24--px position-relative">
                <Link
                  className="text-decoration-none text-muted"
                  onClick={onShowLiveEditor}
                  to={''}><p
                  className="pe-4 m-0 w-100 text-truncate fs-07rem">{uiModel.slug}</p>
                </Link>
              </div>
              <div
                className="h-24--px position-relative card-text mb-auto text-truncate">
                <Link className="text-decoration-none text-reset"
                      onClick={onShowLiveEditor}
                      to={''}>
                  <p className="pe-4 m-0 w-100 text-truncate">{''}</p>
                </Link>
              </div>
              <div
                className="d-flex justify-content-between h-24--px position-relative card-text mb-auto text-truncate">
                <Link className="text-decoration-none text-muted fs-07rem"
                      onClick={onShowLiveEditor}
                      to={''}>
                  <p className="pe-4 m-0 w-100 text-truncate">{
                    uiModel.enabled
                      ? <>
                        <i className={'bi bi-check-circle-fill text-success me-1'}></i>Enabled
                      </>
                      : <>
                        <i className={'bi bi-dash-circle-fill text-danger me-1'}></i>Disabled
                      </>
                  }</p>
                </Link>
                <Link className="text-decoration-none text-muted fs-07rem"
                      onClick={onShowLiveEditor}
                      to={''}>
                  <p className="pe-4 m-0 w-100 text-truncate">{
                    uiModel.isLive
                      ? <>
                        <i className={'bi bi-globe text-primary me-1'}></i>Live
                      </>
                      : <>
                        <i className={'bi bi-circle-fill text-secondary me-1'}></i>Sandbox
                      </>
                  }</p>
                </Link>
              </div>
            </div>
            <div className={'col-md-6 col-lg-8 col-xl-9 ps-2 pe-2 align-self-center'}>
              <div className="btn-group btn-group-lg"
                   role="group"
                   aria-label="Basic example">
                <button
                  type={'button'}
                  className="btn btn-outline-light text-secondary border-0 px-2 px-sm-3"
                  onClick={() => changeCurrentStore(
                    () => navigate('/store/products'))}
                >
                  <i className="bi bi-box2 fs-2"></i>
                  <p className={'d-none d-lg-block mb-0'}>
                    <span className="text-muted fs-07rem">Products</span>
                  </p>
                </button>
                <button
                  type={'button'}
                  className="btn btn-outline-light text-secondary border-0 px-2 px-sm-3"
                  onClick={() => changeCurrentStore(
                    () => navigate('/store/shipping-methods'))}
                >
                  <i className="bi bi-truck fs-2"></i>
                  <p className={'d-none d-lg-block mb-0'}>
                    <span className="text-muted fs-07rem">Shipping</span>
                  </p>
                </button>
                <button
                  type={'button'}
                  className="btn btn-outline-light text-secondary border-0 px-2 px-sm-3 d-none"
                  onClick={() => changeCurrentStore(
                    () => navigate('/store/payment-gateway'))}
                >
                  <i className="bi bi-credit-card fs-2"></i>
                  <p className={'d-none d-lg-block mb-0'}>
                    <span className="text-muted fs-07rem">Payments</span>
                  </p>
                </button>
                <button
                  type={'button'}
                  className="btn btn-outline-light text-secondary border-0 px-2 px-sm-3"
                  onClick={() => changeCurrentStore(
                    () => navigate('/store/cms'))}
                >
                  <i className="bi bi-square fs-2"></i>
                  <p className={'d-none d-lg-block mb-0'}>
                  <span className="fs-07rem text-primary">C<span
                    className="fs-07rem text-warning">M</span><span
                    className="fs-07rem text-danger">S</span></span>
                  </p>
                </button>
                <button
                  type={'button'}
                  className="btn btn-outline-light text-secondary border-0 px-2 px-sm-3"
                  onClick={() => changeCurrentStore(
                    () => navigate('/store/orders'))}
                >
                  <i className="bi bi-receipt fs-2"></i>
                  <p className={'d-none d-lg-block mb-0'}>
                    <span className="text-muted fs-07rem">Sales</span>
                  </p>
                </button>
                <button
                  type={'button'}
                  className="btn btn-outline-light text-secondary border-0 px-2 px-sm-3"
                  onClick={() => changeCurrentStore(
                    () => navigate('/store/bank-account'))}
                >
                  <i className="bi bi-bank fs-2"></i>
                  <p className={'d-none d-lg-block mb-0'}>
                    <span className="text-muted fs-07rem">Bank</span>
                  </p>
                </button>
                <a
                  className="btn btn-outline-light text-secondary border-0 px-2 px-sm-3"
                  href={uiModel.storefrontURL ?? ''}
                  target={'_blank'}
                >
                  <i className="bi bi-box-arrow-up-right fs-2"></i>
                  <p className={'d-none d-lg-block mb-0'}>
                    <span className="text-muted fs-07rem">Go to Store</span>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export { StoreStoreItem };
