import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CacheUtil } from './utils/cache';
import { RequestUtil } from './utils/request';
import { IAppContext } from './context/app.context';
import { Try } from './utils';
import { UserDto } from './context/dto/user.dto';
import { StoreDto } from './context/dto/store.dto';

async function initialContext(): Promise<IAppContext> {
  if (CacheUtil.cache('__jsession')) {
    const currentUser = await Try(() => RequestUtil.get('/auth/whichUser', {},
      () => UserDto));
    if (currentUser) {
      if (currentUser.stores) {
        const [store] = currentUser.stores;
        if (store) {
          const currentStore = CacheUtil.cache('x-store');
          if (!currentUser.stores.map((store: StoreDto) =>
            store.uuid).includes(currentStore)) {
            // @ts-ignore
            CacheUtil.cache('x-store', store.uuid);
          }
        }
      }
      return {currentUser: currentUser};
    }
    CacheUtil.removeCache('__jsession');
    CacheUtil.removeCache('x-store');
  }
  return {};
}

initialContext().then(appContext => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  root.render(
    <React.StrictMode>
      <App currentUser={appContext.currentUser}/>
    </React.StrictMode>,
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
