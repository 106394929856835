import React, { useContext, useEffect, useState } from 'react';
import AppContext, { IAppContext } from '../context/app.context';
import MainContainer from '../components/main/main-container';
import MainNavbar from '../components/main/main-navbar';
import { IProps } from '../types';
import { UIToast } from '../components/toast/toast.ui';
import { PubSubEnum } from '../enums/pub-sub.enum';
import { Alert } from '../components/modal/alert.modal';

const MainLayout = ({children}: IProps) => {
  const {currentUser} = useContext(AppContext) as IAppContext;
  const [avatarImage, setAvatarImage] = useState('');

  useEffect(() => {
    setAvatarImage(currentUser?.avatarMedia?.base64 ?? '');
  }, [currentUser]);

  return (
    <div className={'d-flex flex-column h-100'}>
      <header className={'z-index-1'}>
        <MainNavbar avatarImage={avatarImage}/>
      </header>
      <main className="position-absolute bg-light w-100 h-100 pt-5">
        <div
          className="d-flex align-items-center bg-light h-100 ps-1 pe-1 pt-2 ps-sm-4 pe-sm-4 pb-sm-3"
        >
          <MainContainer>
            {children}
          </MainContainer>
        </div>
        <UIToast topic={[
          PubSubEnum.TOAST_ERROR,
          PubSubEnum.TOAST_SAVED]}></UIToast>
        <Alert topicsAllowed={[
          PubSubEnum.ALERT_MODAL_MESSAGE,
          PubSubEnum.ALERT_CONFIRM_MODAL_MESSAGE]}></Alert>
      </main>
    </div>
  );
};

export { MainLayout };
