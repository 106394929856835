import { useContext, useEffect, useState } from 'react';
import { RequestUtil } from '../../utils';
import { ShippingMethodDto } from './dto/shipping-method.dto';
import AppContext from '../../context/app.context';

function useListStoreShippingMethods() {
  const {currentShop} = useContext(AppContext);
  const [shippingMethod, setShippingMethod] = useState(
    [] as Array<ShippingMethodDto>);
  useEffect(() => {
    if (!currentShop?.uuid) {
      setShippingMethod([]);
      return;
    }
    RequestUtil.get(`/stores/${currentShop?.uuid}/shipping-methods`, {},
      () => ShippingMethodDto,
      (result: json) => {
        return result.map((r: json) => r.shippingMethods).flat();
      },
    ).
      then(response => {
        setShippingMethod(response as Array<ShippingMethodDto>);
      }).
      catch(errors => {
        console.error('Error: useListStoreShippingMethods');
        setShippingMethod([] as Array<ShippingMethodDto>);
      });
  }, [currentShop]);
  return shippingMethod;
};

export { useListStoreShippingMethods };