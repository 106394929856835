import React, {
  Dispatch, SetStateAction,
  useCallback,
  useEffect, useRef,
  useState,
} from 'react';
import {
  IModal,
} from '../../../modal/modal';
import {
  PaymentGatewayDto,
} from '../../../../hooks/payment-gateways/dto/payment-gateway.dto';
import { PaymentGatewayActions } from '../payment-gateway.actions';
import { PaymentGatewayConstants } from '../payment-gateway-constants';
import {
  useParentUntilFrom,
} from '../../../modal/hooks/use-parent-until-from.hook';
import {
  BootstrapInstanceEnum,
} from '../../../modal/hooks/bootstrap-instance.enum';
import PupSub from 'pubsub-js';
import { YesModalSizeEnum, YesNoModal } from '../../../modal/yesno-modal';
import { PaymentGatewayLiveEditorFromEnum } from '../payment-gateway-item';

export interface IPaymentGatewayBaseDataLiveEditor extends IModal {
  paymentGateway: [PaymentGatewayDto, Dispatch<SetStateAction<PaymentGatewayDto>>];
}

const PaymentGatewayLiveEditor = ({
  paymentGateway,
}: IPaymentGatewayBaseDataLiveEditor) => {
  const thisRef = useRef(null);
  const parentUntilFrom = useParentUntilFrom([]);
  const [modalId, setModalId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [paymentGatewayToEdit, setPaymentGatewayEdited] = paymentGateway;

  // states for form
  const [paypalClientId, setPaypalClientId] = useState('');
  const [paypalAppSecret, setPaypalAppSecret] = useState('');
  const [paypalSandboxClientId, setPaypalSandboxClientId] = useState('');
  const [paypalSandboxAppSecret, setPaypalSandboxAppSecret] = useState('');
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setModalId(
      PaymentGatewayLiveEditorFromEnum.MODAL_LIVE_EDITOR_FORM_INSTANCE +
      paymentGatewayToEdit.uuid);
    const settings = paymentGatewayToEdit?.settings || [];
    const clientIdProperty = settings.find(
      s => s.property === PaymentGatewayConstants.PROPERTY_PAYPAL_CLIENT_ID);
    const secretIdProperty = settings.find(
      s => s.property === PaymentGatewayConstants.PROPERTY_PAYPAL_APP_SECRET);
    const sandboxClientIdProperty = settings.find(
      s => s.property ===
        PaymentGatewayConstants.PROPERTY_PAYPAL_SANDBOX_CLIENT_ID);
    const sandboxSecretIdProperty = settings.find(
      s => s.property ===
        PaymentGatewayConstants.PROPERTY_PAYPAL_SANDBOX_APP_SECRET);
    setEnabled && setEnabled(paymentGatewayToEdit?.enabled ?? false);
    setPaypalClientId && setPaypalClientId(clientIdProperty?.value || '');
    setPaypalAppSecret && setPaypalAppSecret(secretIdProperty?.value || '');
    setPaypalSandboxClientId &&
    setPaypalSandboxClientId(sandboxClientIdProperty?.value || '');
    setPaypalSandboxAppSecret &&
    setPaypalSandboxAppSecret(sandboxSecretIdProperty?.value || '');
  }, [paymentGatewayToEdit]);

  const onSave = useCallback(() => {
    const paymentGatewayTransitory = {
      ...paymentGatewayToEdit,
      enabled,
      settings: settings(),
    } as PaymentGatewayDto;
    setIsLoading(true);
    PaymentGatewayActions.save(paymentGatewayTransitory).then(() => {
      setPaymentGatewayEdited &&
      setPaymentGatewayEdited(paymentGatewayTransitory);

      // Handler Modal Event
      const $modal = parentUntilFrom(thisRef, 'modal',
        BootstrapInstanceEnum.MODAL);
      $modal.hide();
    }).finally(() => {
      setIsLoading(false);
    });
  }, [
    paymentGatewayToEdit,
    enabled,
  ]);

  useEffect(() => {
    // Subscriber
    PubSub.unsubscribe(modalId + '-save');
    PupSub.subscribe(modalId + '-save', onSave);
  }, [
    paymentGatewayToEdit,
    enabled,
  ]);

  const settings = useCallback(() => {
    switch (paymentGatewayToEdit?.code) {
      case 'IN_STORE':
        return [
          {
            value: '',
            property: PaymentGatewayConstants.PROPERTY_IN_STORE_CLIENT_ID,
          }];
      case 'PAYPAL':
        return [
          {
            value: paypalClientId,
            property: PaymentGatewayConstants.PROPERTY_PAYPAL_CLIENT_ID,
          },
          {
            value: paypalAppSecret,
            property: PaymentGatewayConstants.PROPERTY_PAYPAL_APP_SECRET,
          },
          {
            value: paypalSandboxClientId,
            property: PaymentGatewayConstants.PROPERTY_PAYPAL_SANDBOX_CLIENT_ID,
          },
          {
            value: paypalSandboxAppSecret,
            property: PaymentGatewayConstants.PROPERTY_PAYPAL_SANDBOX_APP_SECRET,
          },
        ];
      default:
        return [];
    }

  }, [paymentGatewayToEdit, paypalClientId, paypalAppSecret]);

  const showCustomFields = useCallback(() => {
    switch (paymentGatewayToEdit?.code) {
      case 'IN_STORE':
        return <></>;
      case 'PAYPAL':
        return <>
          <div className="card mb-3">
            <div className="card-header bg-primary text-white">
              <i className={'bi bi-globe me-2'}></i>Live
            </div>
            <div className="card-body">
              <div className="form-floating mb-3">
                <input id="payment-gateway.paypal.clientid"
                       value={paypalClientId}
                       onChange={(el) => setPaypalClientId(
                         el.currentTarget.value)}
                       type="text"
                       className="form-control form-control-lg"
                       placeholder="Client Id"
                       autoComplete="nope"
                       required={true}/>
                <label htmlFor={'payment-gateway.paypal.clientid'}
                       className="form-label">Client Id
                </label>
              </div>
              <div className="form-floating mb-3">
                <input id="payment-gateway.paypal.secret"
                       value={paypalAppSecret}
                       onChange={(el) => setPaypalAppSecret(
                         el.currentTarget.value)}
                       type="text"
                       className="form-control form-control-lg"
                       placeholder="App Secret"
                       autoComplete="nope"
                       required={true}/>
                <label htmlFor={'payment-gateway.paypal.secret'}
                       className="form-label">App Secret
                </label>
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-header">
              <i className={'bi bi-circle-fill text-muted me-2'}></i>Sandbox
            </div>
            <div className="card-body">
              <div className="form-floating mb-3">
                <input id="payment-gateway.sandbox.paypal.clientid"
                       value={paypalSandboxClientId}
                       onChange={(el) => setPaypalSandboxClientId(
                         el.currentTarget.value)}
                       type="text"
                       className="form-control form-control-lg"
                       placeholder="Client Id"
                       autoComplete="nope"
                       required={true}/>
                <label htmlFor={'payment-gateway.sandbox.paypal.clientid'}
                       className="form-label">Client Id
                </label>
              </div>
              <div className="form-floating mb-3">
                <input id="payment-gateway.sandbox.paypal.secret"
                       value={paypalSandboxAppSecret}
                       onChange={(el) => setPaypalSandboxAppSecret(
                         el.currentTarget.value)}
                       type="text"
                       className="form-control form-control-lg"
                       placeholder="App Secret"
                       autoComplete="nope"
                       required={true}/>
                <label htmlFor={'payment-gateway.sandbox.paypal.secret'}
                       className="form-label">App Secret
                </label>
              </div>
            </div>
          </div>
        </>;
      default:
        return <></>;
    }

  }, [paymentGatewayToEdit, paypalClientId, paypalAppSecret, enabled]);

  return (
    <YesNoModal id={modalId}
                size={YesModalSizeEnum.LG}
                className={modalId}
                header={(() => {
                  return <p className={`modal-title fs-5`}>Edit Product</p>;
                })()}
                body={
                  <div ref={thisRef}>
                    <p className="card-title text-muted fs-07rem">{`payment-gateway:${paymentGatewayToEdit?.uuid}`}</p>
                    <form className="needs-validation"
                          noValidate={true}
                          autoComplete="off">
                      <div className="d-flex justify-content-between">
                        <p className={'fs-4'}>{paymentGatewayToEdit?.name ??
                          ''}</p>
                        <div className="form-check form-switch align-self-center">
                          <input id="payment-gateway.enabled"
                                 className="form-check-input"
                                 type="checkbox"
                                 onChange={(el) => setEnabled(
                                   el.target.checked)}
                                 role="switch"
                                 checked={enabled}
                          />
                          <label className="form-check-label"
                                 htmlFor="payment-gateway.enabled">Enabled /
                            Disabled</label>
                        </div>
                      </div>
                      {showCustomFields()}
                    </form>
                  </div>
                }
                buttons={{
                  yes: {
                    label: 'Save',
                    isLoading: [isLoading, setIsLoading],
                  },
                }}
    ></YesNoModal>
  );
};

export default PaymentGatewayLiveEditor;
