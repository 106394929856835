import React from 'react';
import { CmsPropertyDto } from '../../../hooks/cms/dto/cms-property.dto';

export interface ICMSContext {
  data?: CmsPropertyDto[];
  setData?: Function;
}

const CMSContext = React.createContext({} as ICMSContext);

export { CMSContext as default };
