import { RequestUtil } from '../../utils/request';
import { ProfileEditAvatarDto } from './avatar/profile-edit-avatar.dto';
import { ProfileEditNameDto } from './name/profile-edit-name.dto';
import { ProfileEditEmailDto } from './email/profile-edit-email.dto';
import { StatusCodes } from 'http-status-codes';
import { ProfileEditPasswordDto } from './password/profile-edit-password.dto';
import { UserDto } from '../../context/dto/user.dto';

class ProfileEditActions {

  static async save(data: json, url?: string) {
    return RequestUtil.patch(url ?? '', data, {}, () => UserDto);
  }

  static async avatar(editAvatarDto: ProfileEditAvatarDto) {
    const data = {
      avatar: {
        ...editAvatarDto.avatar,
      },
    };
    return this.save(data, editAvatarDto.url);
  }

  static async fullName(editNameDto: ProfileEditNameDto) {
    const data = {
      ...editNameDto,
    };
    delete data.url;
    return this.save(data, editNameDto.url);
  }

  static async email(editEmailDto: ProfileEditEmailDto) {
    const data = {
      email: editEmailDto.newEmail,
    };
    return this.save(data, editEmailDto.url);
  }

  static async password(editPasswordDto: ProfileEditPasswordDto) {
    const data = {
      password: editPasswordDto.newPassword,
    };
    return this.save(data, editPasswordDto.url);
  }
}

export { ProfileEditActions };
