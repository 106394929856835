import React from 'react';
import MainBody from '../../components/main/main-body';
import VerifyUser from '../../components/verify-user/verify-user';
import LoginLayout from '../../layouts/login.layout';

const VerifyUserPage = () => {
  return (
    <LoginLayout>
      <MainBody>
        <VerifyUser/>
      </MainBody>
    </LoginLayout>
  );
};

export { VerifyUserPage as default };
