import React, { SyntheticEvent, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppContext, { IAppContext } from '../../context/app.context';
import { CacheUtil } from '../../utils';
import { IProps } from '../../types';

interface IShopMainDropdownSelector extends IProps {
  hideInPaths?: string[];
}

const ShopMainDropdownSelector = ({hideInPaths}: IShopMainDropdownSelector) => {
  const {
    currentUser,
    shopList,
    setShopList,
    currentShop,
    setCurrentShop,
  } = useContext(AppContext) as IAppContext;

  useEffect(() => {
    if (!currentShop) {
      return;
    }
    // @ts-ignore
    CacheUtil.cache('x-store', currentShop.uuid);
  }, [currentShop]);

  const onShopSelect = (evt: SyntheticEvent) => {
    const newCurrentShop = shopList?.find(
      shop => shop.uuid === evt.currentTarget.id);
    setCurrentShop &&
    setCurrentShop(newCurrentShop);
  };

  if (hideInPaths) {
    if (hideInPaths.includes(window?.location?.pathname)) {
      return <></>;
    }
  }

  return (
    <>
      <div className="btn-group"
           role="group"
           aria-label="Button group with nested dropdown">
        <div className="btn-group"
             role="group">
          <button
            type="button"
            className="btn btn-light rounded-top-start-1rem dropdown-toggle text-decoration-none ps-4 pe-4"
            data-bs-toggle="dropdown">
            <span className="fs-6">{currentShop?.name}</span>
          </button>
          <ul className="dropdown-menu z-index-1">
            {shopList && shopList.map(shop => (
              <li key={shop.uuid}>
                <Link id={shop.uuid}
                      className="dropdown-item"
                      onClick={onShopSelect}
                      to={''}>{shop.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <a
          className="btn btn-light border-start"
          href={'/store'}
          target={'_self'}
        >
          <i className="bi bi-shop"></i>
        </a>
        <a
          className="btn btn-light border-start"
          href={currentShop?.storefrontURL ?? ''}
          target={'_blank'}
        >
          <i className="bi bi-box-arrow-up-right"></i>
        </a>
      </div>
      {!currentShop?.isLive &&
          <span className="badge rounded-pill bg-warning text-dark ms-3 px-3 py-2">Sandbox</span>}
    </>
  );
};

export { ShopMainDropdownSelector };
