import React, {
  Dispatch, SetStateAction, useCallback,
  useEffect, useRef,
  useState,
} from 'react';
import {
  IModal,
} from '../../../modal/modal';
import { ProductDto } from '../dto/shop-product.dto';
import { ShopProductActions } from '../shop-product.actions';
import {
  useParentUntilFrom,
} from '../../../modal/hooks/use-parent-until-from.hook';
import { ShopProductLiveEditorFromEnum } from '../shop-product-item';
import {
  BootstrapInstanceEnum,
} from '../../../modal/hooks/bootstrap-instance.enum';
import PupSub from 'pubsub-js';
import { YesModalSizeEnum, YesNoModal } from '../../../modal/yesno-modal';

export interface IShopProductStockLiveEditor extends IModal {
  product: [ProductDto, Dispatch<SetStateAction<ProductDto>>];
}

const ShopProductStockLiveEditor = ({
  product,
}: IShopProductStockLiveEditor) => {
  const thisRef = useRef(null);
  const parentUntilFrom = useParentUntilFrom([]);
  const [modalId, setModalId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [productToEdit, setProductToEdit] = product;

  // states of each field
  const [stock, setStock] = useState('');

  useEffect(() => {
    setModalId(
      ShopProductLiveEditorFromEnum.MODAL_STOCK_LIVE_EDITOR_FORM_INSTANCE +
      productToEdit.uuid);
    setStock(`${productToEdit?.stock ?? 0}`);
  }, [productToEdit]);

  const onSave = useCallback(() => {
    const productTransitory = {
      ...productToEdit,
      stock: Number(stock),
    } as ProductDto;
    setIsLoading(true);
    ShopProductActions.save(productTransitory).then(() => {
      setProductToEdit && setProductToEdit(productTransitory);

      // Handler Modal Event
      const $modal = parentUntilFrom(thisRef, 'modal',
        BootstrapInstanceEnum.MODAL);
      $modal.hide();
    }).finally(() => {
      setIsLoading(false);
    });
  }, [
    productToEdit,
    stock,
  ]);

  useEffect(() => {
    // Subscriber
    PubSub.unsubscribe(modalId + '-save');
    PupSub.subscribe(modalId + '-save', onSave);
  }, [
    productToEdit,
    stock,
  ]);

  return (
    <YesNoModal id={modalId}
                size={YesModalSizeEnum.LG}
                className={modalId}
                header={(() => {
                  return <p className={`modal-title fs-5`}>Edit Product</p>;
                })()}
                body={
                  <div ref={thisRef}>
                    <p className="card-title text-muted fs-07rem">{`product:${productToEdit?.uuid}`}</p>
                    <form className="needs-validation"
                          noValidate={true}
                          autoComplete="off">
                      <div className="form-floating mb-3">
                        <input id="product.stock"
                               value={stock}
                               onChange={(el) => setStock(
                                 el.currentTarget.value)}
                               type="number"
                               step="0.0001"
                               className="form-control form-control-lg"
                               placeholder="Stock"
                               autoComplete="nope"
                               required={true}/>
                        <label htmlFor={'product.stock'}
                               className="form-label">Stock
                        </label>
                      </div>
                    </form>
                  </div>
                }
                buttons={{
                  yes: {
                    label: 'Save',
                    isLoading: [isLoading, setIsLoading],
                  },
                }}
    ></YesNoModal>
  );
};

export { ShopProductStockLiveEditor };
