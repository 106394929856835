import {Expose} from 'class-transformer';

export class ShopProductCreateDto {
  @Expose()
  id!: string;

  @Expose()
  uuid!: string;

  @Expose()
  name!: string;

  @Expose()
  longDescription?: string;

  @Expose()
  shortDescription?: string;

  @Expose()
  enabled!: boolean;
}
