import { Expose } from 'class-transformer';
import { ShopProductMediaDto } from './shop-product-media.dto';

export class ShopProductMediaCreateDto {
  @Expose()
  productUUID!: string;
  
  @Expose()
  medias?: ShopProductMediaDto[];
}
