import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ShopUserDto } from './dto/shop-user.dto';
import { Utils } from '../../../utils';
import { IProps } from '../../../types';
import {
  Modal,
  ModalActionsEnum,
  ModalInstance,
} from '../../modal/modal';
import ModalContext, { IModalContext } from '../../modal/context/modal.context';
import { ShopUserActions } from './shop-user.actions';
import {
  ShopUserListingContainerContext,
} from '../../../pages/store/users/shop.users.page';
import {
  ListingContainerActionsEnum,
} from '../../listing-container/listing-container';
import AppContext from '../../../context/app.context';

export enum ShopUserFromEnum {
  MODAL_FULL_FORM_INSTANCE = 'shop-user-form'
}

interface IShopUserForm extends IProps {
  model?: ShopUserDto;
}

const ShopUserForm = ({model}: IShopUserForm) => {
  const {
    currentShop,
  } = useContext(AppContext);
  const {
    action: listingContainerAction,
    setAction: setListingContainerAction,
    itemToEdit,
    setItemToEdit,
    setItemEdited,
    itemEdited,
  } = useContext(
    ShopUserListingContainerContext);

  // states for form
  const [title, setTitle] = useState('');

  // states for each field
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  // Listing Container
  useEffect(() => {
    switch (Utils.extractAction(listingContainerAction)) {
      case ListingContainerActionsEnum.CREATE:
        setTitle('New User');
        const $modalBootstrap = ModalInstance(
          ShopUserFromEnum.MODAL_FULL_FORM_INSTANCE);
        $modalBootstrap.show();
        break;
    }
  }, [listingContainerAction]);

  // Modal Form states and context
  const [showSpinner, setShowSpinner] = useState(false);
  const [action, setAction] = useState('');

  const modalContext = {
    showSpinner,
    setShowSpinner,
    action,
    setAction,
  } as IModalContext;

  // Item to Edit
  useEffect(() => {
    setFirstName && setFirstName(itemToEdit?.firstName ?? '');
    setLastName &&
    setLastName(itemToEdit?.lastName ?? '');
    setEmail &&
    setEmail(itemToEdit?.email ?? '');
    setPhone &&
    setPhone(itemToEdit?.phone ?? '');
  }, [itemToEdit]);

  // Modal Actions
  useEffect(() => {
    switch (Utils.extractAction(action)) {
      case ModalActionsEnum.SAVE:
        const userTransitory = {
          ...itemToEdit,
          firstName,
          email,
          lastName,
          phone,
        } as ShopUserDto;
        ShopUserActions.save(userTransitory, currentShop?.uuid ?? '').
          then(() => {
            setItemEdited && setItemEdited(userTransitory);
          });
        break;
    }
  }, [action, setItemEdited]);

  const userEditedCallback = useCallback(() => {
    const $modalBootstrap = ModalInstance(
      ShopUserFromEnum.MODAL_FULL_FORM_INSTANCE);
    $modalBootstrap.hide();
    setItemToEdit && setItemToEdit(undefined);
    if (!itemEdited?.id) {
      setListingContainerAction && setListingContainerAction(
        Utils.createActionString(ListingContainerActionsEnum.CREATED));
    }
  }, [itemEdited]);

  useEffect(() => {
    if (!itemEdited || Object.keys(itemEdited).length === 0) {
      return;
    }
    userEditedCallback();
  }, [userEditedCallback, itemEdited]);

  return (
    <ModalContext.Provider value={modalContext}>
      <Modal id={ShopUserFromEnum.MODAL_FULL_FORM_INSTANCE}
             title={title}>
        {itemToEdit?.id &&
            <p className="card-title text-muted fs-07rem">{`user:${itemToEdit?.id}`}</p>}
        <form className="needs-validation"
              noValidate={true}
              autoComplete="off">
          <div className="form-floating mb-3">
            <input id="shop.user.name"
                   value={firstName}
                   onChange={(el) => setFirstName(el.currentTarget.value)}
                   type="text"
                   className="form-control form-control-lg"
                   placeholder="First Name"
                   autoComplete="nope"
                   required={true}/>
            <label htmlFor={'shop.user.name'}
                   className="form-label">Name
            </label>
          </div>
          <div className="form-floating mb-3">
            <input id="shop.user.last-name"
                   value={lastName}
                   onChange={(el) => setLastName(
                     el.currentTarget.value)}
                   type="text"
                   className="form-control form-control-lg"
                   placeholder="Last Name"
                   autoComplete="nope"/>
            <label htmlFor={'shop.user.long-description'}>Last Name
            </label>
          </div>
          <div className="form-floating mb-3">
            <input id="shop.user.email"
                   value={email}
                   onChange={(el) => setEmail(
                     el.currentTarget.value)}
                   type="text"
                   className="form-control form-control-lg"
                   placeholder="Email"
                   autoComplete="nope"/>
            <label htmlFor={'shop.user.email'}
                   className="form-label">Email
            </label>
          </div>
          <div className="form-floating mb-3">
            <input id="shop.user.phone"
                   value={phone}
                   onChange={(el) => setPhone(
                     el.currentTarget.value)}
                   type="text"
                   className="form-control form-control-lg"
                   placeholder="Phone"
                   autoComplete="nope"/>
            <label htmlFor={'shop.user.phone'}
                   className="form-label">Phone
            </label>
          </div>
        </form>
      </Modal>
    </ModalContext.Provider>
  );
};

export { ShopUserForm as default };
