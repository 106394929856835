import { Expose } from 'class-transformer';

export class StoreDto {
  @Expose()
  uuid!: string;

  @Expose()
  name?: string;

  @Expose()
  slug?: string;

  @Expose()
  email?: string;

  @Expose()
  enabled!: boolean;

  @Expose()
  isLive!: boolean;

  @Expose()
  headOfficeAddressCity!: string;

  @Expose()
  headOfficeAddressState!: string;

  @Expose()
  headOfficeAddressLine1!: string;

  @Expose()
  headOfficeAddressLine2?: string;

  @Expose()
  headOfficeAddressPostalCode!: string;

  @Expose()
  defaultTaxCode?: string;

  @Expose()
  storefrontURL?: string;
}