import React, {
  useContext,
  useEffect,
} from 'react';
import {
  BootstrapInstanceEnum,
} from '../../../modal/hooks/bootstrap-instance.enum';
import useListCountryStates
  from '../../../../hooks/countries/use-list-country-states.hook';
import {
  IStoreStoreLiveEditorUIModel,
} from './store-store-live-editor.uimodel';
import { IProps } from '../../../../types';
import PubSub from 'pubsub-js';
import { PubSubEnum } from '../../../../enums/pub-sub.enum';
import {
  useQuerySelectorFrom,
} from '../../../modal/hooks/use-query-selector-from.hook';
import AppContext from '../../../../context/app.context';
import { NavLink } from 'react-router-dom';
import {
  ListingContainerEnum
} from '../../../listing-container/listing-container';

export interface IStoreStoreLiveEditorProps extends IProps {
  uiModel: IStoreStoreLiveEditorUIModel;
}

const StoreStoreLiveEditor = ({uiModel}: IStoreStoreLiveEditorProps) => {
  const {currentShop} = useContext(AppContext);
  const querySelectorFrom = useQuerySelectorFrom([]);
  
  const listStates = useListCountryStates({isoCode3: 'USA'}, []);

  useEffect(() => {
    setTimeout(() => {
      // Subscriber
      PubSub.unsubscribe(PubSubEnum.STORE_LIVE_EDITOR_SHOW);
      PubSub.subscribe(PubSubEnum.STORE_LIVE_EDITOR_SHOW,
        (topic, model) => {
          uiModel.empty();
          uiModel.fetchTaxCodes();
          uiModel.fill(model);
          const $modal = querySelectorFrom(uiModel.thisRef,
            `__self`,
            BootstrapInstanceEnum.MODAL);
          $modal?.show();
        });
    }, 0);

  }, [currentShop]);

  return (
    <div ref={uiModel.thisRef}
         className={`modal ${ListingContainerEnum.TOOLBAR_NEW_MODAL_INSTANCE}`}
         data-bs-backdrop="static"
         data-bs-keyboard="false">
      <div
        className={`modal-dialog modal-dialog-centered modal-dialog-scrollable`}>
        <div className="modal-content">
          <div className="modal-header">
            {uiModel.isLoading &&
                <div className="spinner-border spinner-border-sm me-2"
                     role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>}
            <p className={`modal-title fs-5`}>{uiModel.uuid ? 'Edit Store' : 'New Store'}</p>
            <NavLink className="btn-close"
                     data-bs-dismiss="modal"
                     to={''}></NavLink>
          </div>
          <div
            className={`modal-body`}>
            <form className="needs-validation"
                  noValidate={true}
                  autoComplete="off">
              <fieldset>
                <legend className={'fs-5'}>General Information</legend>
                <div className="form-floating mb-3">
                  <input id="store.name"
                         value={uiModel.name}
                         onChange={(el) => uiModel.setName(
                           el.currentTarget.value)}
                         type="text"
                         className="form-control form-control-lg"
                         placeholder="Name"
                         autoComplete="nope"
                         required={true}/>
                  <label htmlFor={'store.name'}
                         className="form-label">Name
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input id="store-slug"
                         value={uiModel.slug}
                         onChange={(el) => uiModel.setSlug(
                           el.currentTarget.value)}
                         type="text"
                         className="form-control form-control-lg"
                         placeholder="Slug"
                         autoComplete="nope"/>
                  <label htmlFor={'store-slug'}
                         className="form-label">Slug
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input id="store-email"
                         type="email"
                         value={uiModel.email}
                         onChange={(el) => uiModel.setEmail(
                           el.currentTarget.value)}
                         className="form-control form-control-lg"
                         placeholder="Email address"
                         autoComplete="nope"
                         required={true}/><label
                  className="form-label"
                  htmlFor="store-email">Email
                  address</label>
                </div>
              </fieldset>
              <hr/>
              <fieldset>
                <legend className={'fs-5'}>Head Office</legend>
                <div className="form-floating mb-3">
                  <input id="store-address-line-1"
                         type="text"
                         value={uiModel.addressLine1}
                         onChange={(el) => uiModel.setAddressLine1(
                           el.currentTarget.value)}
                         className="form-control form-control-lg"
                         placeholder="Adress Line 1"
                         autoComplete="nope"
                         required={true}/><label
                  className="form-label"
                  htmlFor="store-address-line-1">Address Line
                  1</label>
                </div>
                <div className="form-floating mb-3">
                  <input id="store-address-line-2"
                         type="text"
                         value={uiModel.addressLine2}
                         onChange={(el) => uiModel.setAddressLine2(
                           el.currentTarget.value)}
                         className="form-control form-control-lg"
                         placeholder="Adress Line 2"
                         autoComplete="nope"
                         required={true}/><label
                  className="form-label"
                  htmlFor="store-address-line-2">Address Line
                  2</label>
                </div>
                <div className="form-floating mb-3">
                  <input id="store-city"
                         type="text"
                         value={uiModel.city}
                         onChange={(el) => uiModel.setCity(
                           el.currentTarget.value)}
                         className="form-control form-control-lg"
                         placeholder="City"
                         autoComplete="nope"
                         required={true}/><label
                  className="form-label"
                  htmlFor="store-city">City</label>
                </div>
                <div className="form-floating mb-3">
                  <select className="form-select d-block w-100"
                          id="store-state"
                          value={uiModel.state}
                          onChange={(evt) => uiModel.setState(
                            evt.target.value)}
                          required>
                    <option value="">Choose...</option>
                    {
                      listStates?.map(
                        state => <option key={state.isoCode}
                                         value={state.isoCode}>{state.name}</option>) ||
                        <></>
                    }
                  </select>
                  <label
                    className="form-label"
                    htmlFor="store-state">State</label>
                </div>
                <div className="form-floating mb-3">
                  <input id="store-address-postal-code"
                         type="text"
                         value={uiModel.postalCode}
                         onChange={(el) => uiModel.setPostalCode(
                           el.currentTarget.value)}
                         className="form-control form-control-lg"
                         placeholder="Zip Code"
                         autoComplete="nope"
                         required={true}/><label
                  className="form-label"
                  htmlFor="store-address-postal-code">Zip
                  Code</label>
                </div>
              </fieldset>
              <hr/>
              <fieldset>
                <legend className={'fs-5'}>Tax Information</legend>
                <div className="form-floating mb-3">
                  <select id="store-default-tax-code"
                          className="form-select"
                          value={uiModel.taxCode || ''}
                          onChange={(evt) =>
                            uiModel.setTaxCode(evt.target.value)}>
                    <option>Choose...</option>
                    {uiModel.taxCodes?.map(t =>
                      <option key={t.id}
                              value={t.id}>{t.name}</option>)
                    }
                  </select>
                  <label
                    className="form-label"
                    htmlFor="store-default-tax-code">Tax Codes</label>
                </div>
              </fieldset>
              <hr/>
              <div className="form-check form-switch">
                <input id="store-enabled"
                       className="form-check-input"
                       type="checkbox"
                       onChange={(el) => uiModel.setEnabled(
                         el.target.checked)}
                       role="switch"
                       checked={uiModel.enabled}
                />
                <label className="form-check-label"
                       htmlFor="store-enabled">Enabled /
                  Disabled</label>
              </div>
              <div className="form-check form-switch mt-3">
                <input id="store-is-live"
                       className="form-check-input"
                       type="checkbox"
                       onChange={(el) => uiModel.setIsLive(el.target.checked)}
                       role="switch"
                       checked={uiModel.isLive}
                />
                <label className="form-check-label"
                       htmlFor="store-is-live">Live / Sandbox</label>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <NavLink className="btn btn-secondary"
                     data-bs-dismiss="modal"
                     to={''}
            >Cancel
            </NavLink>
            <button type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      uiModel.save!()
                    }}
                    disabled={uiModel.isLoading}
            >Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
    ;
};

export { StoreStoreLiveEditor };
