import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import { IProps } from '../../types';
import PubSub from 'pubsub-js';
import { PubSubEnum } from '../../enums/pub-sub.enum';
import { TrySync } from '../../utils';

export interface IAlertModal extends IProps {
  topicsAllowed: PubSubEnum[];
  title?: ReactNode;
  message?: ReactNode;
  okHandler?: (evt?: any) => any;
}

const Alert = ({
  topicsAllowed,
}: IAlertModal) => {
  const $ref = useRef(null);
  const [topic, setTopic] = useState('');
  const [title, setTitle] = useState(<></> as ReactNode);
  const [bodyMessage, setBodyMessage] = useState(<></> as ReactNode);
  const [footerButtons, setFooterButtons] = useState(<></> as ReactNode);
  const [id, setId] = useState(Date.now().toString());

  const buildFooterButtons = useCallback((topic: string, okHandler?: any) => {
    return TrySync(() => {
      switch (topic) {
        case PubSubEnum.ALERT_CONFIRM_MODAL_MESSAGE:
          return <div className="btn-group"
                      role="group"
                      aria-label="Basic example">
            <button type="button"
                    className="btn btn-outline-primary"
                    onClick={async (evt) => {
                      if (typeof okHandler !== 'function') {
                        return;
                      }
                      await okHandler(evt);
                      const $component = ($ref.current as any);
                      const $modal = bootstrap.Modal.getOrCreateInstance(
                        $component);
                      $modal.hide();
                    }}
            >Yes
            </button>
            <button className="btn btn-outline-primary"
                    data-bs-dismiss="modal"
            >No
            </button>
          </div>;
        default:
          return <NavLink className="btn btn-outline-secondary"
                          data-bs-dismiss="modal"
                          to={''}
          >Close
          </NavLink>;
      }
    });
  }, [topic]);

  useEffect(() => {
    if (!$ref) {
      return;
    }
    const $component = ($ref.current as any);
    const $modal = bootstrap.Modal.getOrCreateInstance($component);

    if (Array.isArray(topicsAllowed)) {

      if (topicsAllowed.includes(PubSubEnum.ALERT_MODAL_MESSAGE)) {
        PubSub.unsubscribe(PubSubEnum.ALERT_MODAL_MESSAGE);
        PubSub.subscribe(PubSubEnum.ALERT_MODAL_MESSAGE,
          (topic, data: IAlertModal) => {
            setTopic(topic);
            setId(Date.now().toString());
            setTitle(<div className={'d-flex px-1 align-items-center'}>
              <i className="bi bi-chat-dots fs-3"></i>
              <strong className={'align-middle ms-3'}>Message</strong>
            </div>);
            setBodyMessage(data.message);
            setFooterButtons(buildFooterButtons(topic, data.okHandler));
            setTimeout(() => {
              $modal && $modal.show && $modal.show();
            }, 0);
          });
      }

      if (topicsAllowed.includes(PubSubEnum.ALERT_CONFIRM_MODAL_MESSAGE)) {
        PubSub.unsubscribe(PubSubEnum.ALERT_CONFIRM_MODAL_MESSAGE);
        PubSub.subscribe(PubSubEnum.ALERT_CONFIRM_MODAL_MESSAGE,
          (topic, data: IAlertModal) => {
            setTopic(topic);
            setId(Date.now().toString());
            setTitle(<div className={'d-flex px-1 align-items-center'}>
              <i className="bi bi-exclamation-triangle fs-3"></i>
              <strong className={'align-middle ms-3'}></strong>
            </div>);
            setBodyMessage(data.message);
            setFooterButtons(buildFooterButtons(topic, data.okHandler));
            setTimeout(() => {
              $modal && $modal.show && $modal.show();
            }, 0);
          });
      }
    }
  }, []);

  return (
    <>
      <div ref={$ref}
           className={`modal`}
           data-bs-backdrop="static"
           data-bs-keyboard="false">
        <div
          className={`modal-dialog modal-dialog-centered modal-dialog-scrollable`}>
          <div className="modal-content">
            <div className="modal-header">
              <span className={`modal-title 'fs-5'}`}>{title}</span>
              <NavLink className="btn-close"
                       data-bs-dismiss="modal"
                       to={''}></NavLink>
            </div>
            <div
              className={`modal-body`}>
              {bodyMessage}
            </div>
            <div className="modal-footer">
              {footerButtons}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Alert };
