import { DependencyList, useCallback, MutableRefObject } from 'react';
import { findDOMNode } from 'react-dom';
import { BootstrapInstanceEnum } from './bootstrap-instance.enum';
import { isInstance } from 'class-validator';

function useQuerySelectorFrom(deps: DependencyList) {
  return useCallback(
    (
      ref: MutableRefObject<any> | Element,
      querySelector: string,
      bs?: BootstrapInstanceEnum) => {
      const $el = (isInstance(ref, Element) ? ref : findDOMNode(
        (ref as MutableRefObject<any>).current)) as Element;
      const component = querySelector === '__self' ? $el : $el.querySelector(
        querySelector);
      switch (bs) {
        case BootstrapInstanceEnum.MODAL:
          return bootstrap.Modal.getOrCreateInstance(component);
        default:
          return $el.parentNode?.parentNode?.parentNode?.parentNode;
      }
    }, deps);
}

export { useQuerySelectorFrom };