import React from 'react';
import { ShopMainDropdownSelector } from './shop-main-dropdown-selector';

const ShopMainHeader = () => {
  return (
    <>
      <div className={'h-52--px'}>
        <ShopMainDropdownSelector hideInPaths={[
          '/store',
        ]}/>
      </div>
    </>
  );
};

export { ShopMainHeader };
