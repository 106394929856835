import { useContext, useEffect, useState } from 'react';
import { Pipes, RequestUtil, Utils } from '../../utils';
import { PaymentGatewayDto } from './dto/payment-gateway.dto';
import AppContext from '../../context/app.context';

export default function useListStorePaymentGateways() {
  const {currentShop} = useContext(AppContext);
  const [paymentGateways, setPaymentGateways] = useState(
    [] as Array<PaymentGatewayDto>);
  useEffect(() => {
    if (!currentShop?.uuid) {
      setPaymentGateways([]);
      return;
    }
    RequestUtil.get(`/stores/${currentShop?.uuid}/payment-gateways`, {},
      () => PaymentGatewayDto,
      (result: json) => {
        return result.map((r: json) => {
          return {...r.paymentGateway, settings: r.settings};
        }).flat();
      },
    ).
      then(response => {
        setPaymentGateways(response as Array<PaymentGatewayDto>);
      }).
      catch(errors => {
        console.error('Error: useListPaymentGateways');
        setPaymentGateways([] as Array<PaymentGatewayDto>);
      });
  }, [currentShop]);
  return paymentGateways;
};