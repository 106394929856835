import { Expose, Type } from 'class-transformer';
import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { ShopProductVariantDto } from '../variants/shop-product-variant.dto';

export class ShopProductVariantItemDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  uuid?: string;

  @Expose()
  @Type(() => ShopProductVariantDto)
  variant?: ShopProductVariantDto;
  
  @Expose()
  @IsString()
  @IsNotEmpty()
  code!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  name!: string;

  @Expose()
  @IsOptional()
  shortName?: string;

  @Expose()
  @IsBoolean()
  enabled!: boolean;
}