import { Expose, Transform, Type } from 'class-transformer';
import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import {
  ShopProductVariantItemDto,
} from '../variants-items/shop-product-variant-item.dto';

export class ShopProductVariantDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  uuid?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  code!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  name!: string;

  @Expose()
  @IsOptional()
  shortDescription?: string;

  @Expose()
  @IsBoolean()
  enabled!: boolean;

  @Expose()
  @Transform(({obj, value}: {
    obj: ShopProductVariantDto,
    value: ShopProductVariantItemDto[]
  }) => {
    value?.map(v => {
      v.variant = {uuid: obj.uuid} as ShopProductVariantDto;
    });
    return value;
  })
  items?: ShopProductVariantItemDto[];
}