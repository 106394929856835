import { Expose } from 'class-transformer';

export class TaxCodeDto {
  @Expose()
  id!: string;

  @Expose()
  type?: string;

  @Expose()
  description?: string;

  @Expose()
  name?: string;
}
