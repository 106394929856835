import { useRef } from 'react';
import { DashboardChart } from './dashboard-chart';
import { DashboardSummary } from './dashboard-summary';
import { useDashboard } from '../../hooks/dashboard/use-dashboard.hook';
import { IProps } from '../../types';
import { DashboardDto } from '../../hooks/dashboard/dto/dashboard.dto';

export interface IDashboard extends IProps {
  data?: DashboardDto;
}

const Dashboard = () => {
  const thisRef = useRef(null);
  const dashboardData = useDashboard();

  return <div ref={thisRef}
              className="d-flex w-100 h-100">
    <div className="w-100 position-relative px-3">
      <DashboardSummary data={dashboardData}></DashboardSummary>
      <DashboardChart data={dashboardData}></DashboardChart>
    </div>
  </div>;
};

export { Dashboard };