import { DependencyList, useCallback } from 'react';
import { OrderDto } from './dto/order.dto';
import { OrderConstants } from '../../components/store/orders/order.constants';

function useStoreOrderPaymentStatus(deps: DependencyList) {
  return useCallback((order: OrderDto) => {
    switch (order?.cartSnapshot?.payment?.status) {
      case OrderConstants.PAYMENT_PENDING:
        return [
          'Pending',
          'bg-danger',
          OrderConstants.PAYMENT_PENDING];
      case OrderConstants.PAYMENT_PAID:
        return ['Paid', 'bg-success', OrderConstants.PAYMENT_PAID];
      default:
        return [
          order?.cartSnapshot?.payment?.status || OrderConstants.UNKNOWN,
          'bg-primary',
          OrderConstants.UNKNOWN];
    }
  }, deps);
}

export { useStoreOrderPaymentStatus };