import React, { useContext } from 'react';
import { MainLayout } from '../../../layouts/main.layout';
import { MainHeader } from '../../../components/main/main-header';
import { ShopMainHeader } from '../../../components/store/shop-main-header';
import MainBody from '../../../components/main/main-body';
import AppContext, { IAppContext } from '../../../context/app.context';
import { ListingContainer }
  from '../../../components/listing-container/listing-container';
import {
  OrderDto,
} from '../../../hooks/orders/dto/order.dto';
import CreateListingContainerContext
  , {
} from '../../../components/listing-container/listing-container.context';
import
{
  OrderItem,
} from '../../../components/store/orders/order-item';
import EmptyComponentForm
  from '../../../components/listing-container/empty-component-form';
import { NavLink } from 'react-router-dom';

export const StoreOrderListingContainerContext = CreateListingContainerContext<OrderDto>();

const StoreOrderPage = () => {
  const {
    currentShop,
  } = useContext(AppContext) as IAppContext;

  return (
    <MainLayout>
      <MainHeader className={'shadow-sm bg-dark'}>
        <ShopMainHeader/>
      </MainHeader>
      <MainBody>
        <StoreOrderListingContainerContext.Provider value={{}}>
          <ListingContainer resource={{
            url: `/stores/${currentShop?.uuid}/orders`,
            type: () => OrderDto,
            interceptor: (response) => {
              return response.rows as Array<OrderDto> ?? [];
            },
          }}
                            breadcrumb={[
                              <NavLink className={'text-decoration-none'}
                                       to={'/store'}>Stores</NavLink>,
                              'Orders',
                              'All']}
                            itemComponentClass={OrderItem}
                            formComponentClass={EmptyComponentForm}
                            ContextComponent={StoreOrderListingContainerContext}
                            showSearchBar={false}
          />
        </StoreOrderListingContainerContext.Provider>
      </MainBody>
    </MainLayout>
  );
};

export { StoreOrderPage };
