import React, {
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { ProductDto } from './dto';
import { Pipes, Utils } from '../../../utils';
import { IProps } from '../../../types';
import { PubSubEnum } from '../../../enums/pub-sub.enum';
import { IAlertModal } from '../../modal/alert.modal';
import {
  BootstrapInstanceEnum,
} from '../../modal/hooks/bootstrap-instance.enum';
import {
  useQuerySelectorFrom,
} from '../../modal/hooks/use-query-selector-from.hook';
import { ShopProductMediaLiveEditor }
  from './live-editor/shop-product-media-live-editor';
import { ShopProductBaseDataLiveEditor }
  from './live-editor/shop-product-base-data-live-editor';
import { ShopProductStockLiveEditor }
  from './live-editor/shop-product-stock-live-editor';
import { ShopProductPriceLiveEditor }
  from './live-editor/shop-product-price-live-editor';
import { ShopProductEnabledLiveEditor }
  from './live-editor/shop-product-enabled-live-editor';

export enum ShopProductLiveEditorFromEnum {
  MODAL_BASE_DATA_LIVE_EDITOR_FORM_INSTANCE = 'shop-product-modal-base-data-live-editor',
  MODAL_STOCK_LIVE_EDITOR_FORM_INSTANCE = 'shop-product-modal-stock-live-editor',
  MODAL_PRICE_LIVE_EDITOR_FORM_INSTANCE = 'shop-product-modal-price-live-editor',
  MODAL_ENABLED_LIVE_EDITOR_FORM_INSTANCE = 'shop-product-modal-enabled-live-editor',
  MODAL_PRODUCT_MEDIA_LIVE_EDITOR_FORM_INSTANCE = 'shop-product-modal-product-media-live-editor',
}

interface IShopProductItem extends IProps {
  model?: ProductDto;
}

const ShopProductItem = ({model}: IShopProductItem) => {
  const thisRef = useRef(null);
  const querySelectorFrom = useQuerySelectorFrom([]);
  const [productToEdit, setProductToEdit] = useState(
    Pipes.transform(ProductDto, model) as ProductDto);
  
  if (!model) {
    return <div key={Utils.random()}></div>;
  }

  if (!productToEdit) {
    return <div key={Utils.random()}></div>;
  }

  const onShowMediaLiveEditor = () => {
    const $modal = querySelectorFrom(thisRef,
      `.${ShopProductLiveEditorFromEnum.MODAL_PRODUCT_MEDIA_LIVE_EDITOR_FORM_INSTANCE +
      productToEdit.uuid}`,
      BootstrapInstanceEnum.MODAL);
    $modal?.show();
  };

  const onShowBaseDataLiveEditor = () => {
    const $modal = querySelectorFrom(thisRef,
      `.${ShopProductLiveEditorFromEnum.MODAL_BASE_DATA_LIVE_EDITOR_FORM_INSTANCE +
      productToEdit.uuid}`,
      BootstrapInstanceEnum.MODAL);
    $modal?.show();
  };

  const onShowStockLiveEditor = () => {
    const $modal = querySelectorFrom(thisRef,
      `.${ShopProductLiveEditorFromEnum.MODAL_STOCK_LIVE_EDITOR_FORM_INSTANCE +
      productToEdit.uuid}`,
      BootstrapInstanceEnum.MODAL);
    $modal?.show();
  };

  const onShowEnabledLiveEditor = () => {
    const $modal = querySelectorFrom(thisRef,
      `.${ShopProductLiveEditorFromEnum.MODAL_ENABLED_LIVE_EDITOR_FORM_INSTANCE +
      productToEdit.uuid}`,
      BootstrapInstanceEnum.MODAL);
    $modal?.show();
  };

  const onShowPriceLiveEditor = () => {
    const $modal = querySelectorFrom(thisRef,
      `.${ShopProductLiveEditorFromEnum.MODAL_PRICE_LIVE_EDITOR_FORM_INSTANCE +
      productToEdit.uuid}`,
      BootstrapInstanceEnum.MODAL);
    $modal?.show();
  };

  const onShowAlertHorizontalDevice = () => {
    PubSub.publish(PubSubEnum.ALERT_MODAL_MESSAGE,
      {
        message: <>
          <p>Please use landscape mode to edit it.</p>
          <div className={'d-flex justify-content-center'}>
            <i className="bi bi-phone fs-1"></i>
            <i className="bi bi-arrow-right-short fs-1 mx-2"></i>
            <i className="bi bi-phone-landscape fs-1"></i>
          </div>
        </>,
      } as IAlertModal);
  };

  return (
    <div
      ref={thisRef}
      key={productToEdit.id}
      className="d-flex p-1 g-0 border rounded overflow-hidden mb-1 shadow-sm max-h-140--px position-relative">
      <Link className="stretched-link d-block d-md-none"
            onClick={onShowAlertHorizontalDevice}
            to={''}></Link>
      <Link
        data-key={'image'}
        onClick={onShowMediaLiveEditor}
        to={''}
        className="flex-shrink-0 position-relative g-0 border rounded w-120--px">
        {productToEdit?.mainMedia && <img src={productToEdit?.mainMedia.base64}
                                          width={120}/>}
        {!productToEdit?.mainMedia && <i
            className="bi bi-box2 fs-1 position-absolute top-50 start-50 translate-middle"></i>}

      </Link>
      <div className="flex-grow-1 overflow-auto">
        <div className="container-fluid row pe-0">
          <div
            className="col-12 col-md-6 col-lg-4 col-xl-3 ps-2 pe-2">
            <div className="h-24--px position-relative d-none">
              <Link
                data-key={'code'}
                className="text-decoration-none text-primary"
                onClick={onShowBaseDataLiveEditor}
                to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{productToEdit.uuid}</p>
              </Link>
            </div>
            <div className="position-relative">
              <Link
                data-key={'name'}
                className="text-decoration-none text-reset fs-5"
                onClick={onShowBaseDataLiveEditor}
                to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{productToEdit.name}</p>
                <i
                  className="bi bi-pencil-square text-primary fs-6 position-absolute top-50 end-0 translate-middle-y d-none d-sm-inline"></i>
              </Link>
            </div>
            <div className="h-24--px position-relative">
              <Link
                className="text-decoration-none text-muted"
                onClick={onShowBaseDataLiveEditor}
                to={''}><p
                className="pe-4 m-0 w-100 text-truncate">{productToEdit.slug}</p>
              </Link>
            </div>
            <div
              className="h-24--px position-relative card-text mb-auto text-truncate">
              <Link className="text-decoration-none text-reset"
                    onClick={onShowBaseDataLiveEditor}
                    to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{productToEdit.longDescription}</p>
              </Link>
            </div>
            <div
              className="h-24--px position-relative card-text mb-auto text-truncate">
              <Link className="text-decoration-none text-muted"
                    onClick={onShowBaseDataLiveEditor}
                    to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{productToEdit.shortDescription}</p>
              </Link>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-4 col-xl-3 d-none d-md-block ps-2 pe-2">
                        <span
                          className="card-title text-muted fs-07rem">Stock</span>
            <div className="position-relative">
              <Link
                className="text-decoration-none text-muted d-none d-sm-inline"
                onClick={onShowStockLiveEditor}
                to={''}>
                <p
                  className="pe-4 m-0 w-100 text-truncate">Stock
                  ({productToEdit.stock})</p>
                <i
                  className="bi bi-pencil-square text-primary position-absolute top-50 end-0 translate-middle-y"></i>
              </Link>
            </div>
            <div className="position-relative">
              <Link
                className="text-decoration-none text-muted d-none d-sm-inline"
                onClick={onShowPriceLiveEditor}
                to={''}>
                <p
                  className="pe-4 m-0 w-100 text-truncate">Price
                  ({productToEdit.price ?? 0})</p>
                <i
                  className="bi bi-pencil-square text-primary position-absolute top-50 end-0 translate-middle-y"></i>
              </Link>
            </div>
            <div
              className="h-24--px position-relative card-text mb-auto text-truncate">
              <Link className="text-decoration-none text-reset"
                    to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{''}</p>
              </Link>
            </div>
            <div className="position-relative">
              <Link
                className="text-decoration-none text-muted d-none d-sm-inline"
                onClick={onShowEnabledLiveEditor}
                to={''}
              >
                <p
                  className="pe-4 m-0 w-100 text-truncate fs-7">{
                  productToEdit.enabled
                    ? <>
                      <i className={'bi bi-check-circle-fill text-success me-1'}></i>Enabled
                    </>
                    : <>
                      <i className={'bi bi-dash-circle-fill text-danger me-1'}></i>Disabled
                    </>
                }</p>
                <i
                  className="bi bi-pencil-square text-primary position-absolute top-50 end-0 translate-middle-y"></i>
              </Link>
            </div>
          </div>
          <div
            className="col-lg-4 col-xl-3 d-none ps-2 pe-2">
                        <span
                          className="card-title text-muted fs-07rem">Categories</span>
            <div className="bg-light h-75">
              <Link
                className="text-decoration-none float-end d-none d-sm-inline"
                to={''}>
                <i
                  className="bi bi-pencil-square"></i>
              </Link>
            </div>
          </div>
          <div className="col-3 d-none ps-2 pe-2"><span
            className="card-title text-muted fs-07rem">Variants</span>
            <div className="bg-light h-75">
              <Link
                className="text-decoration-none float-end d-none d-sm-inline"
                to={''}>
                <i
                  className="bi bi-pencil-square"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ShopProductMediaLiveEditor product={[
        productToEdit,
        setProductToEdit]}/>
      <ShopProductBaseDataLiveEditor product={[
        productToEdit,
        setProductToEdit]}/>
      <ShopProductStockLiveEditor product={[
        productToEdit,
        setProductToEdit]}/>
      <ShopProductPriceLiveEditor product={[
        productToEdit,
        setProductToEdit]}/>
      <ShopProductEnabledLiveEditor product={[
        productToEdit,
        setProductToEdit]}/>
    </div>
  );
};

export { ShopProductItem };
