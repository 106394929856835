import { useContext, useEffect } from 'react';
import AppContext from '../../context/app.context';
import { IDashboard } from './dashboard';
import { Utils } from '../../utils';

const DashboardSummary = ({data}: IDashboard) => {
  const {currentShop} = useContext(AppContext);

  useEffect(() => {
    if (Object.keys(currentShop || {}).length === 0) {
      return;
    }
  }, [currentShop]);

  return <div className={'container-fluid'}>
    <div className={'row h-100 my-3'}>
      <div className="col-12 col-md-6 col-lg-3 ps-0 pe-0 pe-sm-2 pe-lg-4 mb-2 mb-md-0">
        <div className="rounded border border-1">
          <div className="d-flex justify-content-between rounded border-5 border-start border-primary h-100 px-3 py-3">
            <div>
              <div className="fs-07rem text-primary text-uppercase mb-2">
                Earnings (Last 30 Days)
              </div>
              <div className="fs-5 mb-0 text-secondary">{Utils.formatNumber(
                data?.summary?.earnings?.monthly ?? 0)}</div>
            </div>
            <div className="align-self-md-center">
              <i className="bi bi-calendar text-primary fs-2"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block col-6 col-lg-3 ps-0 pe-0 pe-sm-2 pe-lg-4">
        <div className="rounded border border-1">
          <div className="d-flex justify-content-between rounded border-5 border-start border-primary h-100 px-3 py-3">
            <div>
              <div className="fs-07rem text-primary text-uppercase mb-2">
                Earnings (Last 365 Days)
              </div>
              <div className="fs-5 mb-0 text-secondary">{Utils.formatNumber(
                data?.summary?.earnings?.annual ?? 0)}</div>
            </div>
            <div className="align-self-md-center">
              <i className="bi bi-currency-dollar text-primary fs-2"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3 ps-0 pe-0 pe-sm-2 pe-lg-4">
        <div className="rounded border border-1">
          <div className="d-flex justify-content-between rounded border-5 border-start border-success h-100 px-3 py-3">
            <div>
              <div className="fs-07rem text-success text-uppercase mb-2">
                Sales Quantity (Last 30 Days)
              </div>
              <div className="fs-5 mb-0 text-secondary">{data?.summary?.qty_orders?.monthly ??
                0}</div>
            </div>
            <div className="align-self-md-center">
              <i className="bi bi-receipt text-success fs-2"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block col-6 col-lg-3 ps-0 pe-0 pe-sm-2 mb-2 mb-md-0">
        <div className="rounded border border-1">
          <div className="d-flex justify-content-between rounded border-5 border-start border-warning h-100 px-3 py-3">
            <div>
              <div className="fs-07rem text-warning text-uppercase mb-2">
                Pending Orders
              </div>
              <div className="fs-5 mb-0 text-secondary">{data?.summary?.qty_orders?.currently ??
                0}</div>
            </div>
            <div className="align-self-md-center">
              <i className="bi bi-stopwatch text-warning fs-2"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export { DashboardSummary };