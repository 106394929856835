import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Pipes } from '../../../utils';
import { IProps } from '../../../types';
import {
  ShippingMethodDto,
} from '../../../hooks/shipping-methods/dto/shipping-method.dto';
import {
  useQuerySelectorFrom,
} from '../../modal/hooks/use-query-selector-from.hook';
import {
  BootstrapInstanceEnum,
} from '../../modal/hooks/bootstrap-instance.enum';
import ShippingMethodLiveEditor
  from './live-editor/shipping-method-live-editor';

export enum ShippingMethodLiveEditorFromEnum {
  MODAL_LIVE_EDITOR_FORM_INSTANCE = 'payment-gateway-modal-base-data-live-editor'
}

interface IShippingMethodItem extends IProps {
  model?: ShippingMethodDto;
}

const ShippingMethodItem = ({model}: IShippingMethodItem) => {
  const thisRef = useRef(null);
  const querySelectorFrom = useQuerySelectorFrom([]);
  const [shippingMethodToEdit, setShippingMethodToEdit] = useState(
    {} as ShippingMethodDto);

  useEffect(() => {
    setShippingMethodToEdit(
      Pipes.transform(ShippingMethodDto, model) as ShippingMethodDto);
  }, [model]);

  const onShowLiveEditor = () => {
    const $modal = querySelectorFrom(thisRef,
      `.${ShippingMethodLiveEditorFromEnum.MODAL_LIVE_EDITOR_FORM_INSTANCE +
      shippingMethodToEdit.uuid}`,
      BootstrapInstanceEnum.MODAL);
    $modal?.show();
  };

  return (
    <div
      ref={thisRef}
      key={shippingMethodToEdit.uuid}
      className="d-flex p-1 g-0 border rounded overflow-hidden mb-1 shadow-sm max-h-140--px position-relative">
      <div
        className="flex-shrink-0 position-relative g-0 border rounded w-120--px">
        <i
          className="bi bi-box2 fs-1 position-absolute top-50 start-50 translate-middle"></i>
      </div>
      <div className="flex-grow-1 overflow-auto">
        <div className="container-fluid row pe-0">
          <div
            className="col-12 col-md-6 col-lg-4 col-xl-3 ps-2 pe-2">
            <div className="h-24--px position-relative d-none">
              <Link
                data-key={'code'}
                className="text-decoration-none text-primary"
                onClick={onShowLiveEditor}
                to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{shippingMethodToEdit.uuid}</p>
              </Link>
            </div>
            <div className="position-relative">
              <Link
                data-key={'name'}
                className="text-decoration-none text-reset fs-5"
                onClick={onShowLiveEditor}
                to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{shippingMethodToEdit.name}</p>
                <i
                  className="bi bi-pencil-square text-primary fs-6 position-absolute top-50 end-0 translate-middle-y d-none d-sm-inline"></i>
              </Link>
            </div>
            <div
              className="h-24--px position-relative card-text mb-auto text-truncate">
              <Link className="text-decoration-none text-reset"
                    onClick={onShowLiveEditor}
                    to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{''}</p>
              </Link>
            </div>
            <div
              className="h-24--px position-relative card-text mb-auto text-truncate">
              <Link className="text-decoration-none text-muted fs-07rem"
                    onClick={onShowLiveEditor}
                    to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{
                  shippingMethodToEdit.enabled
                    ? <>
                      <i className={'bi bi-check-circle-fill text-success me-1'}></i>Enabled
                    </>
                    : <>
                      <i className={'bi bi-dash-circle-fill text-danger me-1'}></i>Disabled
                    </>
                }</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ShippingMethodLiveEditor shippingMethod={[
        shippingMethodToEdit,
        setShippingMethodToEdit]}></ShippingMethodLiveEditor>
    </div>
  );
};

export { ShippingMethodItem };
