import { DependencyList, useCallback } from 'react';
import { OrderDto } from './dto/order.dto';
import { OrderConstants } from '../../components/store/orders/order.constants';

function useStoreOrderShippingStatus(deps: DependencyList) {
  return useCallback((order: OrderDto) => {
    switch (order?.cartSnapshot?.shipping?.status) {
      case OrderConstants.SHIPPING_PENDING:
        return ['Pending', 'bg-danger', OrderConstants.SHIPPING_PENDING];
      case OrderConstants.SHIPPING_SENT:
        return ['Sent', 'bg-success', OrderConstants.SHIPPING_SENT];
      default:
        return [
          order?.cartSnapshot?.shipping?.status || OrderConstants.UNKNOWN,
          'bg-primary',
          OrderConstants.UNKNOWN];
    }
  }, deps);
}

export { useStoreOrderShippingStatus };