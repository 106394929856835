import React, { ReactNode } from 'react';
import { IProps } from '../../types';

export interface IThankYouMsg extends IProps {
  message: ReactNode;
  iconClassName?: string;
}

const ThankYouMsg = ({message, iconClassName, className, children}: IThankYouMsg) => {
  return (
    <>
      <div
        className={className ? className : 'd-flex flex-column text-white text-center justify-content-center align-items-center h-100'}>
        <i className={iconClassName ? iconClassName : "bi bi-check-circle text-white fs-5rem"}></i>
        <h2 className="mt-5 ps-5 pe-5">{message}</h2>
        {children}
      </div>
    </>
  );
};

export { ThankYouMsg as default };
