import React, { useCallback, useContext, useState } from 'react';
import { MainLayout } from '../../../layouts/main.layout';
import { MainHeader } from '../../../components/main/main-header';
import { ShopMainHeader } from '../../../components/store/shop-main-header';
import MainBody from '../../../components/main/main-body';
import { CMSForm } from '../../../components/store/cms/cms-form';
import AppContext, { IAppContext } from '../../../context/app.context';
import { useCMSProperties } from '../../../hooks/cms/use-cms-properties.hook';
import CMSContext, {
  ICMSContext,
} from '../../../components/store/cms/cms.context';
import { CmsPropertyDto } from '../../../hooks/cms/dto/cms-property.dto';
import { NavLink } from 'react-router-dom';

const StoreCmsPage = () => {
  const {
    currentShop,
  } = useContext(AppContext) as IAppContext;

  // context
  const [data, setData] = useState([] as CmsPropertyDto[]);
  const context: ICMSContext = {
    data,
    setData,
  };

  const cmsProperties = useCMSProperties();
  const dataFn = useCallback(() => {
    if (!cmsProperties
      || cmsProperties.length === 0) {
      return [] as CmsPropertyDto[];
    }
    return cmsProperties;
  }, [cmsProperties]);

  return (
    <MainLayout>
      <MainHeader className={'shadow-sm bg-dark'}>
        <ShopMainHeader/>
      </MainHeader>
      <MainBody>
        <CMSContext.Provider value={context}>
          <CMSForm resource={{
            url: `/stores/${currentShop?.uuid}/cms`,
            data: {
              fn: dataFn,
              deps: [cmsProperties],
            },
          }}
                   breadcrumb={[
                     <NavLink className={'text-decoration-none'}
                              to={'/store'}>Stores</NavLink>,
                     'CMS',
                     'Sections']}/>
        </CMSContext.Provider>
      </MainBody>
    </MainLayout>
  );
};

export { StoreCmsPage };
