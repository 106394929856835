import React from 'react';
import LoginLayout from '../../layouts/login.layout';
import ResetPassword from '../../components/auth/reset-password/reset-password';

const ResetPasswordPage = () => {
  return (
    <LoginLayout>
      <ResetPassword></ResetPassword>
    </LoginLayout>
  );
};

export { ResetPasswordPage as default };
