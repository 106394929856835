import { Expose } from 'class-transformer';
import { BankAccountStatusEnum } from '../bank-account-status.enum';

export class BankAccountStatusDto {
  @Expose()
  account!: boolean;

  @Expose()
  verified!: boolean;

  @Expose()
  status!: BankAccountStatusEnum;
}
