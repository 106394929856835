import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { IProps } from '../../types';
import ModalContext, { IModalContext } from './context/modal.context';
import { TrySync, Utils } from '../../utils';

export enum ModalActionsEnum {
  CLOSE = 'CLOSE',
  SAVE = 'SAVE'
}

export interface IModal extends IProps {
  id?: string;
  title?: string;
  closeNavLink?: string;
  mode?: string;
  modalTitleClasses?: string;
  modalBodyClasses?: string;
}

export const ModalInstance = (modalId: string) => TrySync(
  () => bootstrap.Modal.getOrCreateInstance(document.getElementById(modalId)),
);

const Modal = ({
  children,
  title,
  closeNavLink,
  mode,
  modalTitleClasses,
  modalBodyClasses,
  id,
}: IModal) => {
  const navigate = useNavigate();
  const {
    setAction,
    showSpinner,
    showModal,
    hasBackdrop,
  } = useContext(
    ModalContext) as IModalContext;
  const [showModalClass, setShowModalClass] = useState('');
  const modalRef = useRef(null);

  useEffect(() => {
    if (!modalRef) {
      return;
    }
    const $modal = (modalRef.current as any);
    $modal.addEventListener('shown.bs.modal', () => {
      // Do something
    });
    $modal.addEventListener('hidden.bs.modal', () => {
      // Do something
    });
  }, []);

  useEffect(() => {
    if (showModal) {
      const $modalBootstrap = bootstrap.Modal.getOrCreateInstance(
        modalRef.current);
      $modalBootstrap.show();
      return;
    }
    setShowModalClass('');
  }, [showModal]);

  const save = async () => {
    setAction && setAction(`${ModalActionsEnum.SAVE}@${Utils.random()}`);
  };

  const onClose = () => {
    if (closeNavLink) {
      return navigate(closeNavLink);
    }
    setAction && setAction(`${ModalActionsEnum.CLOSE}@${Utils.random()}`);
  };

  return (
    <>
      <div ref={modalRef}
           key={id}
           id={id}
           data-key={id}
           className={`modal ${showModalClass}`}
           data-bs-backdrop="static"
           data-bs-keyboard="false">
        <div
          className={`modal-dialog modal-dialog-centered modal-dialog-scrollable ${mode ??
          ''}`}>
          <div className="modal-content">
            <div className="modal-header"><p
              className={`modal-title ${modalTitleClasses ??
              'fs-5'}`}>{title}</p>
              <NavLink className="btn-close"
                       data-bs-dismiss="modal"
                       onClick={onClose}
                       to={closeNavLink ?? ''}></NavLink>
            </div>
            <div
              className={`modal-body ${modalBodyClasses ?? ''}`}>
              {children}
            </div>
            <div className="modal-footer">
              <div className="me-auto">
                <div className={`text-secondary me-auto ${showSpinner
                  ? ''
                  : 'd-none'}`}>
                  <span className={'spinner-border spinner-border-sm me-1'}
                        role={'status'}></span>
                  Loading...
                </div>
              </div>
              <NavLink className="btn btn-secondary"
                       data-bs-dismiss="modal"
                       onClick={onClose}
                       to={closeNavLink ?? ''}
              >Cancel
              </NavLink>
              <button type="button"
                      className="btn btn-primary"
                      onClick={save}>Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {(showModal)
        && (typeof hasBackdrop === 'undefined' || hasBackdrop) &&
          <div className={'modal-backdrop fade show'}></div>}
    </>
  );
};

export { Modal };
