import { merge } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext, { IAppContext } from '../../../context/app.context';
import ModalContext, { IModalContext } from '../../modal/context/modal.context';
import { ProfileEditActions } from '../profile-edit.actions';

const ProfileEditNameForm = () => {
  const {currentUser, setCurrentUser} = useContext(AppContext) as IAppContext;
  const {action, setShowSpinner} = useContext(
    ModalContext) as IModalContext;
  const $formRef = useRef(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    setFirstName(currentUser?.firstName ?? '');
    setLastName(currentUser?.lastName ?? '');
  }, [currentUser]);

  useEffect(() => {
    if (!action) {
      return;
    }
    const $form = $formRef.current as unknown as HTMLFormElement;
    submit($form);
  }, [action]);

  const submit = async ($form: HTMLFormElement) => {
    const url = `${$form.getAttribute('action')}/${currentUser?.id}`;
    try {
      setShowSpinner && setShowSpinner(true);
      const user = await ProfileEditActions.fullName({
        url,
        firstName,
        lastName,
      });
      setCurrentUser && setCurrentUser(merge(currentUser, user));
    } finally {
      setShowSpinner && setShowSpinner(false);
    }
  };

  return (
    <form ref={$formRef}
          action="/users"
          className="needs-validation"
          noValidate={true}
          autoComplete="off">
      <div className="form-floating mb-3">
        <input id="profile.first.name"
               value={firstName}
               onChange={(el) => setFirstName(el.currentTarget.value)}
               type="text"
               className="form-control form-control-lg"
               placeholder="First Name"
               autoComplete="nope"
               required={true}/>
        <label
          className="form-label"
          htmlFor="profile.first.name">First Name</label>
      </div>
      <div className="form-floating mb-3">
        <input id="profile.last.name"
               value={lastName}
               onChange={(el) => setLastName(el.currentTarget.value)}
               type="text"
               className="form-control form-control-lg"
               placeholder="Last Name"
               autoComplete="nope"/>
        <label
          className="form-label"
          htmlFor="profile.last.name">Last Name</label>
      </div>
    </form>
  );
};

export { ProfileEditNameForm };
