import React, { useCallback, useContext, useState } from 'react';
import { IProps } from '../../types';
import { VerifyUserActions } from './verify-user.actions';
import ThankYouMsg from '../tankyou/thank-you-msg';
import AppContext, { IAppContext } from '../../context/app.context';
import { Navigate } from 'react-router-dom';

export interface IVerifyUser extends IProps {
}

const VerifyUser = ({className, children}: IVerifyUser) => {
  const {currentUser} = useContext(AppContext) as IAppContext;
  const [wasSuccess, setWasSuccess] = useState(false);
  
  const onCLick = useCallback(() => {
    VerifyUserActions.verify().then(() => {
      setWasSuccess(true);
    }).catch(errors => console.error(errors));
  }, []);

  if (!currentUser) {
    return (<Navigate to={'/login'}/>);
  }
  
  if (currentUser?.isVerified) {
    return (<Navigate to={'/'}/>);
  }
  
  if (wasSuccess) {
    return (<ThankYouMsg
      message={'We\'ve sent an email to confirm your email address'}
      className={'d-flex text-white flex-column text-center justify-content-center align-items-center h-100'}>
      </ThankYouMsg>
      );
  }
  return (
    <>
      <div
        className={className
          ? className
          : 'd-flex flex-column text-white text-center justify-content-center align-items-center h-100'}>
        <i className="bi text-white bi-person-fill-lock fs-5rem"></i>
        <h2 className="mt-5 mb-5 ps-5 pe-5">You must verify your account</h2>
        <button className={'btn btn-lg btn-light'}
                type={'button'}
                onClick={onCLick}>Click for validating your account
        </button>
      </div>
    </>
  );
};

export default VerifyUser;
