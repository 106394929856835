import React, { useCallback, useContext, useState } from 'react';
import { MainLayout } from '../../../layouts/main.layout';
import { MainHeader } from '../../../components/main/main-header';
import { ShopMainHeader } from '../../../components/store/shop-main-header';
import MainBody from '../../../components/main/main-body';
import AppContext, { IAppContext } from '../../../context/app.context';
import { StoreStoreDto } from '../../../components/store/stores/dto';
import { ListingContainer }
  from '../../../components/listing-container/listing-container';
import {
  ShippingMethodDto,
} from '../../../hooks/shipping-methods/dto/shipping-method.dto';
import CreateListingContainerContext
  , {
  IListingContainerContext,
} from '../../../components/listing-container/listing-container.context';
import
{
  ShippingMethodItem,
} from '../../../components/store/shipping-methods/shipping-method-item';
import { useListStoreShippingMethods }
  from '../../../hooks/shipping-methods/use-list-store-shipping-methods.hook';
import { useListShippingMethods }
  from '../../../hooks/shipping-methods/use-list-shipping-methods.hook';
import EmptyComponentForm
  from '../../../components/listing-container/empty-component-form';
import { NavLink } from 'react-router-dom';

export const ShopShippingMethodListingContainerContext = CreateListingContainerContext<ShippingMethodDto>();

const StoreShippingMethodPage = () => {
  const {
    currentShop,
  } = useContext(AppContext) as IAppContext;
  // Listing Container context
  const [action, setAction] = useState('');
  const [itemToEdit, setItemToEdit] = useState({} as StoreStoreDto);
  const [itemEdited, setItemEdited] = useState({} as StoreStoreDto);
  const contextModalForm: IListingContainerContext<ShippingMethodDto> = {
    action,
    setAction,
    itemToEdit,
    setItemToEdit,
    itemEdited,
    setItemEdited,
  };

  // Data
  const [fetching, setFetching] = useState(true);
  const listShippingMethods = useListShippingMethods();
  const listStoreShippingMethods = useListStoreShippingMethods();
  const data = useCallback(() => {
    if (!listShippingMethods
      || !listStoreShippingMethods
      || listStoreShippingMethods.length === 0) {
      return [] as ShippingMethodDto[];
    }
    setFetching(false);
    return listShippingMethods.map(shippingMethod => {
      const enabled = listStoreShippingMethods.find(
        storeShippingMethod => storeShippingMethod.code ===
          shippingMethod.code)?.enabled;
      return {...shippingMethod, enabled};
    });
  }, [listShippingMethods, listStoreShippingMethods]);

  return (
    <MainLayout>
      <MainHeader className={'shadow-sm bg-dark'}>
        <ShopMainHeader/>
      </MainHeader>
      <MainBody>
        <ShopShippingMethodListingContainerContext.Provider value={contextModalForm}>
          <ListingContainer resource={{
            data: {
              fn: data,
              deps: [listShippingMethods, listStoreShippingMethods],
              useStateFetching: [fetching, setFetching],
            },
          }}
                            breadcrumb={[
                              <NavLink className={'text-decoration-none'}
                                       to={'/store'}>Stores</NavLink>,
                              'Shipping Method',
                              'All']}
                            itemComponentClass={ShippingMethodItem}
                            formComponentClass={EmptyComponentForm}
                            ContextComponent={ShopShippingMethodListingContainerContext}
                            showSearchBar={false}
          />
        </ShopShippingMethodListingContainerContext.Provider>
      </MainBody>
    </MainLayout>
  );
};

export { StoreShippingMethodPage };
