import React, { useState } from 'react';
import { MainLayout } from '../../layouts/main.layout';
import { Modal } from '../../components/modal/modal';
import { ProfileEditPasswordForm }
  from '../../components/profile/password/profile-edit-password-form';
import ModalContext from '../../components/modal/context/modal.context';

const ProfileEditPasswordPage = () => {
  const [action, setAction] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [hasBackdrop, setHasBackdrop] = useState(false);
  const context = {
    action,
    setAction,
    showModal,
    setShowModal,
    showSpinner,
    setShowSpinner,
    hasBackdrop,
    setHasBackdrop,
  };
  return (
    <MainLayout>
      <ModalContext.Provider value={context}>
        <Modal mode={'modal-fullscreen'}
               title={'Change Password'}
               closeNavLink={'/profile'}
               modalBodyClasses={'d-flex justify-content-center align-items-center'}>
          <ProfileEditPasswordForm key={'profile-save-password'}/>
        </Modal>
      </ModalContext.Provider>
    </MainLayout>
  );
};

export { ProfileEditPasswordPage as default };
