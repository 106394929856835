import { RequestUtil } from '../../utils';
import { TaxCodeDto } from './dto/tax-code.dto';
import { IAppContext } from '../../context';

export class TaxCodesService {
  
  static async fetch(context: IAppContext) {
    return RequestUtil.get(
      `/stores/${context.currentShop?.uuid}/payouts/tax-codes`,
      {},
      () => TaxCodeDto).catch(errors => console.error('Error: useListCountryStates'));
  }
}