import { Expose, Type } from 'class-transformer';
import { StoreDto } from './store.dto';
import { AvatarDto } from './avatar.dto';

export class UserDto {
  @Expose()
  id!: number;
  
  @Expose()
  uuid!: string;

  @Expose()
  firstName!: string;

  @Expose()
  lastName!: string;

  @Expose()
  email!: string;

  @Expose()
  isVerified!: string;

  @Expose()
  @Type(() => AvatarDto)
  avatarMedia?: AvatarDto;
  
  @Expose()
  @Type(() => StoreDto)
  stores?: StoreDto[];
}