import { Expose, Transform } from 'class-transformer';
import { ShopProductMediaDto } from './shop-product-media.dto';

export class ProductDto {
  @Expose()
  id!: string;

  @Expose()
  uuid!: string;

  @Expose()
  name!: string;

  @Expose()
  slug?: string;

  @Expose()
  longDescription?: string;

  @Expose()
  shortDescription?: string;

  @Expose()
  enabled!: boolean;

  @Expose()
  stock?: number;

  @Expose()
  price?: number;

  @Expose()
  mediaStoreProducts?: ShopProductMediaDto[];

  @Expose()
  @Transform(({obj: data}: { obj: ProductDto }) => {
    const [media] = data.mediaStoreProducts?.filter(media => media.isMainImage) ?? [];
    return media;
  })
  mainMedia?: ShopProductMediaDto;

  @Expose()
  createdAt!: Date;

  @Expose()
  updatedAt!: Date;
}
