import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Pipes, Utils } from '../../../utils';
import { IProps } from '../../../types';
import { OrderDto } from '../../../hooks/orders/dto/order.dto';
import { OrderConstants } from './order.constants';
import {
  useStoreOrderPaymentStatus,
} from '../../../hooks/orders/use-store-order-payment-status.hook';
import {
  useStoreOrderShippingStatus,
} from '../../../hooks/orders/use-store-order-shipping-status.hook';
import {
  useStoreOrderStatus,
} from '../../../hooks/orders/use-store-order-status.hook';
import { YesModalSizeEnum, YesNoModal } from '../../modal/yesno-modal';
import { OrderLiveEditor } from './live-editor/order-live-editor';
import {
  useQuerySelectorFrom,
} from '../../modal/hooks/use-query-selector-from.hook';
import {
  BootstrapInstanceEnum,
} from '../../modal/hooks/bootstrap-instance.enum';

interface IOrderItem extends IProps {
  model?: OrderDto;
}

const OrderItem = ({model}: IOrderItem) => {
  const thisRef = useRef(null);
  const querySelectorFrom = useQuerySelectorFrom([]);
  const [orderToEdit, setOrderToEdit] = useState(
    Pipes.transform(OrderDto, model) as OrderDto);
  const paymentStatus = useStoreOrderPaymentStatus([orderToEdit]);
  const shippingStatus = useStoreOrderShippingStatus([orderToEdit]);
  const orderStatus = useStoreOrderStatus([orderToEdit]);
  const [isLoading, setIsLoading] = useState(false);

  const payment = useCallback(() => {
    const [status, bg] = paymentStatus(orderToEdit);
    return <div
      className="align-self-center text-secondary border-0 text-center mx-3"
    >
      <i className="bi bi-credit-card fs-2 position-relative">
                <span className={'position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle ' +
                  bg}>
                  <span className="visually-hidden">Payment {status}</span>
                </span>
      </i>
      <p className={'d-none d-lg-block mb-0'}>
        <span className="text-muted fs-07rem">{status}</span>
      </p>
    </div>;
  }, [orderToEdit]);

  const shipping = useCallback(() => {
    const [status, bg] = shippingStatus(orderToEdit);
    return <div
      className="align-self-center text-secondary border-0 text-center mx-3"
    >
      <i className="bi bi-truck fs-2 position-relative">
                <span className={'position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle ' +
                  bg}>
                  <span className="visually-hidden">Shipping {status}</span>
                </span>
      </i>
      <p className={'d-none d-lg-block mb-0'}>
        <span className="text-muted fs-07rem">{status}</span>
      </p>
    </div>;
  }, [orderToEdit]);

  const orderStatusComponent = useCallback(() => {
    const [status, bg] = orderStatus(orderToEdit);
    return <Link onClick={onShowLiveEditor}
                 to={''}
                 className={'btn stretched-link ' + bg}>
      <span className={'d-none d-sm-block'}>{status}</span>
    </Link>;
  }, [orderToEdit]);

  const orderActionRequiredComponent = useCallback(() => {
    const [statusLabel, bg, status] = orderStatus(orderToEdit);
    if (status === OrderConstants.ORDER_COMPLETED) {
      return <></>;
    }
    return <div className="alert alert-warning d-flex align-items-center align-self-center ms-3 mb-0 d-none d-sm-flex"
                role="alert">
      <i className="bi bi-exclamation-triangle-fill me-3"></i>
      <div>
        Action required
      </div>
    </div>;
  }, [orderToEdit]);

  if (!orderToEdit) {
    return <div key={Utils.random()}></div>;
  }

  const onShowLiveEditor = () => {
    const $modal = querySelectorFrom(thisRef, '.modal',
      BootstrapInstanceEnum.MODAL);
    $modal?.show();
  };

  return (
    <div
      ref={thisRef}
      key={orderToEdit.uuid}
      className="d-flex p-1 g-0 border rounded overflow-hidden mb-1 shadow-sm max-h-140--px position-relative">
      {orderStatusComponent()}
      <div className="flex-grow-1 overflow-auto">
        <div className="container-fluid row pe-0">
          <div
            className="col-12 col-md-6 col-lg-4 col-xl-3 ps-2 pe-2">
            <div className="h-24--px position-relative">
              <Link
                data-key={'code'}
                className="text-decoration-none text-primary"
                to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{orderToEdit.uuid}</p>
              </Link>
            </div>
            <div className="position-relative">
              <Link
                data-key={'name'}
                className="text-decoration-none text-reset fs-5"
                to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{orderToEdit.cartSnapshot?.billingAddress?.email ||
                  ''}</p>
              </Link>
            </div>
            <div
              className="h-24--px position-relative card-text mb-auto text-truncate">
              <Link className="text-decoration-none text-muted"
                    to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{orderToEdit.createdAt?.toLocaleString() ||
                  ''}</p>
              </Link>
            </div>
            <div
              className="d-flex justify-content-end h-24--px position-relative card-text mb-auto text-truncate">
              <Link className="text-decoration-none text-muted fw-bold fs-07rem"
                    to={''}>
                <p className="pe-4 m-0 w-100">{
                  Utils.formatNumber(
                    orderToEdit.cartSnapshot?.summary?.total || 0,
                  )
                }</p>
              </Link>
            </div>
          </div>
          <div className={'d-flex justify-content-start col-md-6 col-lg-8 col-xl-9 ps-2 pe-2'}>
            {payment()}
            {shipping()}
            {orderActionRequiredComponent()}
          </div>
        </div>
      </div>
      <YesNoModal id={orderToEdit.uuid}
                  size={YesModalSizeEnum.LG}
                  header={(() => {
                    const [statusLabel] = orderStatus(orderToEdit);
                    return <p className={`modal-title fs-5`}>{statusLabel ??
                      ''}</p>;
                  })()}
                  body={<OrderLiveEditor order={[
                    orderToEdit,
                    setOrderToEdit]}
                                         isLoading={[isLoading, setIsLoading]}
                  ></OrderLiveEditor>}
                  buttons={{
                    yes: {
                      label: 'Save',
                      isLoading: [isLoading, setIsLoading],
                    },
                  }}
      ></YesNoModal>
    </div>
  );
};

export { OrderItem };
