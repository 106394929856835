import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { PubSubEnum } from '../../../../enums/pub-sub.enum';
import PubSub from 'pubsub-js';
import AppContext from '../../../../context/app.context';
import {
  BootstrapInstanceEnum,
} from '../../../modal/hooks/bootstrap-instance.enum';
import {
  useQuerySelectorFrom,
} from '../../../modal/hooks/use-query-selector-from.hook';
import { ShopProductVariantsForm } from './form/shop-product-variants-form';
import {
  IShopProductVariantFormUIModel, useCreateModel as useCreateModelForm,
} from './form/shop-product-variant-form.uimodel';
import {
  IShopProductVariantItemsUIModel, useCreateModel as useCreateModelItems,
} from '../variants-items/shop-product-variant-items.uimodel';
import {
  IShopProductVariantUIModel, useCreateModel,
} from './shop-product-variant.uimodel';
import { IAlertModal } from '../../../modal/alert.modal';
import {
  ShopProductVariantItemForm,
} from '../variants-items/form/shop-product-variant-item-form';

const ShopProductVariant = () => {
  const {currentShop} = useContext(AppContext);
  const thisRef = useRef(null);
  const querySelectorFrom = useQuerySelectorFrom([]);
  const uiModel: IShopProductVariantUIModel = useCreateModel();
  const uiModelForm: IShopProductVariantFormUIModel = useCreateModelForm();
  const uiModelItems: IShopProductVariantItemsUIModel = useCreateModelItems();

  useEffect(() => {
    setTimeout(() => {
      // Subscriber
      PubSub.unsubscribe(PubSubEnum.LISTING_CONTAINER_SEARCH_BAR_VARIANTS_SHOW);
      PubSub.subscribe(PubSubEnum.LISTING_CONTAINER_SEARCH_BAR_VARIANTS_SHOW,
        () => {
          const $modal = querySelectorFrom(thisRef,
            `__self`,
            BootstrapInstanceEnum.MODAL);
          $modal?.show();
        });
    }, 0);

  }, [currentShop]);

  const onCreateOrEditForm = useCallback(() => {
    uiModelForm.empty();
    uiModelForm.setCreatingOrEditing(true);
  }, [currentShop]);

  useEffect(() => {
    if (uiModelForm.creatingOrEditing) {
      return;
    }
    uiModel.fetch();
  }, [currentShop, uiModelForm.creatingOrEditing]);

  return <div ref={thisRef}
              className="modal"
              data-bs-backdrop="static"
              data-bs-keyboard="false">
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content">
        <div className="modal-header">
          <span className={'text-muted me-2'}>{currentShop?.name}:</span>
          <h5 className="modal-title">Variants</h5>
          <button type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <div className={'d-flex justify-content-center'}>
            {!uiModelForm.creatingOrEditing &&
              !uiModelItems.creatingOrEditing && (
                <div className={'col-12 col-md-10 col-lg-6 text-center position-relative'}>
                  <button type={'button'}
                          className={'btn btn-primary end-0 position-absolute'}
                          onClick={onCreateOrEditForm}
                  >
                    <i className={'bi bi-plus-lg fw-bold me-2'}></i>Add
                    Variant
                  </button>
                  <table className="table table-striped table-hover table-responsive caption-top">
                    <caption>List of Variants</caption>
                    <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Values</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {uiModel.data?.map(variant => {
                      return <tr key={variant.uuid}
                                 className={'align-middle'}>
                        <td>{variant.name}</td>
                        <td>
                          {variant.items?.map(item =>
                            <span key={item.uuid}
                                  className="badge rounded-pill bg-primary mx-1">{item.shortName}</span>,
                          )}
                        </td>
                        <td>
                          <p className="pe-4 m-0 w-100 text-truncate text-muted">{
                            variant.enabled
                              ? <>
                                <i className={'bi bi-check-circle-fill text-success me-1'}></i>Enabled
                              </>
                              : <>
                                <i className={'bi bi-dash-circle-fill text-danger me-1'}></i>Disabled
                              </>
                          }</p>
                        </td>
                        <td>
                          <div className="btn-group"
                               role="group"
                               aria-label="Basic example">
                            <button type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => {
                                      uiModelForm.fill(variant);
                                      uiModelForm.setCreatingOrEditing(true);
                                    }}
                            >
                              <i className={'bi bi-pencil'}></i>
                            </button>
                            <button type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => {
                                      uiModelItems.fill(variant);
                                      uiModelItems.setCreatingOrEditing(
                                        true);
                                    }}
                            >
                              <i className={'bi bi-list-check'}></i>
                            </button>
                            <button type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => {
                                      uiModelForm.fill(variant);
                                      PubSub.publish(
                                        PubSubEnum.ALERT_CONFIRM_MODAL_MESSAGE,
                                        {
                                          message: <div>
                                            <p className={'fs-4'}>Do You want to
                                              delete <span className={'fw-bold'}>{variant.name}</span> variant?
                                            </p>
                                            <p>Click on Yes button to
                                              delete.</p>
                                          </div>,
                                          okHandler: () => {
                                            return uiModelForm.remove(variant).
                                              then(() => {
                                                uiModel.fetch();
                                              });
                                          },
                                        } as IAlertModal);
                                    }}
                            >
                              <i className={'bi bi-x'}></i>
                            </button>
                          </div>
                        </td>
                      </tr>;
                    })}
                    </tbody>
                  </table>
                </div>
              )}

            {uiModelForm.creatingOrEditing && (
              <div className={'col-12 col-md-10 col-lg-6 text-center'}>
                <ShopProductVariantsForm uiModel={uiModelForm}/>
              </div>
            )}
            {uiModelItems.creatingOrEditing && (
              <div className={'col-12 col-md-10 col-lg-6 text-center'}>
                <ShopProductVariantItemForm uiModel={uiModelItems}/>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export { ShopProductVariant };