import React, { SetStateAction, useState } from 'react';
import { ShopProductVariantDto } from '../variants/shop-product-variant.dto';

export interface IShopProductVariantItemsUIModel {
  variant: ShopProductVariantDto;
  setVariant: React.Dispatch<SetStateAction<ShopProductVariantDto>>;
  creatingOrEditing: boolean,
  setCreatingOrEditing: React.Dispatch<SetStateAction<boolean>>;
  empty: Function;
  fill: Function;
}

const useCreateModel = () => {
  // Control
  const [creatingOrEditing, setCreatingOrEditing] = useState(false);

  // Model
  const [variant, setVariant] = useState({} as ShopProductVariantDto);

  const empty = () => {
    setVariant({} as ShopProductVariantDto);
  };

  const fill = (dto: ShopProductVariantDto) => {
    setVariant(dto);
  };

  return {
    variant,
    setVariant,
    creatingOrEditing,
    setCreatingOrEditing,
    empty,
    fill,
  } as IShopProductVariantItemsUIModel;
};

export { useCreateModel };