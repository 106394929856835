import React, { useContext, useState } from 'react';
import { MainLayout } from '../../../layouts/main.layout';
import { MainHeader } from '../../../components/main/main-header';
import { ShopMainHeader } from '../../../components/store/shop-main-header';
import MainBody from '../../../components/main/main-body';
import { ListingContainer }
  from '../../../components/listing-container/listing-container';
import ShopUserForm
  from '../../../components/store/users/shop-user-form';
import { ShopUserDto } from '../../../components/store/users/dto';
import CreateListingContainerContext
  , {
  IListingContainerContext,
} from '../../../components/listing-container/listing-container.context';
import ShopUserItem from '../../../components/store/users/shop-user-item';
import AppContext, { IAppContext } from '../../../context/app.context';
import { StoreStoreDto } from '../../../components/store/stores/dto';

export const ShopUserListingContainerContext = CreateListingContainerContext<ShopUserDto>();

const ShopUsersPage = () => {
  const {
    currentShop,
  } = useContext(AppContext) as IAppContext;

  // Listing Container context
  const [action, setAction] = useState('');
  const [itemToEdit, setItemToEdit] = useState({} as ShopUserDto);
  const [itemEdited, setItemEdited] = useState({} as ShopUserDto);
  const contextModalForm: IListingContainerContext<ShopUserDto> = {
    action,
    setAction,
    itemToEdit,
    setItemToEdit,
    itemEdited,
    setItemEdited,
  };

  return (
    <MainLayout>
      <MainHeader className={'shadow-sm bg-dark'}>
        <ShopMainHeader/>
      </MainHeader>
      <MainBody>
        <ShopUserListingContainerContext.Provider value={contextModalForm}>
          <ListingContainer resource={{
            url: `/stores/${currentShop?.uuid}/users`,
            type: () => StoreStoreDto,
          }}
                            breadcrumb={['Users', 'All Users']}
                            itemComponentClass={ShopUserItem}
                            formComponentClass={ShopUserForm}
                            ContextComponent={ShopUserListingContainerContext}/>
        </ShopUserListingContainerContext.Provider>
      </MainBody>
    </MainLayout>
  );
};

export { ShopUsersPage };
