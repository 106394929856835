import React, {
  Dispatch, SetStateAction, useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  IModal,
} from '../../../modal/modal';
import { ProductDto } from '../dto/shop-product.dto';
import { ShopProductActions } from '../shop-product.actions';
import { YesModalSizeEnum, YesNoModal } from '../../../modal/yesno-modal';
import {
  useParentUntilFrom,
} from '../../../modal/hooks/use-parent-until-from.hook';
import {
  BootstrapInstanceEnum,
} from '../../../modal/hooks/bootstrap-instance.enum';
import PupSub from 'pubsub-js';
import { ShopProductLiveEditorFromEnum } from '../shop-product-item';

export interface IShopProductBaseDataLiveEditor extends IModal {
  product: [ProductDto, Dispatch<SetStateAction<ProductDto>>];
}

const ShopProductBaseDataLiveEditor = ({
  product,
}: IShopProductBaseDataLiveEditor) => {
  const thisRef = useRef(null);
  const parentUntilFrom = useParentUntilFrom([]);
  const [modalId, setModalId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [productToEdit, setProductToEdit] = product;

  // states of each field
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');

  useEffect(() => {
    setModalId(
      ShopProductLiveEditorFromEnum.MODAL_BASE_DATA_LIVE_EDITOR_FORM_INSTANCE +
      productToEdit.uuid);
    setName && setName(productToEdit?.name ?? '');
    setSlug && setSlug(productToEdit?.slug ?? '');
    setLongDescription &&
    setLongDescription(productToEdit?.longDescription ?? '');
    setShortDescription &&
    setShortDescription(productToEdit?.shortDescription ?? '');
  }, [productToEdit]);

  const onSave = useCallback(() => {
    const productTransitory = {
      ...productToEdit,
      name,
      shortDescription,
      longDescription,
    } as ProductDto;
    setIsLoading(true);
    ShopProductActions.save(productTransitory).then(() => {
      setProductToEdit && setProductToEdit(productTransitory);

      // Handler Modal Event
      const $modal = parentUntilFrom(thisRef, 'modal',
        BootstrapInstanceEnum.MODAL);
      $modal.hide();
    }).finally(() => {
      setIsLoading(false);
    });
  }, [
    productToEdit,
    name,
    slug,
    longDescription,
    shortDescription,
  ]);

  useEffect(() => {
    // Subscriber
    PubSub.unsubscribe(modalId + '-save');
    PupSub.subscribe(modalId + '-save', onSave);
  }, [
    productToEdit,
    name,
    slug,
    longDescription,
    shortDescription,
  ]);

  return (
    <YesNoModal id={modalId}
                size={YesModalSizeEnum.LG}
                className={modalId}
                header={(() => {
                  return <p className={`modal-title fs-5`}>Edit Product</p>;
                })()}
                body={
                  <div ref={thisRef}>
                    <p className="card-title text-muted fs-07rem">{`product:${productToEdit?.uuid}`}</p>
                    <form className="needs-validation"
                          noValidate={true}
                          autoComplete="off">
                      <div className="form-floating mb-3">
                        <input id="product.name"
                               value={name}
                               onChange={(el) => setName(
                                 el.currentTarget.value)}
                               type="text"
                               className="form-control form-control-lg"
                               placeholder="Name"
                               autoComplete="nope"
                               required={true}/>
                        <label htmlFor={'product.name'}
                               className="form-label">Name
                        </label>
                      </div>
                      <div className="form-floating mb-3">
            <textarea id="product.long-description"
                      value={longDescription}
                      onChange={(el) => setLongDescription(
                        el.currentTarget.value)}
                      className="form-control form-control-lg"
                      placeholder="Long Description"
                      autoComplete="nope"/>
                        <label htmlFor={'product.long-description'}>Long
                          Description
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <input id="product.short-description"
                               value={shortDescription}
                               onChange={(el) => setShortDescription(
                                 el.currentTarget.value)}
                               type="text"
                               className="form-control form-control-lg"
                               placeholder="Short Description"
                               autoComplete="nope"/>
                        <label htmlFor={'product.short-description'}
                               className="form-label">Short Description
                        </label>
                      </div>
                    </form>
                  </div>
                }
                buttons={{
                  yes: {
                    label: 'Save',
                    isLoading: [isLoading, setIsLoading],
                  },
                }}
    ></YesNoModal>
  );
};

export { ShopProductBaseDataLiveEditor };
