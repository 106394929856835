import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { LoginActions } from '../auth/login/login.actions';
import { IProps } from '../../types';
import AppContext, { IAppContext } from '../../context/app.context';

interface IMainNavbar extends IProps {
  avatarImage?: string;
}

const MainNavbar = ({className, avatarImage}: IMainNavbar) => {
  const {setCurrentUser} = useContext(AppContext) as IAppContext;

  const onLogout = () => {
    setCurrentUser && setCurrentUser({} as IAppContext);
    LoginActions.logout();
  };

  return (
    <>
      <nav
        className={className
          ? className
          : 'navbar navbar-expand bg-light ps-3 pe-3 pt-0 pb-0'}>
        <div className="d-flex w-100">
          <NavLink className="navbar-brand"
                   to={'/'}>
            <img alt={'logo'}
                 src="/img/snoween-logo-full.svg"
                 height="36"/>
          </NavLink>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link
                  to={''}
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i
                    className="bi bi-grid fs-4"></i>
                </Link>
                <ul className="dropdown-menu dropdown-menu-end z-index-1">
                  <li>
                    <NavLink className="dropdown-item"
                             to={'/profile'}>
                      <i className="bi bi-person-fill me-2"></i>
                      Profile
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider"/>
                  </li>
                  <li>
                    <NavLink className="dropdown-item"
                             to={'/'}>
                      <i className="bi bi-pie-chart me-2"></i>
                      Dashboard
                    </NavLink>
                  </li>
                  <li className={'d-none'}>
                    <NavLink className="dropdown-item"
                             to={'/settings'}>
                      <i className="bi bi-sliders2 me-2"></i>
                      Settings
                    </NavLink>
                  </li>
                  <li className={'d-none'}>
                    <NavLink className="dropdown-item"
                             to={'/notifications'}>
                      <i className="bi bi-bell-fill me-2"></i>
                      Notifications
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider"/>
                  </li>
                  <li><h6 className="dropdown-header">Modules</h6></li>
                  <li>
                    <NavLink className="dropdown-item"
                             to={'/store'}>
                      <i className="bi bi-shop me-2 fs-4"></i>
                      Store
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="collapse navbar-collapse justify-content-end pe-2">
            <ul className="navbar-nav">
              <li className="nav-item me-2 d-none">
                <NavLink className="nav-link p-0 m-0"
                         to={'/notifications'}>
                  <i className="bi bi-bell-fill fs-4"></i>
                </NavLink>
              </li>
              <li className="nav-item me-2 ms-2 d-none">
                <NavLink className="nav-link p-0 m-0"
                         to={'/settings'}>
                  <i className="bi bi-sliders2 fs-4"></i>
                </NavLink>
              </li>
              <li id="main.header.nav.profile"
                  className="nav-item dropdown ms-3">
                <div>
                  <Link id="nav.profile.avatar"
                        className="nav-link dropdown-toggle position-relative"
                        to={''}
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                      <span
                        className="position-absolute w-3-5--em h-3-5--em top-50 start-50 translate-middle badge border border-light rounded-circle bg-primary p-2">
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    <i
                      className="position-absolute top-50 start-50 translate-middle text-white bi bi-person-fill fs-4"></i>
                    <img
                      alt={'avatar'}
                      className="w-45--px h-45--px position-absolute top-50 start-50 translate-middle text-white bi bi-person-fill rounded-circle fs-4"
                      src={avatarImage}/>
                    <span
                      className="position-absolute top-100 start-100 translate-middle p-x-0-4--rem badge border border-light rounded-circle bg-success"><span
                      className="visually-hidden">unread messages</span></span>
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-end z-index-1">
                    <li>
                      <Link className="dropdown-item"
                            to={'/login'}
                            onClick={onLogout}>
                        <i className="bi bi-box-arrow-right me-2"></i>
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export { MainNavbar as default };
