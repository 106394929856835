import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IProps } from '../../../types';
import AppContext from '../../../context/app.context';
import { RequestUtil } from '../../../utils';
import { BankAccountCreateDto } from './dto/bank-account-create.dto';
import { Spinner } from '../../spinner/spinner';
import { BankAccountStatusDto } from './dto/bank-account-status.dto';
import { BankAccountStatusEnum } from './bank-account-status.enum';
import { Link } from 'react-router-dom';
import { PubSubEnum } from '../../../enums/pub-sub.enum';
import { IAlertModal } from '../../modal/alert.modal';

interface IBankAccountFormContainer extends IProps {
  breadcrumb?: ReactProps[];
}

const BankAccountForm = ({
  breadcrumb,
}: IBankAccountFormContainer) => {
  const {currentShop} = useContext(AppContext);
  const formRef = useRef(null);
  const [actionRequest, setActionRequest] = useState('Post');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountStatus, setAccountStatus] = useState(
    BankAccountStatusEnum.NO_SUBMITTED);
  const [disabledFields, setDisabledFields] = useState(false);
  const [showFetching, setShowFetching] = useState(true);
  const [showSubmitLoading, setShowSubmitLoading] = useState(false);
  const [showSubmittedAlert, setShowSubmittedAlert] = useState(false);

  useEffect(() => {
    if (!currentShop?.uuid) {
      return;
    }
    setActionRequest('Post');
    setShowFetching(true);
    RequestUtil.get(`/stores/${currentShop?.uuid}/payouts/information/status`,
      {}, () => BankAccountStatusDto).
      then(response => {
        setDisabledFields(response.verified || response.account);
        setAccountStatus(response.status);
      }).
      finally(() => {
        setShowFetching(false);
      });
  }, [currentShop]);

  useEffect(() => {
    if (accountStatus === BankAccountStatusEnum.VERIFIED
      || accountStatus === BankAccountStatusEnum.SUBMITTED
      || accountStatus === BankAccountStatusEnum.PENDING
      || accountStatus === BankAccountStatusEnum.NEEDS_INFORMATION
      || accountStatus === BankAccountStatusEnum.REJECTED
    ) {
      setShowSubmittedAlert(true);
      return;
    }
    setShowSubmittedAlert(false);
  }, [accountStatus]);

  const alert = useCallback(() => {
    if (!showSubmittedAlert) {
      return <></>;
    }
    const status = {
      [BankAccountStatusEnum.VERIFIED]: 'fw-bold text-success',
      [BankAccountStatusEnum.SUBMITTED]: 'fw-bold text-success',
      [BankAccountStatusEnum.PENDING]: 'fw-bold text-warning',
      [BankAccountStatusEnum.NEEDS_INFORMATION]: 'fw-bold text-danger',
      [BankAccountStatusEnum.NO_SUBMITTED]: 'fw-bold text-danger',
      [BankAccountStatusEnum.REJECTED]: 'fw-bold text-danger',
    };

    switch (accountStatus) {
      case BankAccountStatusEnum.VERIFIED:
        return <div className="alert alert-success"
                    role="alert">
          <h4 className="alert-heading">Well done!</h4>
          <p>You've provided the Bank account Information.</p>
          <p>The current status is: <span className={status[accountStatus] ??
            ''}>{accountStatus}</span></p>
          <p className="mb-0">If you need change this information,
            please click on link below.</p>
          <hr/>
          <p className="my-2">
            <Link className=""
                  onClick={showFormUpdateBankAccount}
                  to={''}>
              <Spinner show={showSubmitLoading}/>
              {!showSubmitLoading
                ? <span className={'mx-2'}>Edit Information</span>
                : ''}
            </Link>
          </p>
          <p className="mb-0">We are sending an email with
            instructions.</p>
        </div>;
      case BankAccountStatusEnum.SUBMITTED:
        return <div className="alert alert-success"
                    role="alert">
          <h4 className="alert-heading">Well done!</h4>
          <p>You've provided the Bank account Information.</p>
          <p>The current status is: <span className={status[accountStatus] ??
            ''}>{accountStatus}</span></p>
          <p className="mb-0">We need to verify the information. Please check
            the Store email for instructions.</p>
          <hr/>
          <p className="mb-0">If You haven't received the email, then click on
            "Resend email" link below.</p>
          <p className="my-2">
            <Link className=""
                  onClick={verifyInformationRequest}
                  to={''}
            >
              <Spinner show={showSubmitLoading}/>
              {!showSubmitLoading
                ? <span className={'mx-2'}>Resend email</span>
                : ''}
            </Link>
          </p>
          <p className="mb-0">We are sending an email with
            instructions.</p>
        </div>;
      case BankAccountStatusEnum.PENDING:
        return <div className="alert alert-success"
                    role="alert">
          <h4 className="alert-heading">Well done!</h4>
          <p>You've provided the Bank account Information.</p>
          <p>The current status is: <span className={status[accountStatus] ??
            ''}>{accountStatus}</span></p>
          <hr/>
          <p className="mb-0">This state take a short time.</p>
        </div>;
      case BankAccountStatusEnum.NEEDS_INFORMATION:
        return <div className="alert alert-warning"
                    role="alert">
          <h4 className="alert-heading">Well done!</h4>
          <p>You've provided the Bank account Information.</p>
          <p>The current status is: <span className={status[accountStatus] ??
            ''}>{accountStatus}</span></p>
          <p className="mb-0">But, We need to verify some information.</p>
          <hr/>
          <p className="mb-0">Please, click on the link below for receiving
            instructions.</p>
          <p className="my-2">
            <Link className=""
                  onClick={verifyInformationRequest}
                  to={''}>
              <Spinner show={showSubmitLoading}/>
              {!showSubmitLoading
                ? <span className={'mx-2'}>Verify Information</span>
                : ''}
            </Link>
          </p>
          <p className="mb-0">We are sending an email with
            instructions.</p>
        </div>;
      case BankAccountStatusEnum.REJECTED:
        return <div className="alert alert-danger"
                    role="alert">
          <h4 className="alert-heading">There is a problem!</h4>
          <p>The Bank Account has been rejected.</p>
          <p>The current status is: <span className={status[accountStatus] ??
            ''}>{accountStatus}</span></p>
          <p className="mb-0">You must provide new information. Please click on
            the link below to add new information.</p>
          <hr/>
          <p className="my-2">
            <Link className=""
                  onClick={showFormUpdateBankAccount}
                  to={''}>
              <Spinner show={showSubmitLoading}/>
              {!showSubmitLoading
                ? <span className={'mx-2'}>Add new Bank Account</span>
                : ''}
            </Link>
          </p>
        </div>;
      default:
        return <></>;
    }
  }, [currentShop, accountStatus, showSubmittedAlert]);

  const submit = async (evt: any) => {
    evt.preventDefault();
    const payload: BankAccountCreateDto = {
      account_number: accountNumber,
      routing_number: routingNumber,
    };
    setShowSubmitLoading(true);
    if (actionRequest === 'Post') {
      RequestUtil.post(`/stores/${currentShop?.uuid}/payouts/information`,
        payload, {}, () => BankAccountStatusDto).
        then(response => {
          setDisabledFields(response.verified || response.account);
          setAccountStatus(response.status);
        }).
        finally(() => {
          setShowSubmitLoading(false);
        });
    }
    if (actionRequest === 'Put') {
      RequestUtil.put(`/stores/${currentShop?.uuid}/payouts/information`,
        payload, {}, () => BankAccountStatusDto).
        then(response => {
          setDisabledFields(response.verified || response.account);
          setAccountStatus(response.status);
        }).
        finally(() => {
          setShowSubmitLoading(false);
        });
    }

  };

  const verifyInformationRequest = async (evt: any) => {
    evt.preventDefault();
    setShowSubmitLoading(true);
    RequestUtil.post(
      `/stores/${currentShop?.uuid}/payouts/information/verify-request`,
      {}, {notify: false}, () => BankAccountStatusDto).
      then(response => {
        PubSub.publish(PubSubEnum.ALERT_MODAL_MESSAGE, {
          message: <div>
            <p>We've sent an email with instructions, please check your
              inbox!</p>
          </div>,
        } as IAlertModal);
      }).
      catch(error => {
        PubSub.publish(PubSubEnum.ALERT_MODAL_MESSAGE, {
          message: <div>
            <p>There was an error</p>
            <p>If the error persists, please contact us support@snoween.net</p>
          </div>,
        } as IAlertModal);
      }).
      finally(() => {
        setShowSubmitLoading(false);
      });
  };

  const showFormUpdateBankAccount = async (evt: any) => {
    evt.preventDefault();
    setActionRequest('Put');
    setDisabledFields(false);
    setAccountStatus(BankAccountStatusEnum.NO_SUBMITTED);
  };

  return (
    <>
      <div className="d-flex w-100 sticky-top bg-white border-bottom">
        <div className="flex-grow-1 d-none d-sm-inline-flex">
          <nav className="align-self-center mt-1 mb-1 me-auto ps-3">
            <ol className="breadcrumb m-0">
              {breadcrumb?.map((str, index) => (
                <li key={index}
                    className={`breadcrumb-item ${index ===
                    breadcrumb?.length - 1 ? 'active' : ''}`}>{str}</li>
              ))}
            </ol>
          </nav>
        </div>
      </div>
      {showFetching &&
          <p className={'mt-3 text-center'}>
              <span className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"></span>
            Fetching...
          </p>}
      {!showFetching &&
          <div className={'d-flex justify-content-center align-items-center'}>
            {showSubmittedAlert &&
                <div className={'col-12 col-md-6 col-lg-3 px-3 px-md-0 mt-5'}>
                  {alert()}
                </div>
            }
            {!disabledFields &&
                <form ref={formRef}
                      className="needs-validation col-12 col-md-6 col-lg-3 px-3 px-md-0"
                      noValidate={true}
                      autoComplete="off"
                      onSubmit={submit}>
                  <fieldset disabled={disabledFields}>
                    <legend className={'my-5'}>Bank Account Information</legend>
                    <div className="form-floating mb-3">
                      <input id="store-bank-account-number"
                             value={accountNumber}
                             onChange={(el) => setAccountNumber(
                               el.currentTarget.value)}
                             type="text"
                             className="form-control form-control-lg"
                             placeholder="Account Number"
                             autoComplete="nope"
                             required={true}/>
                      <label
                          className="form-label"
                          htmlFor="store-bank-account-number">Account
                        Number</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input id="store-bank-routing-number"
                             value={routingNumber}
                             onChange={(el) => setRoutingNumber(
                               el.currentTarget.value)}
                             type="text"
                             className="form-control form-control-lg"
                             placeholder="Routing Number"
                             autoComplete="nope"
                             required={true}/>
                      <label
                          className="form-label"
                          htmlFor="store-bank-routing-number">Routing
                        Number</label>
                    </div>
                    <div className="d-grid mb-3">
                      <button className="btn btn-primary btn-lg">
                        <Spinner show={showSubmitLoading}/>
                        {!showSubmitLoading ? <span className={'mx-2'}>Save</span> : ''}
                      </button>
                    </div>
                  </fieldset>
                </form>
            }
          </div>
      }
    </>
  )
    ;
};

export {
  BankAccountForm,
};
