import React from 'react';
import LoginLayout from '../../layouts/login.layout';
import ThankYouMsg from '../../components/tankyou/thank-you-msg';
import { NavLink } from 'react-router-dom';

const VerifiedUserPage = () => {
  return (
    <LoginLayout>
      <ThankYouMsg message={"We've confirmed your email address!"}>
        <NavLink to={'/login'} className={'text-white mt-4'}>Go to Log In</NavLink>
      </ThankYouMsg>
    </LoginLayout>
  );
};

export { VerifiedUserPage as default };
