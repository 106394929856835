import { CacheUtil, Pipes, RequestUtil } from '../../../../utils';
import { ShopProductVariantItemDto } from './shop-product-variant-item.dto';

class ShopProductVariantItemService {

  static async save(data: ShopProductVariantItemDto) {
    const storeId = CacheUtil.cache('x-store');
    const {uuid, variant} = data;
    delete data.uuid;
    delete data.variant;
    const url = `/stores/${storeId}/variants/${variant?.uuid}/items`;
    if (uuid) {
      return RequestUtil.patch(
        `${url}/${uuid}`,
        Pipes.transform(ShopProductVariantItemDto, data),
        {},
        () => ShopProductVariantItemDto,
      );
    }
    return RequestUtil.post(
      url,
      Pipes.transform(ShopProductVariantItemDto, data),
      {},
      () => ShopProductVariantItemDto,
    );
  }

  static async fetch() {
    const storeId = CacheUtil.cache('x-store');
    const url = `/stores/${storeId}/variants`;
    return RequestUtil.get(
      url,
      {},
      () => ShopProductVariantItemDto,
    );
  }

  static async delete(uuid: string) {
    const storeId = CacheUtil.cache('x-store');
    const url = `/stores/${storeId}/variants/${uuid}`;
    return RequestUtil.delete(url, {}, () => ShopProductVariantItemDto);
  }
}

export { ShopProductVariantItemService };
