import React, {
  Dispatch, SetStateAction, useCallback,
  useEffect, useRef,
  useState,
} from 'react';
import {
  IModal,
} from '../../../modal/modal';
import {
  ShippingMethodDto,
} from '../../../../hooks/shipping-methods/dto/shipping-method.dto';
import { ShippingMethodActions } from '../shipping-method.actions';
import {
  useParentUntilFrom,
} from '../../../modal/hooks/use-parent-until-from.hook';
import { ShippingMethodLiveEditorFromEnum } from '../shipping-method-item';
import {
  BootstrapInstanceEnum,
} from '../../../modal/hooks/bootstrap-instance.enum';
import PupSub from 'pubsub-js';
import { YesModalSizeEnum, YesNoModal } from '../../../modal/yesno-modal';

export interface IShippingMethodBaseDataLiveEditor extends IModal {
  shippingMethod: [ShippingMethodDto, Dispatch<SetStateAction<ShippingMethodDto>>];
}

const ShippingMethodLiveEditor = ({
  shippingMethod,
}: IShippingMethodBaseDataLiveEditor) => {
  const thisRef = useRef(null);
  const parentUntilFrom = useParentUntilFrom([]);
  const [modalId, setModalId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [shippingMethodToEdit, setShippingMethodEdited] = shippingMethod;

  // states of each field
  const [name, setName] = useState('');
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setModalId(
      ShippingMethodLiveEditorFromEnum.MODAL_LIVE_EDITOR_FORM_INSTANCE +
      shippingMethodToEdit.uuid);
    setName && setName(shippingMethodToEdit?.name ?? '');
    setEnabled && setEnabled(shippingMethodToEdit?.enabled ?? false);
  }, [shippingMethodToEdit]);

  const onSave = useCallback(() => {
    const shippingMethodTransitory = {
      ...shippingMethodToEdit,
      enabled,
    } as ShippingMethodDto;
    setIsLoading(true);
    ShippingMethodActions.save(shippingMethodTransitory).then(() => {
      setShippingMethodEdited &&
      setShippingMethodEdited(shippingMethodTransitory);

      // Handler Modal Event
      const $modal = parentUntilFrom(thisRef, 'modal',
        BootstrapInstanceEnum.MODAL);
      $modal.hide();
    }).finally(() => {
      setIsLoading(false);
    });
  }, [
    shippingMethodToEdit,
    enabled,
  ]);

  useEffect(() => {
    // Subscriber
    PubSub.unsubscribe(modalId + '-save');
    PupSub.subscribe(modalId + '-save', onSave);
  }, [
    shippingMethodToEdit,
    enabled,
  ]);

  return (
    <YesNoModal id={modalId}
                size={YesModalSizeEnum.LG}
                className={modalId}
                header={(() => {
                  return <p className={`modal-title fs-5`}>Edit Product</p>;
                })()}
                body={
                  <div ref={thisRef}>
                    <p className="card-title text-muted fs-07rem">{`shipping-method:${shippingMethodToEdit?.uuid}`}</p>
                    <form className="needs-validation"
                          noValidate={true}
                          autoComplete="off">
                      <div className="form-floating mb-3">
                        <span className={'fs-4'}>{shippingMethodToEdit?.name ??
                          ''}</span>
                      </div>
                      <div className="form-check form-switch">
                        <input id="shipping-method.enabled"
                               className="form-check-input"
                               type="checkbox"
                               onChange={(el) => setEnabled(el.target.checked)}
                               role="switch"
                               checked={enabled}
                        />
                        <label className="form-check-label"
                               htmlFor="shipping-method.enabled">Enabled /
                          Disabled</label>
                      </div>
                      <label htmlFor={'shipping-method.enabled'}
                             className="form-label">
                      </label>
                    </form>
                  </div>
                }
                buttons={{
                  yes: {
                    label: 'Save',
                    isLoading: [isLoading, setIsLoading],
                  },
                }}
    ></YesNoModal>
  );
};

export default ShippingMethodLiveEditor;
