import React, { useCallback, useContext, useEffect, useRef } from 'react';
import AppContext from '../../context/app.context';
import { TrySync } from '../../utils';
import Chart from 'chart.js/auto';
import { OrderDto } from '../../hooks/orders/dto/order.dto';
import { NavLink } from 'react-router-dom';
import { OrderItem } from '../store/orders/order-item';
import EmptyComponentForm from '../listing-container/empty-component-form';
import { ListingContainer } from '../listing-container/listing-container';
import {
  StoreOrderListingContainerContext,
} from '../../pages/store/order/store.order.page';
import { IDashboard } from './dashboard';

const DashboardChart = ({data}: IDashboard) => {
  const {currentShop} = useContext(AppContext);
  const canvasRef = useRef(null);

  const chart = useCallback(() => {
    if (!data) {
      return;
    }
    const $canvas = (canvasRef.current as any);
    return TrySync(() => {
      return new Chart(
        $canvas,
        {
          type: 'line',
          data: data.chart,
        },
      );
    });
  }, [data]);

  useEffect(() => {
    if (Object.keys(currentShop || {}).length === 0) {
      return;
    }
    const $canvas = (canvasRef.current as any);
    const chartInstance = Chart.getChart($canvas);
    chartInstance?.destroy();
    chart();
  }, [data]);

  return <div className={'container-fluid'}>
    <div className={'row h-100 my-3'}>
      <div className="col-12 col-lg-6 ps-0 pe-0 pe-sm-2 pe-lg-4">
        <div className="card">
          <div className="card-header">
            Earnings Overview
          </div>
          <div className="card-body">
            <canvas ref={canvasRef}></canvas>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 ps-0 pe-0 pe-sm-2 pe-lg-4 mt-3 mt-lg-0 d-none d-lg-block">
        <div className="card h-100">
          <div className="card-header">
            Latest Orders
          </div>
          <div className="card-body h-200--px px-0 overflow-auto">
            <ListingContainer resource={{
              url: `/stores/${currentShop?.uuid}/orders`,
              type: () => OrderDto,
              interceptor: (response) => {
                return response.rows as Array<OrderDto> ?? [];
              },
            }}
                              breadcrumb={[
                                <NavLink className={'text-decoration-none'}
                                         to={'/store'}>Stores</NavLink>,
                                'Orders',
                                'All']}
                              itemComponentClass={OrderItem}
                              formComponentClass={EmptyComponentForm}
                              ContextComponent={StoreOrderListingContainerContext}
                              showSearchBar={false}
                              showBreadcrumb={false}
            />
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export { DashboardChart };