import React from 'react';
import { PubSubEnum } from '../enums/pub-sub.enum';
import { UIToast } from '../components/toast/toast.ui';
import { Alert } from '../components/modal/alert.modal';

const LoginLayout = (props: { children: ReactProps }) => {
  return (
    <>
      <header>
        <nav id="navbar-login"
             className="navbar fixed-top bg-white d-flex ps-3 pe-3">
          <a
            href={'/'}
            className="navbar-brand">
            <img src="/img/snoween-logo-full.svg"
                 alt={'logo'}
                 height="50"/>
          </a>
        </nav>
      </header>
      <main className="bg-primary h-100">
        <div
          className="d-flex flex-column justify-content-center align-items-center h-100"
        >
          {props.children}
        </div>
        <UIToast topic={[PubSubEnum.TOAST_ERROR]}></UIToast>
        <Alert topicsAllowed={[PubSubEnum.ALERT_MODAL_MESSAGE]}></Alert>
      </main>
    </>

  );
};

export { LoginLayout as default };
