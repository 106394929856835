import React, {
  Dispatch, SetStateAction, useCallback,
  useEffect, useRef,
  useState,
} from 'react';
import {
  IModal,
} from '../../../modal/modal';
import { ProductDto } from '../dto';
import { ShopProductActions } from '../shop-product.actions';
import {
  ShopProductMediaCreateDto,
} from '../dto/shop-product-media-create.dto';
import PupSub from 'pubsub-js';
import {
  useParentUntilFrom,
} from '../../../modal/hooks/use-parent-until-from.hook';
import {
  BootstrapInstanceEnum,
} from '../../../modal/hooks/bootstrap-instance.enum';
import { ShopProductLiveEditorFromEnum } from '../shop-product-item';
import { YesModalSizeEnum, YesNoModal } from '../../../modal/yesno-modal';

export interface IShopProductBaseDataLiveEditor extends IModal {
  product: [ProductDto, Dispatch<SetStateAction<ProductDto>>];
}

const ShopProductMediaLiveEditor = ({
  product,
}: IShopProductBaseDataLiveEditor) => {
  const thisRef = useRef(null);
  const parentUntilFrom = useParentUntilFrom([]);
  const [modalId, setModalId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [productToEdit, setProductToEdit] = product;
  // states for form
  const [productMediaFileType, setProductMediaFileType] = useState('');
  const [productMediaFileName, setProductMediaFileName] = useState('');
  const [productMediaFileBase64, setProductMediaFileBase64] = useState('');

  useEffect(() => {
    setModalId(
      ShopProductLiveEditorFromEnum.MODAL_PRODUCT_MEDIA_LIVE_EDITOR_FORM_INSTANCE +
      productToEdit.uuid);
    setProductMediaFileName &&
    setProductMediaFileName(productToEdit?.mainMedia?.fileName ?? '');
    setProductMediaFileType &&
    setProductMediaFileType(productToEdit?.mainMedia?.mediaType ?? '');
    setProductMediaFileBase64 &&
    setProductMediaFileBase64(productToEdit?.mainMedia?.base64 ?? '');
  }, [productToEdit]);

  const onSave = useCallback(() => {
    const productMediaTransitory = {
      productUUID: productToEdit?.uuid,
      medias: [
        {
          fileName: productMediaFileName,
          mediaType: productMediaFileType,
          base64: productMediaFileBase64,
          isMainImage: true,
        },
      ],
    } as ShopProductMediaCreateDto;
    setIsLoading(true);
    ShopProductActions.saveMedia(productMediaTransitory).then(() => {
      if (!productToEdit) {
        return;
      }
      productToEdit.mainMedia = productMediaTransitory.medias![0];
      setProductToEdit &&
      setProductToEdit({
        ...productToEdit,
      });
      // Handler Modal Event
      const $modal = parentUntilFrom(thisRef, 'modal',
        BootstrapInstanceEnum.MODAL);
      $modal.hide();
    }).finally(() => {
      setIsLoading(false);
    });
  }, [
    productToEdit,
    productMediaFileType,
    productMediaFileName,
    productMediaFileBase64]);

  useEffect(() => {
    // Subscriber
    PubSub.unsubscribe(modalId + '-save');
    PupSub.subscribe(modalId + '-save', onSave);
  }, [
    productToEdit,
    productMediaFileType,
    productMediaFileName,
    productMediaFileBase64]);

  const onProductMediaChange = (evt: any) => {
    const $el = evt.currentTarget;
    const [file] = $el.files;
    if (!file) {
      throw new Error(`'There isn't any image file loaded`);
    }
    const reader = new FileReader();
    reader.addEventListener('loadend', () => {
      setProductMediaFileType(file.type);
      setProductMediaFileName(file.name);
      setProductMediaFileBase64(reader.result as string);
    });
    reader.readAsDataURL(file);
  };

  const onProductMediaRemove = (evt: any) => {
    setProductMediaFileType('');
    setProductMediaFileName('');
    setProductMediaFileBase64('');
  };

  return (
    <YesNoModal id={modalId}
                size={YesModalSizeEnum.LG}
                className={modalId}
                header={(() => {
                  return <p className={`modal-title fs-5`}>Edit Product</p>;
                })()}
                body={
                  <div ref={thisRef}>
                    <p className="card-title text-muted fs-07rem">{`product:${productToEdit?.uuid}`}</p>
                    <form className="needs-validation"
                          noValidate={true}
                          autoComplete="off">
                      <div className="d-flex justify-content-center w-100">
                        <div className="w-100 h-200--px mb-5 border rounded-3">
                          <img className="w-100 h-100"
                               alt="hero bg media"
                               src={productMediaFileBase64}/>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label"
                               htmlFor="shop.product.media">Upload Product Image
                        </label>
                        <div className="input-group">
                          <input id="shop.product.media"
                                 type="file"
                                 className="form-control form-control-lg"
                                 placeholder="Background Image"
                                 required={true}
                                 onChange={onProductMediaChange}
                          />
                          <button className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={onProductMediaRemove}>Remove File
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                }
                buttons={{
                  yes: {
                    label: 'Save',
                    isLoading: [isLoading, setIsLoading],
                  },
                }}
    ></YesNoModal>
  );
};

export { ShopProductMediaLiveEditor };
