import { RequestUtil } from '../../utils';
import { EmptyDto } from '../../types/empty.dto';

class VerifyUserActions {
  static async verify() {
    await RequestUtil.post('/auth/verify-user', {}, {}, () => EmptyDto);
  }
}

export { VerifyUserActions };
