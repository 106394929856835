import React from 'react';
import LoginLayout from '../../layouts/login.layout';
import ForgotPassword from '../../components/auth/forgot-password/forgot-password';

const ForgotPasswordPage = () => {
  return (
    <LoginLayout>
      <ForgotPassword></ForgotPassword>
    </LoginLayout>
  );
};

export { ForgotPasswordPage as default };
