export class Utils {
  static queryParam(key: string) {
    if (!window.location.search) {
      return null;
    }
    const querySplitted = window.location.search.substring(1).split('&');
    const queryParams = querySplitted.reduce((acc, params) => {
      const [key, value] = params.split('=');
      const obj = {} as Record<string, string>;
      obj[key] = value;
      return {
        ...acc,
        ...obj,
      };
    }, {} as Record<string, string>);
    return queryParams[key];
  }

  static random() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  static createActionString(action?: string) {
    return `${action}@${Utils.random()}`;
  }

  static extractAction(action?: string) {
    if (!action) {
      return '';
    }
    return action.split('@')[0];
  }

  static formatNumber(n: number) {
    const amount = Number(n);
    if (isNaN(amount)) {
      return '';
    }

    return new Intl.NumberFormat('us-US',
      {style: 'currency', currency: 'USD'}).format(amount);
  }
}
