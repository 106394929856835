import React, {
  useCallback,
  useEffect, useRef,
  useState,
} from 'react';
import { ProductDto } from './dto';
import { ShopProductActions } from './shop-product.actions';
import {
  useParentUntilFrom,
} from '../../modal/hooks/use-parent-until-from.hook';
import {
  BootstrapInstanceEnum,
} from '../../modal/hooks/bootstrap-instance.enum';
import PupSub from 'pubsub-js';
import { YesModalSizeEnum, YesNoModal } from '../../modal/yesno-modal';
import { PubSubEnum } from '../../../enums/pub-sub.enum';
import {
  ListingContainerEnum,
} from '../../listing-container/listing-container';

const ShopProductForm = () => {
  const thisRef = useRef(null);
  const parentUntilFrom = useParentUntilFrom([]);
  const [modalId, setModalId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // states for each field
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');

  useEffect(() => {
    setModalId(ListingContainerEnum.TOOLBAR_NEW_MODAL_INSTANCE);
    setName('');
    setSlug('');
    setLongDescription('');
    setShortDescription('');
  }, []);

  const onSave = useCallback(() => {
    const productTransitory = {
      name,
      slug,
      shortDescription,
      longDescription,
    } as ProductDto;
    setIsLoading(true);
    ShopProductActions.save(productTransitory).then(() => {

      PubSub.publish(PubSubEnum.LISTING_CONTAINER_SEARCH);

      // Handler Modal Event
      const $modal = parentUntilFrom(thisRef, 'modal',
        BootstrapInstanceEnum.MODAL);
      $modal.hide();
    }).finally(() => {
      setIsLoading(false);
    });
  }, [
    name,
    slug,
    longDescription,
    shortDescription,
  ]);

  useEffect(() => {
    // Subscriber
    PubSub.unsubscribe(modalId + '-save');
    PupSub.subscribe(modalId + '-save', onSave);
  }, [
    name,
    slug,
    longDescription,
    shortDescription,
  ]);

  return (
    <YesNoModal id={modalId}
                size={YesModalSizeEnum.LG}
                className={modalId}
                header={(() => {
                  return <p className={`modal-title fs-5`}>New Product</p>;
                })()}
                body={
                  <div ref={thisRef}>
                    <form className="needs-validation"
                          noValidate={true}
                          autoComplete="off">
                      <div className="form-floating mb-3">
                        <input id="product-name"
                               value={name}
                               onChange={(el) => setName(
                                 el.currentTarget.value)}
                               type="text"
                               className="form-control form-control-lg"
                               placeholder="Name"
                               autoComplete="nope"
                               required={true}/>
                        <label htmlFor={'product-name'}
                               className="form-label">Name
                        </label>
                      </div>
                      <div className="form-floating mb-3">
            <textarea id="product-long-description"
                      value={longDescription}
                      onChange={(el) => setLongDescription(
                        el.currentTarget.value)}
                      className="form-control form-control-lg"
                      placeholder="Long Description"
                      autoComplete="nope"/>
                        <label htmlFor={'product-long-description'}>Long
                          Description
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <input id="product-short-description"
                               value={shortDescription}
                               onChange={(el) => setShortDescription(
                                 el.currentTarget.value)}
                               type="text"
                               className="form-control form-control-lg"
                               placeholder="Short Description"
                               autoComplete="nope"/>
                        <label htmlFor={'product-short-description'}
                               className="form-label">Short Description
                        </label>
                      </div>
                    </form>
                  </div>
                }
                buttons={{
                  yes: {
                    label: 'Save',
                    isLoading: [isLoading, setIsLoading],
                  },
                }}
    ></YesNoModal>
  );
};

export { ShopProductForm };
