import React, { useEffect, useState } from 'react';
import LoginPage from './pages/auth/login.page';
import AppContext, { IAppContext } from './context/app.context';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Authorized from './components/auth/authorized';
import SignUpPage from './pages/auth/sign-up.page';
import ForgotPasswordPage from './pages/auth/forgot-password.page';
import VerifiedUserPage from './pages/auth/verified-user.page';
import ResetPasswordPage from './pages/auth/reset-password.page';
import DashboardPage from './pages/dashboard/dashboard.page';
import NotFoundPage from './pages/404/not-found.page';
import ProfilePage from './pages/profile/profile.page';
import { StorePage } from './pages/store/store.page';
import ProfileEditAvatarPage from './pages/profile/profile-edit-avatar.page';
import ProfileEditNamePage from './pages/profile/profile-edit-name.page';
import ProfileEditEmailPage from './pages/profile/profile-edit-email.page';
import ProfileEditPasswordPage
  from './pages/profile/profile-edit-password.page';
import { StoreProductsPage } from './pages/store/products/store.products.page';
import { CacheUtil } from './utils';
import { ShopUsersPage } from './pages/store/users/shop.users.page';
import { StoreCmsPage } from './pages/store/cms/store.cms.page';
import { StorePaymentGatewayPage }
  from './pages/store/payment-gateway/store.payment-gateway.page';
import VerifyUserPage from './pages/verify/verify-user.page';
import { StoreDto } from './context/dto/store.dto';
import { UserDto } from './context/dto/user.dto';
import { StoreShippingMethodPage }
  from './pages/store/shipping-method/store.shipping-method.page';
import { StoreOrderPage } from './pages/store/order/store.order.page';
import {
  StoreBankAccountPage,
} from './pages/store/bank-account/store.bank-account.page';

function App(props: IAppContext) {
  const [currentUser, setCurrentUser] = useState(
    props.currentUser as UserDto);
  const [shopList, setShopList] = useState([]);
  const [currentShop, setCurrentShop] = useState({} as StoreDto);
  const context: IAppContext = {
    currentUser,
    setCurrentUser,
    shopList,
    setShopList,
    currentShop,
    setCurrentShop,
  };

  useEffect(() => {
    setShopList((currentUser as any)?.stores);
    setCurrentShop(
      (currentUser as any)?.stores?.find(
        (store: json) => store.uuid === CacheUtil.cache('x-store')),
    );
  }, [currentUser]);

  return (
    <AppContext.Provider value={context}>
      <BrowserRouter>
        <Routes>
          <Route path="/"
                 element={
                   <Authorized>
                     <DashboardPage/>
                   </Authorized>
                 }
          />
          <Route path="/verify"
                 element={<VerifyUserPage/>}/>
          <Route path="/login"
                 element={<LoginPage/>}/>
          <Route path="/sign-up"
                 element={<SignUpPage/>}/>
          <Route path="/forgot-password"
                 element={<ForgotPasswordPage/>}/>
          <Route path="/user-password-reset/:token"
                 element={<ResetPasswordPage/>}/>
          <Route path="/verified"
                 element={<VerifiedUserPage/>}/>
          <Route path="/profile"
                 element={
                   <Authorized>
                     <ProfilePage/>
                   </Authorized>
                 }
          />
          <Route path="/profile/edit/avatar"
                 element={
                   <Authorized>
                     <ProfileEditAvatarPage/>
                   </Authorized>
                 }
          />
          <Route path="/profile/edit/name"
                 element={
                   <Authorized>
                     <ProfileEditNamePage/>
                   </Authorized>
                 }
          />
          <Route path="/profile/edit/email"
                 element={
                   <Authorized>
                     <ProfileEditEmailPage/>
                   </Authorized>
                 }
          />
          <Route path="/profile/edit/password"
                 element={
                   <Authorized>
                     <ProfileEditPasswordPage/>
                   </Authorized>
                 }
          />
          <Route path="/store"
                 element={
                   <Authorized>
                     <StorePage/>
                   </Authorized>
                 }
          />
          <Route path="/store/products"
                 element={
                   <Authorized>
                     <StoreProductsPage/>
                   </Authorized>
                 }
          />
          <Route path="/store/users"
                 element={
                   <Authorized>
                     <ShopUsersPage/>
                   </Authorized>
                 }
          />
          <Route path="/store/payment-gateway"
                 element={
                   <Authorized>
                     <StorePaymentGatewayPage/>
                   </Authorized>
                 }
          />
          <Route path="/store/shipping-methods"
                 element={
                   <Authorized>
                     <StoreShippingMethodPage/>
                   </Authorized>
                 }
          />
          <Route path="/store/orders"
                 element={
                   <Authorized>
                     <StoreOrderPage/>
                   </Authorized>
                 }
          />
          <Route path="/store/cms"
                 element={
                   <Authorized>
                     <StoreCmsPage/>
                   </Authorized>
                 }
          />
          <Route path="/store/bank-account"
                 element={
                   <Authorized>
                     <StoreBankAccountPage/>
                   </Authorized>
                 }
          />
          <Route path="*"
                 element={<NotFoundPage/>}/>
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
