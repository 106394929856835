import React, {
  Dispatch, SetStateAction,
  useCallback,
  useEffect,
  useRef, useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import { IProps } from '../../types';

export enum YesModalSizeEnum {
  DEFAULT = '',
  LG = 'modal-lg'
}

export interface IYesNoModal extends IProps {
  id: string;
  size?: YesModalSizeEnum;
  header?: ReactProps;
  body?: ReactProps;
  footer?: ReactProps;
  buttons?: {
    yes?: {
      label?: ReactProps,
      isLoading?: [boolean, Dispatch<SetStateAction<boolean>>];
    },
    no?: {
      label?: ReactProps,
    }
  };
}

const YesNoModal = ({
  id,
  className,
  size,
  header,
  body,
  footer,
  buttons,
}: IYesNoModal) => {
  const modalRef = useRef(null);
  const [$component, set$Component] = useState({} as any);
  const [isLoading, setIsLoading] = buttons?.yes?.isLoading || [];

  const onYes = useCallback(() => {
    PubSub.publish(id + '-save', {});
  }, [$component]);

  useEffect(() => {
    if (!modalRef) {
      return;
    }
    const $component = (modalRef.current as any);
    const $modal = bootstrap.Modal.getOrCreateInstance($component);
    set$Component($component);
    $component.addEventListener('shown.bs.modal', () => {
      // Do something
    });
    $component.addEventListener('hidden.bs.modal', () => {
      // Do something
    });
  }, []);

  return (
    <>
      <div ref={modalRef}
           id={id}
           key={id}
           className={`modal ${className || ''}`}
           data-bs-backdrop="static"
           data-bs-keyboard="false">
        <div
          className={`modal-dialog modal-dialog-centered modal-dialog-scrollable ${size}`}>
          <div className="modal-content">
            <div className="modal-header">
              {isLoading &&
                  <div className="spinner-border spinner-border-sm me-2"
                       role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>}
              {header}
              <NavLink className="btn-close"
                       data-bs-dismiss="modal"
                       to={''}></NavLink>
            </div>
            <div
              className={`modal-body`}>
              {body}
            </div>
            <div className="modal-footer">
              {footer}
              <NavLink className="btn btn-secondary"
                       data-bs-dismiss="modal"
                       to={''}
              >{buttons?.no?.label || 'Cancel'}
              </NavLink>
              <button type="button"
                      className="btn btn-primary"
                      onClick={onYes}
                      disabled={isLoading}
              >{buttons?.yes?.label || 'Yes'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { YesNoModal };
