import React, {
  DependencyList, Dispatch, SetStateAction,
  useCallback,
  useContext,
  useEffect, useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { IProps } from '../../types';
import { RequestUtil, Try, Utils } from '../../utils';
import { IListingContainerContext } from './listing-container.context';
import AppContext from '../../context/app.context';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { BootstrapInstanceEnum } from '../modal/hooks/bootstrap-instance.enum';
import {
  useQuerySelectorFrom,
} from '../modal/hooks/use-query-selector-from.hook';
import PupSub from 'pubsub-js';
import { PubSubEnum } from '../../enums/pub-sub.enum';
import PubSub from 'pubsub-js';

let timeoutToken: string | number | NodeJS.Timeout | undefined;

export enum ListingContainerEnum {
  TOOLBAR_NEW_MODAL_INSTANCE = 'TOOLBAR_NEW_MODAL_INSTANCE'
}

export enum ListingContainerActionsEnum {
  CREATE = 'CREATE',
  CREATED = 'CREATED'
}

interface IListingContainerResource<T> {
  url?: string;
  type?: () => ClassConstructor<T>,
  interceptor?: (result: json) => any,
  data?: {
    fn: () => T[],
    deps: DependencyList,
    useStateFetching?: [boolean, Dispatch<SetStateAction<boolean>>]
  },
}

interface IListingContainer<T> extends IProps {
  breadcrumb?: ReactProps[];
  resource: IListingContainerResource<T>;
  itemComponentClass: (model: json) => JSX.Element;
  formComponentClass: (model: json) => JSX.Element;
  ContextComponent: React.Context<IListingContainerContext<any>>;
  showSearchBar?: boolean,
  showBreadcrumb?: boolean,
  additionalSearchBarItems?: ReactProps[];
}

function ListingContainer<T>({
  breadcrumb,
  itemComponentClass,
  formComponentClass,
  ContextComponent,
  resource,
  showSearchBar = true,
  showBreadcrumb = true,
  additionalSearchBarItems,
}: IListingContainer<T>) {
  const thisRef = useRef(null);
  const querySelectorFrom = useQuerySelectorFrom([]);
  const {currentShop} = useContext(AppContext);
  const {action, setAction, setItemToEdit} = useContext(ContextComponent);
  const [criteria, setCriteria] = useState('');
  const [itemData, setItemData] = useState([] as T[]);
  const [fetching, setFetching] = useState(false);
  const [externalFetching, setExternalFetching] = resource.data?.useStateFetching ||
  [];
  const url = resource?.url ?? '';

  useEffect(() => {
    // Subscriber
    PubSub.unsubscribe(PubSubEnum.LISTING_CONTAINER_SEARCH);
    PupSub.subscribe(PubSubEnum.LISTING_CONTAINER_SEARCH, searchCallback);
  }, []);

  useEffect(() => {
    if (!resource.data) {
      return;
    }
    setItemData(resource.data.fn() as T[] || []);
  }, resource.data?.deps || []);

  const searchCallback = useCallback(() => {
    if (resource.data) {
      return;
    }
    setFetching(true);
    RequestUtil.get(`${url}?criteria=${criteria}`, {}, resource.type!,
      resource.interceptor).
      then(response => {
        setItemData(response as T[] || []);
      }).
      finally(() => {
        setFetching(false);
      });
  }, [criteria, url, resource.data]);

  // Call to callback for criteria change or current shop change
  useEffect(() => {
    if (currentShop?.uuid) {
      if (timeoutToken) {
        clearTimeout(timeoutToken);
      }
      timeoutToken = setTimeout(() => {
        searchCallback();
      }, 500);
    }
  }, [criteria, currentShop, url, searchCallback, resource.data]);

  // Call to callback for created something
  useEffect(() => {
    if (Utils.extractAction(action) === ListingContainerActionsEnum.CREATED) {
      searchCallback();
    }
  }, [action, searchCallback]);

  const onNew = () => {
    try {
      const $modal = querySelectorFrom(thisRef,
        `.${ListingContainerEnum.TOOLBAR_NEW_MODAL_INSTANCE}`,
        BootstrapInstanceEnum.MODAL);
      $modal?.show();
    } catch (e) {
      PubSub.publish(PubSubEnum.STORE_LIVE_EDITOR_SHOW);
    }
  };

  const Item = itemComponentClass;
  const Form = formComponentClass;

  return (
    <div ref={thisRef}
         className="d-flex w-100 h-100">
      <div id="mod-restaurant-main"
           className="w-100 position-relative">
        <div className="d-flex flex-column align-items-center ps-3 pe-3">
          {(showBreadcrumb || showSearchBar) && (
            <div className="d-flex w-100 sticky-top bg-white border-bottom">
              {showBreadcrumb && (
                <div className="flex-grow-1 d-none d-sm-inline-flex">
                  <nav className="align-self-center mt-1 mb-1 me-auto ps-3">
                    <ol className="breadcrumb m-0">
                      {breadcrumb?.map((str, index) => (
                        <li key={index}
                            className={`breadcrumb-item ${index ===
                            breadcrumb?.length - 1 ? 'active' : ''}`}>{str}</li>
                      ))}
                    </ol>
                  </nav>
                </div>
              )}
              {
                showSearchBar && (
                  <div className="d-flex flex-row flex-grow-1 flex-sm-grow-0 justify-content-end">

                    <div className="input-group input-group-sm mt-1 mb-1">
                      {additionalSearchBarItems?.map((item, index) => (item))}
                      <Link
                        className="text-decoration-none input-group-text"
                        to={''}>
                        <i className="bi bi-search"></i>
                      </Link>
                      <input
                        onChange={(el) => setCriteria(el.currentTarget.value)}
                        type="text"
                        className="form-control"
                        placeholder="Search"/>
                      <Link
                        className="text-decoration-none input-group-text"
                        onClick={onNew}
                        to={''}>
                        <i className="bi bi-plus-lg me-1"></i>
                        New
                      </Link>
                    </div>
                  </div>
                )
              }
            </div>
          )}
          <div
            className="container-fluid p-0 overflow-auto">
            {(externalFetching || fetching) &&
                <p className={'mt-3 text-center'}>
              <span className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"></span>
                  Fetching...
                </p>}
            {(!externalFetching && !fetching) && itemData.length === 0 &&
                <p className={'mt-3 text-center'}>No data found!</p>}
            {!fetching && itemData.length >= 1 &&
              itemData.map(data => <Item key={(data as json).uuid}
                                         model={data}/>)}
          </div>
        </div>
      </div>
      <Form model={{}}/>
    </div>
  );
}

export { ListingContainer };
