import {Expose} from 'class-transformer';

export class ShopProductUpdateDto {

  @Expose()
  name!: string;

  @Expose()
  longDescription?: string;

  @Expose()
  shortDescription?: string;

  @Expose()
  stock?: number;

  @Expose()
  price?: number;

  @Expose()
  enabled?: number;
}
