import React, { SetStateAction, useState } from 'react';
import { ShopProductVariantService } from './shop-product-variant.service';
import { ShopProductVariantDto } from './shop-product-variant.dto';

export interface IShopProductVariantUIModel {
  showLoadingVariants: boolean;
  setShowLoadingVariants: React.Dispatch<SetStateAction<boolean>>;
  data: ShopProductVariantDto[];
  setData: React.Dispatch<SetStateAction<ShopProductVariantDto[]>>;
  fetch: Function;
}

const useCreateModel = () => {
  const [showLoadingVariants, setShowLoadingVariants] = useState(false);
  const [data, setData] = useState([] as ShopProductVariantDto[]);

  const fetch = () => {
    setShowLoadingVariants(true);
    ShopProductVariantService.fetch().then(response => {
      setData(response as unknown as ShopProductVariantDto[]);
    }).finally(() => {
      setShowLoadingVariants(false);
    });
  };

  return {
    showLoadingVariants,
    setShowLoadingVariants,
    data,
    setData,
    fetch,
  } as IShopProductVariantUIModel;
};

export { useCreateModel };