import React from 'react';
import { MainLayout } from '../../layouts/main.layout';
import MainBody from '../../components/main/main-body';

const NotFoundPage = () => {
  return (
    <MainLayout>
      <MainBody>
        <h1 className={'position-absolute translate-middle top-50 start-50'}>
          404
        </h1>
      </MainBody>
    </MainLayout>
  );
};

export { NotFoundPage as default };
