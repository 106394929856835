import React from 'react';
import { IProps } from '../../types';

const MainContainer = ({className, children}: IProps) => {
  return (
    <>
      <div
        className={className ? className : 'card border-0 rounded-1rem shadow-sm w-100 h-100'}>
        {children}
      </div>
    </>
  );
};

export { MainContainer as default };
