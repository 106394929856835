import React, { useEffect, useState } from 'react';
import { IProps } from '../../types';
import { NavLink, useNavigate } from 'react-router-dom';

interface IMainHeaderProps extends IProps {
  backButtonClassName?: string;
}

const hideInPaths = ['/'];

const MainHeader = ({
  className,
  children,
  backButtonClassName,
}: IMainHeaderProps) => {
  const [showBackNavigate, setShowBackNavigate] = useState(false);
  const [currentPath, setCurrentPath] = useState(
    window?.location?.pathname || '/');
  const defaultClasses = 'position-relative border-0 rounded-top-1rem align-self-start p-2 w-100 h-52--px';
  const defaultClassesBackButton = 'w-60--px h-52--px position-absolute top-0 end-0 rounded-top-end-1rem';
  const navigate = useNavigate();

  useEffect(() => {
    setShowBackNavigate(!hideInPaths.includes(window?.location?.pathname));
  }, [currentPath]);

  return (
    <>
      <div
        className={`${defaultClasses} ${className ?? ''}`}>
        {children}
        <div
          className={`${defaultClassesBackButton} ${backButtonClassName ??
          ''}`}>
          {showBackNavigate && <NavLink
              className="text-decoration-none text-white position-absolute top-50 end-0 translate-middle fs-5 pe-1"
              onClick={() => {navigate(-1);}}
              to={''}><i className="bi bi-arrow-left me-1"></i>
          </NavLink>}
        </div>
      </div>
    </>
  );
};

export { MainHeader };
