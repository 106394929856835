import { Pipes, RequestUtil } from '../../../utils';
import { ShopUserDto, ShopUserCreateDto, ShopUserUpdateDto } from './dto';
import { UserDto } from '../../../context/dto/user.dto';

class ShopUserActions {

  static async save(data: ShopUserDto, storeUUID: string) {
    const url = `/stores/${storeUUID}/users`;
    if (data.id) {
      return RequestUtil.patch(
        `${url}/${data.id}`,
        Pipes.transform(ShopUserUpdateDto, data),
        {},
        () => UserDto,
      );
    }
    return RequestUtil.post(url, Pipes.transform(ShopUserCreateDto, data),
      {}, () => UserDto);
  }
}

export { ShopUserActions };
