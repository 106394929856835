import React, {
  DependencyList,
  useContext,
  useEffect,
} from 'react';
import { IProps } from '../../../types';
import { CMSFormHeroSection } from './cms-form-hero-section';
import { CMSFormNavbarSection } from './cms-form-navbar-section';
import AppContext from '../../../context/app.context';
import CMSContext from './cms.context';
import { CmsPropertyDto } from '../../../hooks/cms/dto/cms-property.dto';

interface IListingCMSFormContainerResource<T> {
  url: string;
  data?: { fn: () => T[], deps: DependencyList };
}

interface ICMSFormContainer<T> extends IProps {
  breadcrumb?: ReactProps[];
  resource?: IListingCMSFormContainerResource<T>;

}

const CMSForm = ({
  breadcrumb,
  resource,
}: ICMSFormContainer<CmsPropertyDto>) => {
  const {currentShop} = useContext(AppContext);
  const {data, setData} = useContext(CMSContext);

  useEffect(() => {
    if (!resource?.data) {
      return;
    }
    setData && setData(resource?.data.fn() as CmsPropertyDto[] || []);
  }, resource?.data?.deps || []);

  return (
    <>
      <div className="d-flex w-100 sticky-top bg-white border-bottom">
        <div className="flex-grow-1 d-none d-sm-inline-flex">
          <nav className="align-self-center mt-1 mb-1 me-auto ps-3">
            <ol className="breadcrumb m-0">
              {breadcrumb?.map((str, index) => (
                <li key={index}
                    className={`breadcrumb-item ${index ===
                    breadcrumb?.length - 1 ? 'active' : ''}`}>{str}</li>
              ))}
            </ol>
          </nav>
        </div>
      </div>
      <div className="accordion">
        <div className="accordion-item">
          <h2 className="accordion-header"
              id="cms-navbar-section">
            <button className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cms-navbar-section-collapse"
                    aria-expanded="true"
                    aria-controls="cms-navbar-section-collapse">
              Navbar Section
            </button>
          </h2>
          <div id="cms-navbar-section-collapse"
               className="accordion-collapse collapse show"
               aria-labelledby="cms-navbar-section">
            <div className="accordion-body">
              <CMSFormNavbarSection resource={resource}/>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header"
              id="cms-hero-section">
            <button className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#cms-hero-section-collapse"
                    aria-expanded="false"
                    aria-controls="cms-hero-section-collapse">
              Hero Section
            </button>
          </h2>
          <div id="cms-hero-section-collapse"
               className="accordion-collapse collapse"
               aria-labelledby="cms-hero-section">
            <div className="accordion-body">
              <CMSFormHeroSection resource={resource}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
    ;
};

export { CMSForm };
