import { Expose, Type } from 'class-transformer';
import { PropertyDto } from './property.dto';

export class PaymentGatewayDto {
  @Expose()
  uuid?: string;

  @Expose()
  name?: string;

  @Expose()
  code?: string;

  @Expose()
  enabled?: boolean;

  @Expose()
  @Type(() => PropertyDto)
  settings?: PropertyDto[]
}
