import React, { useCallback, useContext, useState } from 'react';
import { MainLayout } from '../../../layouts/main.layout';
import { MainHeader } from '../../../components/main/main-header';
import { ShopMainHeader } from '../../../components/store/shop-main-header';
import MainBody from '../../../components/main/main-body';
import AppContext, { IAppContext } from '../../../context/app.context';
import { NavLink } from 'react-router-dom';
import {
  BankAccountForm,
} from '../../../components/store/bank-account/bank-account-form';

const StoreBankAccountPage = () => {
  const {
    currentShop,
  } = useContext(AppContext) as IAppContext;

  return (
    <MainLayout>
      <MainHeader className={'shadow-sm bg-dark'}>
        <ShopMainHeader/>
      </MainHeader>
      <MainBody>
        <BankAccountForm
          breadcrumb={[
            <NavLink className={'text-decoration-none'}
                     to={'/store'}>Stores</NavLink>,
            'Bank Account',
            'Sections']}/>
      </MainBody>
    </MainLayout>
  );
};

export { StoreBankAccountPage };
