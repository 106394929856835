import { Expose } from "class-transformer";

export class DashboardChartDatasetDto {
  @Expose()
  fill?: boolean;
  
  @Expose()
  label!: string;

  @Expose()
  data!: number;
}