import {Expose} from 'class-transformer';

export class StoreStoreCreateDto {
  @Expose()
  name!: string;

  @Expose()
  slug!: string;

  @Expose()
  email!: string;

  @Expose()
  headOfficeAddressCity!: string;

  @Expose()
  headOfficeAddressState!: string;

  @Expose()
  headOfficeAddressLine1!: string;

  @Expose()
  headOfficeAddressLine2?: string;

  @Expose()
  headOfficeAddressPostalCode!: string;

  @Expose()
  defaultTaxCode?: string;
  
  @Expose()
  enabled!: boolean;

  @Expose()
  isLive!: boolean;
}
