import React from 'react';
import LoginLayout from '../../layouts/login.layout';
import SignUp from '../../components/auth/sign-up/sign-up';

const SignUpPage = () => {
  return (
    <LoginLayout>
      <SignUp></SignUp>
    </LoginLayout>
  );
};

export { SignUpPage as default };
