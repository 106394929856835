import React, {
  Dispatch, SetStateAction, useCallback,
  useEffect, useRef,
  useState,
} from 'react';
import {
  IModal,
} from '../../../modal/modal';
import { ProductDto } from '../dto';
import { ShopProductActions } from '../shop-product.actions';
import {
  useParentUntilFrom,
} from '../../../modal/hooks/use-parent-until-from.hook';
import {
  BootstrapInstanceEnum,
} from '../../../modal/hooks/bootstrap-instance.enum';
import PupSub from 'pubsub-js';
import { ShopProductLiveEditorFromEnum } from '../shop-product-item';
import { YesModalSizeEnum, YesNoModal } from '../../../modal/yesno-modal';

export interface IShopProductEnabledLiveEditor extends IModal {
  product: [ProductDto, Dispatch<SetStateAction<ProductDto>>];
}

const ShopProductEnabledLiveEditor = ({
  product,
}: IShopProductEnabledLiveEditor) => {
  const thisRef = useRef(null);
  const parentUntilFrom = useParentUntilFrom([]);
  const [modalId, setModalId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [productToEdit, setProductToEdit] = product;

  // states of each field
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setModalId(
      ShopProductLiveEditorFromEnum.MODAL_ENABLED_LIVE_EDITOR_FORM_INSTANCE +
      productToEdit.uuid);
    setEnabled(productToEdit?.enabled ?? false);
  }, [productToEdit]);

  const onSave = useCallback(() => {
    const productTransitory = {
      ...productToEdit,
      enabled: enabled,
    } as ProductDto;
    setIsLoading(true);
    ShopProductActions.save(productTransitory).then(() => {
      setProductToEdit && setProductToEdit(productTransitory);

      // Handler Modal Event
      const $modal = parentUntilFrom(thisRef, 'modal',
        BootstrapInstanceEnum.MODAL);
      $modal.hide();
    }).finally(() => {
      setIsLoading(false);
    });
  }, [
    productToEdit,
    enabled,
  ]);

  useEffect(() => {
    // Subscriber
    PubSub.unsubscribe(modalId + '-save');
    PupSub.subscribe(modalId + '-save', onSave);
  }, [
    productToEdit,
    enabled,
  ]);

  return (
    <YesNoModal id={modalId}
                size={YesModalSizeEnum.LG}
                className={modalId}
                header={(() => {
                  return <p className={`modal-title fs-5`}>Edit Product</p>;
                })()}
                body={
                  <div ref={thisRef}>
                    <p className="card-title text-muted fs-07rem">{`product:${productToEdit?.uuid}`}</p>
                    <form className="needs-validation"
                          noValidate={true}
                          autoComplete="off">
                      <div className="form-check form-switch">
                        <input id="product-enabled"
                               className="form-check-input"
                               type="checkbox"
                               onChange={(el) => setEnabled(el.target.checked)}
                               role="switch"
                               checked={enabled}
                        />
                        <label className="form-check-label"
                               htmlFor="product-enabled">Enabled /
                          Disabled</label>
                      </div>
                      <label htmlFor={'product-enabled'}
                             className="form-label">
                      </label>
                    </form>
                  </div>
                }
                buttons={{
                  yes: {
                    label: 'Save',
                    isLoading: [isLoading, setIsLoading],
                  },
                }}
    ></YesNoModal>
  );
};

export { ShopProductEnabledLiveEditor };
