import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppContext, { IAppContext } from '../../context/app.context';

const ProfileForm = () => {
  const {currentUser} = useContext(AppContext) as IAppContext;
  return (
    <>
      <div className="container d-flex justify-content-center position-absolute translate-middle top-50 start-50">
        <div className="col-12 col-sm-8 col-lg-6 col-xl-4">
          <div className="d-flex justify-content-center mb-5">
            <div
              className="w-170--px h-170--px mt-3 mt-sm-0 position-relative rounded-circle bg-secondary">
              <div
                className="w-160--px h-160--px text-white rounded-circle border border-light position-absolute top-50 start-50 translate-middle shadow-sm mb-5">
                <img
                  className="w-160--px h-160--px text-white rounded-circle border border-light position-absolute top-50 start-50 translate-middle shadow-sm mb-5"
                  alt="avatar" src={currentUser?.avatarMedia?.base64 ?? ''} /></div>
              <Link className="text-decorated-none stretched-link"
                    to={'/profile/edit/avatar'}><i
                className="bi bi-camera fs-4 position-absolute top-100 start-100 translate-middle"></i></Link><input
              id="profile.avatar" className="d-none" type="file"
              accept="image/*" /></div>
          </div>
          <div className="mb-5">
            <div className="d-flex">
              <div className="flex-shrink-0"><i
                className="bi bi-person-badge me-2 fs-2"></i></div>
              <div className="d-flex flex-grow-1">
                <div
                  className="d-flex flex-column align-items-start flex-grow-1">
                  <p className="m-0 fs-5">Name</p><span
                  className="text-muted fs-8">Personal information.</span></div>
                <Link className="text-decoration-none align-self-end"
                      to={'/profile/edit/name'}
                >Change</Link>
              </div>
            </div>
            <hr className="dropdown-divider" />
          </div>
          <div className="mb-5">
            <div className="d-flex">
              <div className="flex-shrink-0"><i
                className="bi bi-envelope me-2 fs-2"></i></div>
              <div className="d-flex flex-grow-1">
                <div
                  className="d-flex flex-column align-items-start flex-grow-1">
                  <p className="m-0 fs-5">Email</p><span
                  className="text-muted fs-8">Used for account login.</span>
                </div>
                <Link className="text-decoration-none align-self-end"
                      to={'/profile/edit/email'}>Change</Link>
              </div>
            </div>
            <hr className="dropdown-divider" />
          </div>
          <div className="mb-5">
            <div className="d-flex">
              <div className="flex-shrink-0"><i
                className="bi bi-lock-fill me-2 fs-2"></i></div>
              <div className="d-flex flex-grow-1">
                <div
                  className="d-flex flex-column align-items-start flex-grow-1">
                  <p className="m-0 fs-5">Password</p><span
                  className="text-muted fs-8">Used for account login.</span>
                </div>
                <Link className="text-decoration-none align-self-end"
                      to={'/profile/edit/password'}>Change</Link>
              </div>
            </div>
            <hr className="dropdown-divider" />
          </div>
        </div>
      </div>
    </>
  );
};

export { ProfileForm as default };
