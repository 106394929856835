import { useContext, useEffect, useState } from 'react';
import { RequestUtil } from '../../utils';
import AppContext, { IAppContext } from '../../context/app.context';
import { CmsPropertyDto } from './dto/cms-property.dto';

function useCMSProperties() {
  const {
    currentShop,
  } = useContext(AppContext) as IAppContext;

  const [cmsProperties, setCmsProperties] = useState(
    [] as Array<CmsPropertyDto>);
  useEffect(() => {
    if (!currentShop?.uuid) {
      setCmsProperties([]);
      return;
    }
    RequestUtil.get(`/stores/${currentShop?.uuid}/cms`, {},
      () => CmsPropertyDto).
      then((response: json) => {
        setCmsProperties(response as Array<CmsPropertyDto>);
      }).
      catch(errors => console.error('Error: useCMSProperties'));
  }, [currentShop]);
  return cmsProperties;
}

export { useCMSProperties };