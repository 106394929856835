import {
  ShopProductVariantDto,
} from './shop-product-variant.dto';
import { CacheUtil, Pipes, RequestUtil } from '../../../../utils';

class ShopProductVariantService {

  static async save(data: ShopProductVariantDto) {
    const storeId = CacheUtil.cache('x-store');
    const url = `/stores/${storeId}/variants`;
    if (data.uuid) {
      const uuid = data.uuid;
      delete data.uuid;
      return RequestUtil.patch(
        `${url}/${uuid}`,
        Pipes.transform(ShopProductVariantDto, data),
        {},
        () => ShopProductVariantDto,
      );
    }
    delete data.uuid;
    return RequestUtil.post(
      url,
      Pipes.transform(ShopProductVariantDto, data),
      {},
      () => ShopProductVariantDto,
    );
  }

  static async fetch() {
    const storeId = CacheUtil.cache('x-store');
    const url = `/stores/${storeId}/variants`;
    return RequestUtil.get(
      url,
      {},
      () => ShopProductVariantDto,
    );
  }

  static async delete(uuid: string) {
    const storeId = CacheUtil.cache('x-store');
    const url = `/stores/${storeId}/variants/${uuid}`;
    return RequestUtil.delete(url, {}, () => ShopProductVariantDto);
  }
}

export { ShopProductVariantService };
