import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {Link} from 'react-router-dom';
import {ShopUserDto} from './dto/shop-user.dto';
import {Pipes, TrySync, Utils} from '../../../utils';
import {IProps} from '../../../types';
import {ModalInstance} from '../../modal/modal';
import {
  ShopUserListingContainerContext,
} from '../../../pages/store/users/shop.users.page';
import {ShopUserFromEnum} from './shop-user-form';

export enum ShopUserLiveEditorFromEnum {
  MODAL_LIVE_EDITOR_FORM_INSTANCE = 'shop-user-modal-base-data-live-editor'
}

interface IShopUserItem extends IProps {
  model?: ShopUserDto;
}

const ShopUserItem = ({model}: IShopUserItem) => {
  const MODAL_INSTANCE = 'shop-user-form';
  const [thisUser, setThisUser] = useState(
    TrySync(() => Pipes.transform(ShopUserDto, model) as ShopUserDto),
  );
  const {setItemToEdit, itemEdited} = useContext(
    ShopUserListingContainerContext);

  const userEditedCallback = useCallback((userEdited: ShopUserDto) => {
    if (thisUser?.id !== userEdited.id) {
      return;
    }
    setThisUser && setThisUser({...userEdited});
    ModalInstance(ShopUserFromEnum.MODAL_FULL_FORM_INSTANCE).hide();
  }, [itemEdited]);

  // Call to callback for full form editor
  useEffect(() => {
    if (!itemEdited) {
      return;
    }
    userEditedCallback(itemEdited);
  }, [userEditedCallback, itemEdited]);

  if (!model) {
    return <div key={Utils.random()}></div>;
  }

  if (!thisUser) {
    return <div key={Utils.random()}></div>;
  }

  const onShowFormEditor = () => {
    setItemToEdit && setItemToEdit({...thisUser} as ShopUserDto);
    const $modalBootstrap = ModalInstance(MODAL_INSTANCE ?? '');
    $modalBootstrap.show();
  };

  return (
    <div
      key={thisUser.id}
      className="d-flex p-1 g-0 border rounded overflow-hidden mb-1 shadow-sm max-h-140--px position-relative">
      <Link className="stretched-link d-block d-md-none"
            to={''}></Link>
      <Link
        className="text-decoration-none d-none d-sm-inline"
        onClick={onShowFormEditor}
        to={''}>
        <i
          className="bi bi-gear-wide me-1 position-absolute top-0 end-0"></i>
      </Link>
      <div
        className="flex-shrink-0 position-relative g-0 border rounded w-120--px">
        <i
          className="bi bi-box2 fs-1 position-absolute top-50 start-50 translate-middle"></i>
      </div>
      <div className="flex-grow-1 overflow-auto">
        <div className="container-fluid row pe-0">
          <div
            className="col-12 col-md-6 col-lg-4 col-xl-3 ps-2 pe-2">
            <div className="h-24--px position-relative">
              <Link
                className="text-decoration-none text-primary"
                to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{thisUser.id}</p>
              </Link>
            </div>
            <div className="position-relative">
              <Link
                className="text-decoration-none text-reset fs-5"
                to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{thisUser.firstName}</p>
              </Link>
            </div>
            <div className="h-24--px position-relative">
              <Link
                className="text-decoration-none text-muted"
                to={''}><p
                className="pe-4 m-0 w-100 text-truncate">{thisUser.lastName}</p>
              </Link>
            </div>
            <div
              className="h-24--px position-relative card-text mb-auto text-truncate">
              <Link className="text-decoration-none text-reset"
                    to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{thisUser.email}</p>
              </Link>
            </div>
            <div
              className="h-24--px position-relative card-text mb-auto text-truncate">
              <Link className="text-decoration-none text-muted"
                    to={''}>
                <p className="pe-4 m-0 w-100 text-truncate">{thisUser.phone}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {ShopUserItem as default};
