import React, {
  Dispatch, SetStateAction,
  useCallback,
  useEffect, useRef,
  useState,
} from 'react';
import { IModal } from '../../../modal/modal';
import { Utils } from '../../../../utils';
import { OrderDto } from '../../../../hooks/orders/dto/order.dto';
import { OrderActions } from '../order.actions';
import {
  useStoreOrderPaymentStatus,
} from '../../../../hooks/orders/use-store-order-payment-status.hook';
import {
  useStoreOrderShippingStatus,
} from '../../../../hooks/orders/use-store-order-shipping-status.hook';
import {
  useStoreOrderStatus,
} from '../../../../hooks/orders/use-store-order-status.hook';
import { OrderConstants } from '../order.constants';
import { UpdateOrderDto } from '../../../../hooks/orders/dto/update-order.dto';
import {
  useParentUntilFrom,
} from '../../../modal/hooks/use-parent-until-from.hook';
import PupSub from 'pubsub-js';
import {
  BootstrapInstanceEnum,
} from '../../../modal/hooks/bootstrap-instance.enum';
import { Spinner } from '../../../spinner/spinner';

export interface IOrderBaseDataLiveEditor extends IModal {
  order: [OrderDto, Dispatch<SetStateAction<OrderDto>>];
  isLoading: [boolean, Dispatch<SetStateAction<boolean>>];
}

const OrderLiveEditor = ({
  order,
  isLoading,
}: IOrderBaseDataLiveEditor) => {
  const thisRef = useRef(null);
  const [orderToEdit, setOrderToEdit] = order;
  const paymentStatus = useStoreOrderPaymentStatus([orderToEdit]);
  const shippingStatus = useStoreOrderShippingStatus([orderToEdit]);
  const orderStatus = useStoreOrderStatus([orderToEdit]);
  const parentUntilFrom = useParentUntilFrom([]);
  const [isLoadingData, setIsLoading] = isLoading;
  const [isVerifying, setIsVerifying] = useState(false);

  // states of each field
  const [orderStatusLabel, setOrderStatusLabel] = useState('');
  const [paymentPaid, setPaymentPaid] = useState(false);
  const [paymentReference, setPaymentReference] = useState('');
  const [paymentComments, setPaymentComments] = useState('');
  const [shippingSent, setShippingSent] = useState(false);
  const [shippingReference, setShippingReference] = useState('');
  const [shippingComments, setShippingComments] = useState('');

  const onVerify = useCallback(() => {
    setIsVerifying(true);
    OrderActions.verify(orderToEdit.uuid).then((response) => {
      setOrderToEdit(response);
    }).finally(() => {
      setIsVerifying(false);
    });
  }, [orderToEdit]);
  
  const onSave = useCallback(() => {
    const orderTransitory = {
      uuid: orderToEdit.uuid,
      markAsPaid: paymentPaid,
      paymentReference,
      paymentComments,
      markAsSent: shippingSent,
      shippingReference,
      shippingComments,
    } as UpdateOrderDto;
    setIsLoading(true);
    OrderActions.save(orderTransitory).then((response) => {
      setOrderToEdit(response);

      // Handler Modal Event
      const $modal = parentUntilFrom(thisRef, 'modal',
        BootstrapInstanceEnum.MODAL);
      $modal.hide();
    }).finally(() => {
      setIsLoading(false);
    });
    return false;
  }, [
    orderToEdit,
    paymentPaid,
    paymentReference,
    paymentComments,
    shippingSent,
    shippingReference,
    shippingComments]);

  useEffect(() => {
    // Subscriber
    PubSub.unsubscribe(orderToEdit.uuid + '-save');
    PupSub.subscribe(orderToEdit.uuid + '-save', onSave);
  }, [
    orderToEdit,
    paymentPaid,
    paymentReference,
    paymentComments,
    shippingSent,
    shippingReference,
    shippingComments]);

  useEffect(() => {
    if (!orderToEdit) {
      return;
    }
    const [paymentStatusLabel, paymentBg, paymentStatusEnum] = paymentStatus(
      orderToEdit);
    const [shippingStatusLabel, shippingBg, shippingStatusEnum] = paymentStatus(
      orderToEdit);
    const [statusLabel] = orderStatus(orderToEdit);
    setOrderStatusLabel(statusLabel);
    setPaymentPaid(paymentStatusEnum === OrderConstants.PAYMENT_PAID);
    setPaymentReference(orderToEdit.cartSnapshot?.payment?.reference || '');
    setPaymentComments(orderToEdit.cartSnapshot?.payment?.comments || '');
    setShippingSent(paymentStatusEnum === OrderConstants.SHIPPING_SENT);
    setShippingReference(orderToEdit.cartSnapshot?.shipping?.reference || '');
    setShippingComments(orderToEdit.cartSnapshot?.shipping?.comments || '');
  }, [orderToEdit]);

  const payment = useCallback(() => {
    if (!orderToEdit) {
      return <></>;
    }
    const [statusLabel, bg, status] = paymentStatus(orderToEdit);
    return <div className="accordion-item">
      <h2 className="accordion-header"
          id="accordion-order-payment">
        <button className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-order-payment-collapse"
                aria-expanded="true"
                aria-controls="accordion-order-payment-collapse">
          Payment
        </button>
      </h2>
      <div id="accordion-order-payment-collapse"
           className="accordion-collapse collapse show"
           aria-labelledby="accordion-order-payment">
        <div className="accordion-body">
          <div className={'d-flex flex-column mb-3'}>
            <div className={'mb-3'}>
              <p className={'mb-0'}>
              <span className={'me-3'}>{orderToEdit?.cartSnapshot?.payment?.paymentGatewayCode ||
                ''}</span>
                <span>{statusLabel}</span>
              </p>
            </div>
            <div className={(status === OrderConstants.PAYMENT_PAID
              ? 'd-none'
              : '')}>
              <div className={'d-flex form-check form-switch mb-3 '}>
                <div>
                  <label className="form-check-label"
                         htmlFor="order-payment-status">Mark as Paid</label>
                  <input id="order-payment-status"
                         className="form-check-input"
                         type="checkbox"
                         onChange={(el) => setPaymentPaid(el.target.checked)}
                         role="switch"
                         checked={paymentPaid}
                  />
                </div>
                <div>
                  <button className={'btn btn-outline-primary btn-sm ms-3'}
                          type={'button'}
                          onClick={onVerify}>
                    <Spinner show={isVerifying}/>
                    {!isVerifying && <span className={'mx-2'}>Verify</span> }
                  </button>
                  
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="order-payment-reference"
                       className="form-label">Reference</label>
                <input type="text"
                       className="form-control"
                       id="order-payment-reference"
                       value={paymentReference}
                       onChange={(el) => setPaymentReference(el.target.value)}
                       placeholder="Reference"/>
              </div>
              <div className="mb-3">
                <label htmlFor="order-payment-comments"
                       className="form-label">Comments</label>
                <textarea className="form-control"
                          id="order-payment-comments"
                          value={paymentComments}
                          onChange={(el) => setPaymentComments(el.target.value)}
                          rows={3}></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }, [orderToEdit, paymentPaid, paymentReference, paymentComments, isVerifying]);

  const shipping = useCallback(() => {
    if (!orderToEdit) {
      return <></>;
    }
    const [statusLabel, bg, status] = shippingStatus(orderToEdit);
    return <div className="accordion-item">
      <h2 className="accordion-header"
          id="accordion-order-shipping">
        <button className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-order-shipping-collapse"
                aria-expanded="true"
                aria-controls="accordion-order-shipping-collapse">
          Shipping
        </button>
      </h2>
      <div id="accordion-order-shipping-collapse"
           className="accordion-collapse collapse show"
           aria-labelledby="accordion-order-shipping">
        <div className="accordion-body">
          <div className={'d-flex flex-column mb-3'}>
            <div className={'mb-3'}>
              <p className={'mb-0'}>
          <span className={'me-3'}>{orderToEdit?.cartSnapshot?.shipping?.shippingMethodCode ||
            ''}</span>
                <span>{statusLabel}</span>
              </p>
            </div>
            <div className={(status === OrderConstants.SHIPPING_SENT
              ? 'd-none'
              : '')}>
              <div className={'form-check form-switch mb-3 '}>
                <label className="form-check-label"
                       htmlFor="order-shipping-status">Mark as Sent</label>
                <input id="order-shipping-status"
                       className="form-check-input"
                       type="checkbox"
                       onChange={(el) => setShippingSent(el.target.checked)}
                       role="switch"
                       checked={shippingSent}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="order-shipping-reference"
                       className="form-label">Reference</label>
                <input type="text"
                       className="form-control"
                       id="order-shipping-reference"
                       value={shippingReference}
                       onChange={(el) => setShippingReference(el.target.value)}
                       placeholder="Reference"/>
              </div>
              <div className="mb-3">
                <label htmlFor="order-shipping-comments"
                       className="form-label">Comments</label>
                <textarea className="form-control"
                          id="order-shipping-comments"
                          value={shippingComments}
                          onChange={(el) => setShippingComments(
                            el.target.value)}
                          rows={3}></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }, [orderToEdit, shippingSent, shippingReference, shippingComments]);

  const items = useCallback(() => {
    if (!orderToEdit) {
      return <></>;
    }
    return <div className="accordion-item">
      <h2 className="accordion-header"
          id="accordion-order-items">
        <button className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-order-items-collapse"
                aria-expanded="true"
                aria-controls="accordion-order-items-collapse">
          Items
        </button>
      </h2>
      <div id="accordion-order-items-collapse"
           className="accordion-collapse collapse show"
           aria-labelledby="accordion-order-items">
        <div className="accordion-body px-0 pb-0">
          <ul className="list-group">
            {orderToEdit.cartSnapshot?.items?.map(
              item =>
                <li key={item.product.uuid}
                    className="list-group-item d-flex justify-content-between lh-condensed border-0 border-bottom">
                  <div>
                    <h6 className="my-0">{item?.product?.name}</h6>
                    <small className="text-muted">{item?.product?.shortDescription}</small>
                    <div>
                      <small className="text-muted">{item?.qty} x </small>
                      <small className="text-muted">{Utils.formatNumber(
                        item?.product?.price ?? 0)}</small>
                    </div>
                  </div>
                  <span className="text-muted align-self-end">{Utils.formatNumber(
                    item?.product?.amount ?? 0)}</span>
                </li>)}
          </ul>
        </div>
      </div>
    </div>;
  }, [orderToEdit]);

  const summary = useCallback(() => {
    if (!orderToEdit) {
      return <></>;
    }
    return <div className="accordion-item">
      <h2 className="accordion-header"
          id="accordion-order-summary">
        <button className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordion-order-summary-collapse"
                aria-expanded="true"
                aria-controls="accordion-order-summary-collapse">
          Summary
        </button>
      </h2>
      <div id="accordion-order-summary-collapse"
           className="accordion-collapse collapse show"
           aria-labelledby="accordion-order-summary">
        <div className="accordion-body px-0">
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-condensed border-0">
              <span>Total</span>
              <strong>{Utils.formatNumber(
                orderToEdit?.cartSnapshot?.summary.total ?? 0)}</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>;
  }, [orderToEdit]);

  return (
    <div ref={thisRef}>
      <p className="card-title text-muted fs-07rem">{`order:${orderToEdit?.uuid}`}</p>
      <form className="needs-validation"
            noValidate={true}
            autoComplete="off">
        <div className="form-floating mb-3">
            <span className={'fs-4'}>{orderToEdit?.cartSnapshot?.billingAddress?.email ??
              ''}</span>
        </div>
        <div className="accordion">
          {payment()}
          {shipping()}
          {items()}
          {summary()}
        </div>
      </form>
    </div>
  );
};

export { OrderLiveEditor };
