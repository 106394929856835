import { CacheUtil, RequestUtil } from '../../../utils';
import {
  PaymentGatewayDto,
} from '../../../hooks/payment-gateways/dto/payment-gateway.dto';

class PaymentGatewayActions {

  static async save(data: PaymentGatewayDto) {
    const storeId = CacheUtil.cache('x-store');
    const url = `/stores/${storeId}/payment-gateways`;
    return RequestUtil.post(url, {
        paymentGateways: [
          {
            code: data.code,
            enabled: data.enabled,
            settings: data.settings,
          }],
      },
      {},
      () => PaymentGatewayDto,
    );
  }
}

export { PaymentGatewayActions };
