export enum PubSubEnum {
  LISTING_CONTAINER_SEARCH = 'LISTING_CONTAINER_SEARCH',
  LISTING_CONTAINER_SEARCH_BAR_VARIANTS_SHOW = 'LISTING_CONTAINER_SEARCH_BAR_VARIANTS_SHOW',
  STORE_LIVE_EDITOR_SHOW = 'STORE_LIVE_EDITOR_SHOW',
  TOAST_ERROR = 'TOAST_ERROR',
  TOAST_SAVED = 'TOAST_SAVED',
  TOAST_UPDATE = 'TOAST_UPDATE',
  TOAST_DELETE = 'TOAST_DELETE',
  TOAST_WARNING = 'TOAST_WARNING',
  TOAST_SUCCESS = 'TOAST_SUCCESS',
  ALERT_MODAL_MESSAGE = 'ALERT_MODAL_MESSAGE',
  ALERT_CONFIRM_MODAL_MESSAGE = 'ALERT_CONFIRM_MODAL_MESSAGE',
}