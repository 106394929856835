import { Expose, Type } from "class-transformer";
import { DashboardSummaryDto } from "./dashboard-summary.dto";
import { DashboardChartDto } from './dashboard-chart.dto';

export class DashboardDto {
  @Expose()
  @Type(() => DashboardSummaryDto)
  summary!: DashboardSummaryDto;

  @Expose()
  @Type(() => DashboardChartDto)
  chart!: DashboardChartDto;
}