import React, { useCallback, useContext, useState } from 'react';
import PubSub from 'pubsub-js';
import { MainLayout } from '../../../layouts/main.layout';
import { MainHeader } from '../../../components/main/main-header';
import { ShopMainHeader } from '../../../components/store/shop-main-header';
import MainBody from '../../../components/main/main-body';
import { ListingContainer }
  from '../../../components/listing-container/listing-container';
import
{
  ShopProductItem,
} from '../../../components/store/products/shop-product-item';
import { ShopProductForm }
  from '../../../components/store/products/shop-product-form';
import { ProductDto } from '../../../components/store/products/dto';
import CreateListingContainerContext
  , {
  IListingContainerContext,
} from '../../../components/listing-container/listing-container.context';
import { NavLink } from 'react-router-dom';
import {
  SearchBarItem,
} from '../../../components/listing-container/searchbar-item/search-bar-item';
import AppContext from '../../../context/app.context';
import { PubSubEnum } from '../../../enums/pub-sub.enum';
import {
  ShopProductVariant,
} from '../../../components/store/products/variants/shop-product-variant';

export const ShopProductListingContainerContext = CreateListingContainerContext<ProductDto>();

const StoreProductsPage = () => {
  const {currentShop} = useContext(AppContext);

  // Listing Container context
  const [action, setAction] = useState('');
  const [itemToEdit, setItemToEdit] = useState({} as ProductDto);
  const [itemEdited, setItemEdited] = useState({} as ProductDto);
  const contextModalForm: IListingContainerContext<ProductDto> = {
    action,
    setAction,
    itemToEdit,
    setItemToEdit,
    itemEdited,
    setItemEdited,
  };

  const variantsOnClick = useCallback(() => {
    PubSub.publish(PubSubEnum.LISTING_CONTAINER_SEARCH_BAR_VARIANTS_SHOW);
  }, [currentShop]);

  return (
    <MainLayout>
      <MainHeader className={'shadow-sm bg-dark'}>
        <ShopMainHeader/>
      </MainHeader>
      <MainBody>
        <ShopProductVariant/>
        <ShopProductListingContainerContext.Provider value={contextModalForm}>
          <ListingContainer resource={{
            url: '/products',
            type: () => ProductDto,
          }}
                            breadcrumb={[
                              <NavLink className={'text-decoration-none'}
                                       to={'/store'}>Stores</NavLink>,
                              'Products',
                              'All Products']}
                            additionalSearchBarItems={[
                              <SearchBarItem key={'variants'}
                                             label={'Variants'}
                                             onClickHandler={variantsOnClick}
                              />,
                            ]}
                            itemComponentClass={ShopProductItem}
                            formComponentClass={ShopProductForm}
                            ContextComponent={ShopProductListingContainerContext}/>
        </ShopProductListingContainerContext.Provider>
      </MainBody>
    </MainLayout>
  );
};

export { StoreProductsPage };
