import { Expose } from 'class-transformer';

export class ShippingMethodDto {
  @Expose()
  uuid?: string;

  @Expose()
  name?: string;

  @Expose()
  code?: string;

  @Expose()
  enabled?: boolean;
}
