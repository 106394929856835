import {Expose} from 'class-transformer';

export class ShopUserUpdateDto {

  @Expose()
  firstName?: string;

  @Expose()
  lastName?: string;

  @Expose()
  email?: string;

  @Expose()
  phone?: string;
}
