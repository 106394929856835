import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { IProps } from '../../../types';

interface ISearchBarItem extends IProps {
  label: string;
  onClickHandler: MouseEventHandler | undefined;
}

const SearchBarItem = ({label, onClickHandler}: ISearchBarItem) => {

  return (
    <Link
      type="button"
      className="text-decoration-none input-group-text"
      onClick={onClickHandler}
      to={''}>{label}
    </Link>
  );
};

export { SearchBarItem };