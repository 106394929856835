import { DependencyList, useCallback } from 'react';
import { OrderDto } from './dto/order.dto';
import { OrderConstants } from '../../components/store/orders/order.constants';

function useStoreOrderStatus(deps: DependencyList) {
  return useCallback((order: OrderDto) => {
    switch (order?.status) {
      case OrderConstants.ORDER_IN_PROGRESS:
        return ['In Progress', 'btn-warning', OrderConstants.ORDER_IN_PROGRESS];
      case OrderConstants.ORDER_COMPLETED:
        return ['Completed', 'btn-success', OrderConstants.ORDER_COMPLETED];
      default:
        return ['In Progress', 'btn-warning', OrderConstants.UNKNOWN];
    }
  }, deps);
}

export { useStoreOrderStatus };