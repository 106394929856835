import React, { useState } from 'react';
import { MainLayout } from '../../layouts/main.layout';
import { MainHeader } from '../../components/main/main-header';
import { ShopMainHeader } from '../../components/store/shop-main-header';
import { ListingContainer }
  from '../../components/listing-container/listing-container';
import MainBody from '../../components/main/main-body';
import { StoreStoreDto } from '../../components/store/stores/dto';
import CreateListingContainerContext, {
  IListingContainerContext,
} from '../../components/listing-container/listing-container.context';
import {
  StoreStoreItem,
} from '../../components/store/stores/store-store-item';
import {
  StoreStoreLiveEditor
} from '../../components/store/stores/live-editor/store-store-live-editor';
import {
  useCreateUIModel as useCreateLiveEditorUIModel
} from '../../components/store/stores/live-editor/store-store-live-editor.uimodel';
import EmptyComponentForm
  from '../../components/listing-container/empty-component-form';

export const ShopStoreListingContainerContext = CreateListingContainerContext<StoreStoreDto>();

const StorePage = () => {
  // Listing Container context
  const [action, setAction] = useState('');
  const [itemToEdit, setItemToEdit] = useState({} as StoreStoreDto);
  const [itemEdited, setItemEdited] = useState({} as StoreStoreDto);
  const contextModalForm: IListingContainerContext<StoreStoreDto> = {
    action,
    setAction,
    itemToEdit,
    setItemToEdit,
    itemEdited,
    setItemEdited,
  };
  const liveEditorUIModel = useCreateLiveEditorUIModel();

  return (
    <MainLayout>
      <MainHeader className={'shadow-sm bg-dark'}>
        <ShopMainHeader/>
      </MainHeader>
      <MainBody>
        <StoreStoreLiveEditor uiModel={liveEditorUIModel}/>
        <ShopStoreListingContainerContext.Provider value={contextModalForm}>
          <ListingContainer resource={{url: '/stores', type: () => StoreStoreDto}}
                              breadcrumb={['Stores', 'All Stores']}
                              itemComponentClass={StoreStoreItem}
                              formComponentClass={EmptyComponentForm}
                              ContextComponent={ShopStoreListingContainerContext}/>
        </ShopStoreListingContainerContext.Provider>
      </MainBody>
    </MainLayout>
  );
};

export { StorePage };
